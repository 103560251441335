import * as React from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {Button} from "src/components/button";
import {Separator} from "src/components/separator";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {ShevronRight} from "src/media/icons/custom/shevron/shevronRight";
import {Colors} from "src/utils/defaults/colors";
import routes from "src/utils/defaults/routes";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useUserHeader from "src/utils/zustandStores/userHeader/useUserHeader";

export default function MobileAccountSettings() {
    const navigate = useNavigate();
    const {user} = useUserHeader();
    const {user: tokenUser} = useTokenStore();
    return <div className={'flex flex-col px-padding gap-4 pt-8'}>

        <h1 className={'text-[26px] font-semibold leading-[30px]'}>Profile</h1>

        <section className={'flex pt-4 gap-4 items-center'}>
            <Avatar className={'w-14 h-14 rounded-full cursor-pointer'}>
                <AvatarImage src={(user && user.avatar && user.avatar !== '') ? user.avatar : undefined}/>
                <AvatarFallback
                    className={'text-sm'}>{user ? getFirstLetters(user.firstName, user.lastName) : ''}</AvatarFallback>
            </Avatar>

            <div className={'flex flex-col gap-[2px]'}>

                <div className={'text-lg font-normal flex leading-7'}>{getString([
                    {element: user?.firstName, tag: ElementTag.name},
                    {element: user?.lastName, tag: ElementTag.name},
                ])}
                </div>

                <div className={'flex gap-2 items-center text-neutral-500 text-sm font-normal leading-5'}>
                    {user?.companyName} <DotIcon size={4}/> {tokenUser && `${tokenUser.roles[0] + tokenUser.roles.slice(1).toLowerCase()}`}
                </div>
            </div>
        </section>

        <Separator/>

        <section className={'flex flex-col gap-0'}>
            <div onClick={() => navigate(routes.userPersonalDetails)}
                 className={'py-4 flex items-center justify-between text-foreground text-base leading-6 cursor-pointer'}>
                <div>Personal info</div>
                <ShevronRight size={20} stroke={Colors.foreground}/>
            </div>

            <div onClick={() => navigate(routes.userLoginAndSecurity)}
                 className={'py-4 flex items-center justify-between text-foreground text-base leading-6 cursor-pointer'}>
                <div>Login & Security</div>
                <ShevronRight size={20} stroke={Colors.foreground}/>
            </div>
        </section>

        {(tokenUser && tokenUser.roles !== 'DRIVER') && <>
            <Separator/>

            <section className={'flex flex-col gap-0'}>

                <h3 className={'text-[22px] font-semibold fleading-[26px] py-6'}>Company settings</h3>

                <div onClick={() => navigate(routes.companyDetails)}
                     className={'py-4 flex items-center justify-between text-foreground text-base leading-6 cursor-pointer'}>
                    <div>Company Details</div>
                    <ShevronRight size={20} stroke={Colors.foreground}/>
                </div>

                <div onClick={() => navigate(routes.companyPaymentsAndPayouts)}
                     className={'py-4 flex items-center justify-between text-foreground text-base leading-6 cursor-pointer'}>
                    <div>Payments & Payouts</div>
                    <ShevronRight size={20} stroke={Colors.foreground}/>
                </div>
            </section>
        </>}

        <Separator/>

        <section className={'flex flex-col gap-0'}>

            <h3 className={'text-[22px] font-semibold fleading-[26px] py-6'}>Support</h3>

            <div className={'py-4 flex items-center justify-between text-foreground text-base leading-6 cursor-pointer'}>
                <div>Contact us</div>
                <ShevronRight size={20} stroke={Colors.foreground}/>
            </div>

            <div className={'py-4 flex items-center justify-between text-foreground text-base leading-6 cursor-pointer'}>
                <div>How Marco works</div>
                <ShevronRight size={20} stroke={Colors.foreground}/>
            </div>
        </section>

        <Separator/>

        <Button onClick={() => navigate('/login')} className={'mt-6 text-base font-medium leading-[20px]'} size={'lg'} variant={'outline'}>Log out</Button>
    </div>
}