import * as React from "react";
import {CheckboxDotEmpty} from "src/media/icons/custom/checkBoxDotEmpty";
import {CheckboxDotFilled} from "src/media/icons/custom/checkBoxDotFilled";
import {SortFilter} from "src/utils/enums/sortFilter";
import useOverlay from "src/utils/zustandStores/useOverlay";
import useSearch from "src/utils/zustandStores/useSearch";

export default function SortFilterContent() {
    const {response, setSortFilterValue} = useSearch();
    const {setOpen} = useOverlay()

    return <div>
        {Object.values(SortFilter).map(el =>
            <section
                key={el}
                onClick={() => {
                    setSortFilterValue(el);
                    setOpen(false)
                }}

                className={`px-2 py-[0.375rem] ${response.body.sortFilterValue === el && 'bg-marcoOnHover'} hover:bg-marcoOnHover rounded-md w-60 flex justify-between gap-2 items-center`}>

                <div>
                    <p className={'select-none text-foreground text-sm font-medium leading-[20px]'}>{el[0] + el.slice(1).toLowerCase()}</p>
                    <p className={'select-none text-xs text-muted-foreground font-normal leading-[16px]'}>High to low</p>
                </div>

                {response.body.sortFilterValue === el ? <CheckboxDotFilled size={16}/> : <CheckboxDotEmpty size={16}/>}
            </section>)
        }
    </div>
}