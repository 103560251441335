import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "src/components/useToast";
import {getImages} from "src/utils/apiCalls/getImages";
import {getURL} from "src/utils/apiCalls/orderDetails/attachments/getURL";
import {updateUploadStatus} from "src/utils/apiCalls/orderDetails/attachments/updateUploadStatus";
import {uploadFile} from "src/utils/apiCalls/orderDetails/attachments/uploadFile";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderImage} from "src/utils/types/structures/orderImage";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import useOverlay from "src/utils/zustandStores/useOverlay";

export default function DropImageCover() {
    const {id} = useParams();
    const {isAnySelected, initStore, carrierImages, setSkeletonsCount} = useOrderAttachments();
    const navigate = useNavigate();
    const [inputKey, setInputKey] = useState<string>(Math.random().toString(36));
    const {setProgressBarValue} = useOverlay()

    async function onUploadImagesHandle(files: FileList | null) {
        if (files && id) {
            const dataLoadPace = Number((100 / files.length).toFixed(0));
            setSkeletonsCount(files.length)

            for (let i = 0; i < files.length;) {
                const url = await getURL(files[i], id)
                if (isInstanceOf<ErrorMessage>(url, 'message')) {
                    setProgressBarValue(0)
                    toast({
                        variant: "destructive",
                        description: "Request failed",
                    })
                    return
                } else {
                    const res = await uploadFile(files[i], url.url)
                    setProgressBarValue(useOverlay.getState().progressBarValue + dataLoadPace)
                    if (res === 200) {
                        await updateUploadStatus(url.id)
                        i++
                    } else {
                        setProgressBarValue(0)
                        toast({
                            variant: "destructive",
                            description: "Request failed",
                        })
                        return
                    }
                }
            }

            setProgressBarValue(100)
            let updatedImages: OrderImage[] = []
            const images = await getImages(id)
            if (isInstanceOf<ErrorMessage>(images, 'message')) {
                handleResponseError(images, () => navigate(routes.login))
            } else {
                updatedImages = images
            }
            window.scrollTo(0, 0)
            setProgressBarValue(0)
            initStore(updatedImages, id)
            setSkeletonsCount(0)
        }
    }

    function onClickHandle() {
        const input = document.getElementById("fileInput");
        if (input) {input.click()}
    }

    return  <div onDragOver={e => e.preventDefault()} onDrop={e => {
        e.preventDefault()
        onUploadImagesHandle(e.dataTransfer.files)
    }} className={'w-full aspect-square border-[0.12rem] border-dashed rounded-xl flex flex-col gap-0 place-content-center place-items-center'}>
        <input className={"hidden"}
               type='file'
               key={inputKey}
               name={"files"}
               multiple={true}
               id={"fileInput"}
               onChange={e => {
                   e.preventDefault()
                   onUploadImagesHandle(e.target.files)
               }}/>

        <div className={'text-center px-1'}>
            <p className={'text-sm text-[#6B7280]'}>Drop your file here,</p>
            <span className={'text-sm text-[#6B7280]'}>or </span>
            <span className={'text-sm text-[#7C3AED] font-medium cursor-pointer'} onClick={onClickHandle}>click to browse</span>
        </div>
    </div>
}