import {Member} from "src/utils/types/structures/member";
import {create} from 'zustand'

export enum AlertElementType {
    default = 'DEFAULT',
    member = 'MEMBER'
}

export type AlertDefinition = null | Member

interface State {
    isOpen: boolean
    type: AlertElementType
    value: AlertDefinition
    title: string
    description: string
    firstAction?: () => void
    secondAction?: () => void
}

interface Actions {
    open: (elementType: AlertElementType, value: AlertDefinition, title: string, mutateFirstSource?: () => void, mutateSecondSource?: () => void) => void
    close: () => void
}

const useDialog = create<State & Actions>(
    (set, get) => ({
        isOpen: false,
        type: AlertElementType.default,
        value: null,
        title: '',
        description: '',
        firstAction: () => {},
        secondAction: () => {},

        open: (elementType: AlertElementType, value: AlertDefinition, title: string, mutateFirstSource?: () => void, mutateSecondSource?: () => void) => {
            set(
                (state) => (
                    {
                        isOpen: true,
                        type: elementType,
                        value: value,
                        title: title,
                        firstAction: mutateFirstSource,
                        secondAction: mutateSecondSource
                    }
                )
            )
        },

        close: () => {
            set(
                (state) => (
                    {
                        isOpen: false,
                        type: AlertElementType.default,
                        value: null,
                        title: '',
                        description: '',
                        firstAction: () => {},
                        secondAction: () => {},
                    }
                )
            )
        },
    })
)

export default useDialog;
