import {PropertySize} from "src/utils/enums/addressEnum/propertySize";

export default function getPropertySize(propertySize: PropertySize | null | undefined) {

    let result = ''

    if (propertySize){
        switch (propertySize) {
            case PropertySize.studio: {
                result = 'Studio'
                break
            }

            case PropertySize.one_bedroom: {
                result = '1 bedroom'
                break
            }

            case PropertySize.two_bedrooms: {
                result = '2 bedrooms'
                break
            }

            case PropertySize.three_bedrooms: {
                result = '3 bedrooms'
                break
            }

            case PropertySize.four_bedrooms: {
                result = '4+ bedrooms'
                break
            }
        }
    }
    return result
}