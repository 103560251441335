import {Access} from "src/utils/enums/addressEnum/access";

export default function getAccess(orderAccess: Access | null | undefined) {

    let result = ''

    if (orderAccess){
        switch (orderAccess) {
            case Access.no_stairs: {
                result = 'No stairs'
                break
            }

            case Access.one_flight_of_stairs: {
                result = '1 flight of stairs'
                break
            }

            case Access.two_flight_of_stairs: {
                result = '2 flights of stairs'
                break
            }

            case Access.three_flight_of_stairs: {
                result = '3 flights of stairs'
                break
            }

            case Access.four_flight_of_stairs: {
                result = '4 flights of stairs'
                break
            }

            case Access.five_flight_of_stairs: {
                result = '5 flights of stairs'
                break
            }

            case Access.six_flight_of_stairs: {
                result = '6 flights of stairs'
                break
            }

            case Access.elevator: {
                result = 'Elevator'
                break
            }

            case Access.drive_up: {
                result = 'Drive up'
                break
            }

        }
    }
    return result
}