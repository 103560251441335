import {SquareFootage} from "src/utils/enums/addressEnum/squareFootage";

export default function getSquareFootage(squareFootage: SquareFootage | null | undefined) {

    let result = ''

    if (squareFootage){
        switch (squareFootage) {
            case SquareFootage.SQ_FT_500_1000: {
                result = '500-1000 sq.ft'
                break
            }

            case SquareFootage.SQ_FT_1000_2000: {
                result = '1000-2000 sq.ft'
                break
            }

            case SquareFootage.SQ_FT_2000_3000: {
                result = '2000-3000 sq.ft'
                break
            }

            case SquareFootage.SQ_FT_3000_PLUS: {
                result = '3000+ sq.ft'
                break
            }
        }
    }
    return result
}