import * as RPNInput from "react-phone-number-input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import formatEnumValueToString from "src/utils/stringHandlers/formatEnumValueToString";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {Member} from "src/utils/types/structures/member";
import TableCellName from "./tablecomponents/tableCellName";
import TableDots from "./tablecomponents/tableDots";

interface Props {
    members: Member[]
    mutateMembers: () => void
}

export default function MembersTable({members, mutateMembers}: Props) {
    return <Table>
        <TableHeader>
            <TableRow>
                <TableHead className={'text-sm font-semibold'}>Name</TableHead>
                <TableHead className={'text-sm font-semibold'}>Email</TableHead>
                <TableHead className={'text-sm font-semibold'}>Phone</TableHead>
                <TableHead className={'text-sm font-semibold'}>Status</TableHead>
                <TableHead className={'text-sm font-semibold'}>Role</TableHead>
                <TableHead className={'w-12'}></TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>

            {members.map(
                member =>
                    <TableRow
                        key={member.id}>

                        <TableCellName member={member}/>

                        <TableCell>{getString(
                            [{element: member.email, tag: ElementTag.email}]
                        )}</TableCell>

                        <TableCell>
                            {
                                member.phone
                                    ? RPNInput.isValidPhoneNumber(member.phone) ? RPNInput.formatPhoneNumber(member.phone) : member.phone
                                    : 'No phone'
                            }
                        </TableCell>

                        <TableCell>{member.active ? 'Verified' : 'Pending'}</TableCell>

                        <TableCell>{formatEnumValueToString(member.role)}</TableCell>

                        <TableDots mutateMembers={() => mutateMembers()} member={member}></TableDots>
                    </TableRow>
            )}
        </TableBody>
    </Table>
}