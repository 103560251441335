import jwt from "jwt-decode";
import {Role} from "src/utils/enums/role";
import {Token} from "src/utils/types/token";

import {create} from 'zustand'
import {persist} from 'zustand/middleware'

export type TokenUser = {
    email: string
    exp: number
    first_name: string
    last_name: string
    roles: Role
    sub: string
}

interface TokenState {
    token: string | null;
    user: TokenUser | null
}

interface TokenActions {
    setToken: (token: string) => void;
    resetToken: () => void;
    checkTokenValidity: () => boolean;
}

const useTokenStore = create(
    persist<TokenState & TokenActions>(
        (set, get) => ({
            token: null,
            user: null,

            setToken: (token: string) => {

                const user: TokenUser = jwt(token);

                set(
                    (state) =>
                        (
                            {
                                token: token,
                                user: user
                            }
                        )
                );
            },

            resetToken: () => {
                set(
                    (state) => ({token: "", user: null})
                );
            },

            checkTokenValidity: () => {

                const actualToken = localStorage.getItem(`authToken`);
                const cashToken = get().token;

                if (actualToken && cashToken) {
                    switch (actualToken) {
                        case "":
                        case null:
                        case undefined:
                            return false

                        default: {
                            const user = jwt<Token>(actualToken);
                            const isValid = user.exp * 1000 > new Date().getTime();
                            return isValid
                        }
                    }
                } else return false
            }
        }),

        {
            name: 'authToken',
        }
    )
)

export default useTokenStore;
