import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {PasswordInput} from "src/components/passwordInput";
import {setPassword} from "src/utils/apiCalls/setPassword";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import checkValues, {FormCheck} from "src/utils/stringHandlers/checkValues";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {RegisterRequest} from "src/utils/types/registerRequest";
import setUpPasswordSchema from "src/utils/zodSchemas/setUpPasswordSchema";
import * as z from "zod";

export default function SetUpPassword() {
    const {token} = useParams();
    const navigate = useNavigate();
    const [formChecker, setFormChecker] = useState<FormCheck | null>(null)

    useEffect(() => {
        if (token) {
            form.setValue('token', token)
        }
    }, [token]);

    const form = useForm<z.infer<typeof setUpPasswordSchema>>({
        resolver: zodResolver(setUpPasswordSchema),
        defaultValues: {
            token: "",
            password: "",
            repeatPassword: ""
        }
    });

    async function onSubmit(values: z.infer<typeof setUpPasswordSchema>) {

        const credentials: RegisterRequest = {
            token: values.token,
            password: values.password
        }

        const res = await setPassword(credentials);
        if (res === 200) {
            navigate(routes.main)
        } else {
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res);
            }
        }
    }

    if (token) {
        return <div className={"flex flex-col gap-4"}>
            <h1 className={"text-foreground text-[2rem] font-medium"}>You almost there!</h1>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className={"flex flex-col gap-4"}>
                        <span className={'text-muted-foreground font-normal'}>Please create a strong password that is difficult to guess and does not include any personal information.</span>

                        <FormField
                            control={form.control}
                            name="password"
                            render={({field}) => (
                                <FormItem className={"flex-1"}>

                                    <div className={'flex justify-between'}>
                                        <FormLabel className={'text-sm leading-3'}>Password</FormLabel>
                                        {form.getFieldState('password').invalid && <FormMessage
                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                    </div>

                                    <FormControl>
                                        <PasswordInput
                                            {...field}
                                            placeholder={"Password"}
                                            className={`${form.getFieldState('password').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                            onInput={event => setFormChecker(checkValues(event.currentTarget.value))}
                                            autoComplete={'off'}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <ul className={'text-muted-foreground text-sm font-inter list-disc pl-4'}>
                            <li className={`${!formChecker && 'text-muted-foreground'} ${formChecker?.isLengthCorrect ? 'text-marcoDarkGreen' : 'text-marcoFormErrorTextColor'} text-sm`}>8
                                or more characters
                            </li>
                            <li className={`${!formChecker && 'text-muted-foreground'} ${formChecker?.isUpperPlusLowerLettersPresent ? 'text-marcoDarkGreen' : 'text-marcoFormErrorTextColor'}  text-sm`}>Upper
                                + lowercase letters
                            </li>
                            <li className={`${!formChecker && 'text-muted-foreground'} ${formChecker?.isAtLeastOneNumber ? 'text-marcoDarkGreen' : 'text-marcoFormErrorTextColor'} text-sm`}>At
                                least one number
                            </li>
                            <li className={`${!formChecker && 'text-muted-foreground'} ${formChecker?.isAtLeastOneSpecialCharacter ? 'text-marcoDarkGreen' : 'text-marcoFormErrorTextColor'} text-sm`}>At
                                least one special character
                            </li>
                        </ul>

                        <FormField
                            control={form.control}
                            name="repeatPassword"
                            render={({field}) => (
                                <FormItem className={"flex-1"}>

                                    <div className={'flex justify-between'}>
                                        <FormLabel className={'text-sm leading-3'}>Confirm password</FormLabel>
                                        {form.getFieldState('repeatPassword').invalid && <FormMessage
                                            className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                    </div>

                                    <FormControl>
                                        <PasswordInput
                                            {...field}
                                            placeholder={"Confirm password"}
                                            className={`${form.getFieldState('repeatPassword').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                            autoComplete={'off'}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <Button className={"w-full"}
                                size={'lg'}
                                variant={"green"}
                                type={'submit'}>Create account</Button>
                    </div>
                </form>
            </Form>

            <span>
                <span className={'text-sm'}>Already registered?</span>
                <span
                    className={"ml-1 text-marcoDarkGreen text-sm hover:underline cursor-pointer"}
                    onClick={() => navigate(routes.main)}>
                    Sign In
                </span>
            </span>
        </div>
    }

    return <>
        <h1 className={"text-foreground text-[2rem] font-medium"}>Token not found</h1>
    </>
}