import {CustomItem} from "src/utils/types/structures/customItem";

export function isCustomItemsEqual(firstCustomItem: CustomItem, secondCustomItem: CustomItem) {
    let flag = true

    switch (true) {
        case (firstCustomItem.name !== secondCustomItem.name): {
            flag = false
            break
        }

        case (firstCustomItem.length !== secondCustomItem.length): {
            flag = false
            return flag
        }

        case (firstCustomItem.height !== secondCustomItem.height): {
            flag = false
            return flag
        }

        case (firstCustomItem.weight !== secondCustomItem.weight): {
            flag = false
            return flag
        }

        case (firstCustomItem.description !== secondCustomItem.description): {
            flag = false
            return flag
        }

        case (firstCustomItem.width !== secondCustomItem.width): {
            flag = false
            return flag
        }
    }

    return flag
}