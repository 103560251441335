import React, {useEffect, useState} from "react";
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogHeader,
    AlertDialogTitle
} from "src/components/alert";
import DeleteMember from "src/pages/dashboarSection/layout/popUps/alert/content/deleteMember";
import useAlert, {AlertElementType} from "src/pages/dashboarSection/layout/popUps/alert/useAlert";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {Member} from "src/utils/types/structures/member";

export default function AlertWrapper() {

    const [dialogContent, setDialogContent] = useState(<></>);
    const {isOpen, value, type, close, title, description, secondAction, firstAction} = useAlert();

    useEffect(() => {
        switch (type) {
            case AlertElementType.member: {
                if (value === null){

                } else {
                    if (isInstanceOf<Member>(value, 'id')) {
                        setDialogContent(<DeleteMember member={value}/>)
                    }
                }
            }
        }
    }, [value, type]);

    return <AlertDialog open={isOpen}>
        <AlertDialogContent>
            <AlertDialogHeader>
                <AlertDialogTitle>{title}</AlertDialogTitle>
                <AlertDialogDescription>{description}</AlertDialogDescription>
            </AlertDialogHeader>
            {dialogContent}
        </AlertDialogContent>
    </AlertDialog>
}