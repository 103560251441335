import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Input} from "src/components/input";
import {Sheet, SheetContent, SheetTrigger} from "src/components/sheet";
import {ArrowRight} from "src/media/icons/custom/arrowRight";
import {SearchIcon} from "src/media/icons/custom/searchIcon";
import NoResults from "src/pages/dashboarSection/search/orderList/noResults";
import OrderList from "src/pages/dashboarSection/search/orderList/orderList";
import MobileFilter from "src/pages/dashboarSection/search/searchLayout/mobileSearch/mobileFilter";
import SearchInputMobile from "src/pages/dashboarSection/search/searchLayout/mobileSearch/searchInputMobile";
import {getOrders} from "src/utils/apiCalls/search/getOrders";
import {Colors} from "src/utils/defaults/colors";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderSearch} from "src/utils/types/structures/orderSearch";
import searchAddresssInputSchema from "src/utils/zodSchemas/searchAddresssInputSchema";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import useSearch from "src/utils/zustandStores/useSearch";
import {getGeocode, getLatLng} from "use-places-autocomplete";
import * as z from "zod";

export type Location = {
    shouldFetchData: boolean
    lat: number | null,
    lng: number | null,
}

export type LocationData = {
    origin: Location
    destination: Location
}

export default function MobileSearch() {
    const navigate = useNavigate();
    const {response} = useSearch();
    const {isScrolled} = useViewport();
    const page = useRef(0)
    const [totalRendered, setTotalRendered] = useState<OrderSearch[]>([]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [locationData, setLocationData] = useState<LocationData>({
        destination: {
            shouldFetchData: true,
            lat: null,
            lng: null,
        },

        origin: {
            shouldFetchData: true,
            lat: null,
            lng: null,
        }
    })

    const [sheetOpen, setSheetOpen] = useState<boolean>(false)

    const form = useForm<z.infer<typeof searchAddresssInputSchema>>({
        resolver: zodResolver(searchAddresssInputSchema),
        defaultValues: {
            pickup: '',
            destination: ''
        }
    });

    const {
        getValuesFromURL,
        resetFilter
    } = useSearch()

    const loadMoreOrders = async () => {
        if (totalRendered.length < totalOrders) {
            if (response.request) {
                page.current = page.current + 1
                const res = await getOrders(response.request, page.current);
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.main))

                } else {
                    setTotalRendered(prevState => [...prevState, ...res.content]);
                }
            }
        }
    }

    const initOrders = async () => {
        setLoading(true)
        page.current = 0

        let originLat: number | null = null
        let originLng: number | null = null
        let destinationLat: number | null = null
        let destinationLng: number | null = null
        const origin = searchParams.get('originAddress');
        const destination = searchParams.get('destinationAddress');

        if (locationData.origin.shouldFetchData) {
            if (origin) {
                try {
                    const res = await getGeocode({address: origin});
                    const {lat, lng} = getLatLng(res[0]);
                    originLat = lat;
                    originLng = lng;
                    form.setValue('pickup', origin)
                } catch (e) {
                    originLat = null;
                    originLng = null;
                    form.setValue('pickup', origin)
                    form.setError('pickup', {message: 'Invalid location'})
                }
            } else {
                originLat = null;
                originLng = null;
            }
        } else {
            originLat = locationData.origin.lat;
            originLng = locationData.origin.lng;
        }

        if (locationData.destination.shouldFetchData) {

            if (destination) {
                try {
                    const res = await getGeocode({address: destination});
                    const {lat, lng} = getLatLng(res[0]);
                    destinationLat = lat;
                    destinationLng = lng;
                    form.setValue('destination', destination)
                } catch (e) {
                    destinationLat = null;
                    destinationLng = null;
                    form.setValue('pickup', destination)
                    form.setError('destination', {message: 'Invalid location'})
                }
            } else {
                destinationLat = null;
                destinationLng = null;
            }
        } else {
            destinationLat = locationData.destination.lat;
            destinationLng = locationData.destination.lng;
        }

        const filterValuesObject = await getValuesFromURL(searchParams, originLat, originLng, destinationLat, destinationLng);
        const res = await getOrders(filterValuesObject, page.current)
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate(routes.login))
        } else {
            setTotalOrders(res.totalElements);
            setTotalRendered(res.content)
        }
        window.scrollTo(0, 0)
}

useEffect(() => {
    resetFilter()
}, []);

useEffect(() => {
    initOrders().then(value => setLoading(false))
}, [searchParams]);

return <div className={"h-full"}>

    <section className={`sticky top-0 ${!isScrolled && 'shadow-light'} bg-background z-30 px-padding pt-6`}>
        <Sheet open={sheetOpen}>
            <SheetTrigger asChild>

                <section
                    onClick={() => setSheetOpen(true)}
                    className={`h-[48px] border ${(form.getFieldState('pickup').invalid || form.getFieldState('destination').invalid) ? 'border-marcoFormErrorTextColor bg-marcoFormBackgroundColor' : 'border-[#E7E7E7]'} rounded-lg flex gap-3 items-center px-3 cursor-pointer`}>
                    <SearchIcon size={16}
                                stroke={(form.getFieldState('pickup').invalid || form.getFieldState('destination').invalid) ? Colors.error : Colors.foreground}
                                className={`flex-none fill-none`}/>

                    <div className={'flex-1 flex items-center gap-3'}>

                        <Input
                            id={'pickup-address'}

                            type={'text'}
                            placeholder={"Anywhere"}
                            className={`flex-1 ${(form.getFieldState('pickup').invalid || form.getFieldState('destination').invalid) ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor' : 'text-foreground'} font-normal leading-tight px-0 border-none`}
                            value={form.getValues('pickup')}
                            readOnly
                        />

                        <ArrowRight stroke={(form.getFieldState('pickup').invalid || form.getFieldState('destination').invalid) ? Colors.error : Colors.foreground} size={16}/>

                        <Input
                            id={'destination-address'}

                            type={'text'}
                            placeholder={"Anywhere"}
                            className={`flex-1 ${(form.getFieldState('pickup').invalid || form.getFieldState('destination').invalid) ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor' : 'text-foreground'} font-normal leading-tight px-0 border-none`}
                            value={form.getValues('destination')}
                            readOnly
                        />

                    </div>
                </section>
            </SheetTrigger>

            <SheetContent className={'font-inter py-0 px-0 flex flex-col gap-0 h-dvh'}>
                <SearchInputMobile setSheetOpen={setSheetOpen} sheetOpen={sheetOpen} setLocationData={setLocationData} form={form} isLoading={isLoading}/>
            </SheetContent>
        </Sheet>

        <MobileFilter/>
    </section>


    <h3 className={'text-base font-semibold pt-[0.375rem] px-padding'}>{totalOrders} loads</h3>

    <InfiniteScroll
        next={loadMoreOrders}
        hasMore={totalRendered.length < totalOrders}
        loader={<div>Loading</div>}
        dataLength={totalRendered.length}
    >
        <OrderList orders={totalRendered}/>
    </InfiniteScroll>

    {!isLoading && totalOrders === 0 && <NoResults className={'flex flex-col items-center pt-10 px-padding'}/>}
</div>
}