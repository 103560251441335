import {OrderPacking} from "src/utils/enums/orderPacking";

export default function getPacking(orderPacking: OrderPacking | null | undefined) {

    let result = ''

    if (orderPacking){
        switch (orderPacking) {
            case OrderPacking.fullPacking: {
                result = 'Full packing'
                break
            }

            case OrderPacking.partialPacking: {
                result = 'Partial packing'
                break
            }

            case OrderPacking.notRequredPacking: {
                result = 'Not required packing'
                break
            }
        }
    }
    return result
}