import {BlobType} from "src/utils/enums/blobType";
import {ExtendedOrderImage} from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";

export default function getExtensionType(currentImage: ExtendedOrderImage) {
    const extension = currentImage.image.url.split('.').reverse()[0]
    switch (extension) {
        case 'png':
        case 'jpg':
        case 'jpeg':{
            return BlobType.image
        }

        case 'mov':
        case 'mp4':
        case 'MOV':{
            return BlobType.video
        }

        case 'pdf':
        case 'PDF':{
            return BlobType.pdf
        }

        default: {
            return BlobType.image
        }
    }
}