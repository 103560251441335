import React from "react";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {TableCell} from "src/components/table";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {Member} from "src/utils/types/structures/member";

interface Props {
    member: Member
}

export default function TableCellName({member}: Props) {
    return <TableCell>
        <div className={'flex place-items-center gap-2'}>
            <Avatar>
                <AvatarImage src={(member.avatar && member.avatar !== '') ? member.avatar : undefined}/>
                <AvatarFallback className={'text-sm'}>{getFirstLetters(member.firstName, member.lastName)}</AvatarFallback>
            </Avatar>
            <span className={'font-inter text-sm text-foreground'}>{getString(
                [
                    {element:member.firstName, tag:ElementTag.name},
                    {element:member.lastName, tag:ElementTag.name}
                ]
            )}</span>
        </div>
    </TableCell>
}