import {Role} from "src/utils/enums/role";

export default function getRole(role: Role | null | undefined) {

    let result = ''

    if (role){
        switch (role) {
            case Role.owner: {
                result = 'Owner'
                break
            }
            case Role.manager: {
                result = 'Manager'
                break
            }

            case Role.driver: {
                result = 'Driver'
                break
            }
        }
    }
    return result
}