import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
    stroke: string
}

const ShevronRight = ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox={'-2 0 25 25'} stroke={props.stroke} fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="m9 18 6-6-6-6"/>
    </svg>
}

export {ShevronRight}