import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function updateUploadStatus(fileId: string) {

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/file/confirm`), {
            method: 'POST',
            headers: {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${useTokenStore.getState().token}`,
            },
            body: JSON.stringify({fileId: fileId})
        })

        if (res.ok) {
            return 200
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}