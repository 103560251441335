import React, {useEffect, useState} from "react";
import {Button} from "src/components/button";
import {ScrollArea} from "src/components/scrollArea";
import {Sheet, SheetContent} from "src/components/sheet";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import CreateMember from "src/pages/dashboarSection/layout/popUps/sheet/content/fleetManagement/createMember";
import EditMember from "src/pages/dashboarSection/layout/popUps/sheet/content/fleetManagement/editMember";
import ChangeOrderAddress
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/changeAddress/changeOrderAddress";
import DriverAssign from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/driverAssign/driverAssign";
import Notes from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/notes/notes";
import OrderDocuments
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/orderDocuments/orderDocuments";
import PaymentsContent
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/payments/paymentsContent";
import UpdateItems from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/updateItems/updateItems";
import SearchOrderContent from "src/pages/dashboarSection/layout/popUps/sheet/content/orderSearch/searchOrderContent";
import useSheet, {ElementType} from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";

import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {Address} from "src/utils/types/structures/Address";
import {Member} from "src/utils/types/structures/member";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderSearch} from "src/utils/types/structures/orderSearch";

export default function SheetWrapper() {

    const [sheetContent, setSheetContent] = useState(<></>);
    const {isOpen, value, type, close, title} = useSheet();

    useEffect(() => {
        switch (type) {
            case ElementType.order: {
                if (value === null) {
                    close()
                } else {
                    if (isInstanceOf<OrderSearch>(value, 'id')) {
                        setSheetContent(<SearchOrderContent order={value}/>)
                    }
                }
                break
            }

            case ElementType.orderDetailsPayments: {
                if (value === null) {
                    close()
                } else {
                    if (isInstanceOf<OrderDetails>(value, 'id')) {
                        setSheetContent(<PaymentsContent order={value}/>)
                    }
                }
                break
            }

            case ElementType.orderDetailsNotes: {
                if (value === null) {
                    close()
                } else {
                    if (isInstanceOf<OrderDetails>(value, 'id')) {
                        setSheetContent(<Notes order={value}/>)
                    }
                }
                break
            }

            case ElementType.orderDetailsDriverAssign: {
                if (isInstanceOf<OrderDetails>(value, 'id')) {
                    setSheetContent(<DriverAssign order={value}/>)
                }
                break
            }

            case ElementType.orderDetailsUpdateItems: {
                if (isInstanceOf<OrderDetails>(value, 'id')) {
                    setSheetContent(<UpdateItems order={value}/>)
                }
                break
            }

            case ElementType.fleetManagementInviteMember: {
                if (value === null) {
                    setSheetContent(<CreateMember/>)
                }
                break
            }

            case ElementType.fleetManagementEditMember: {
                if (value === null) {
                    close()
                }

                if (isInstanceOf<Member>(value, 'id')) {
                    setSheetContent(<EditMember member={value}/>)
                }
                break
            }

            case ElementType.orderDetailsDocuments: {
                if (value === null) {
                    close()
                }

                if (isInstanceOf<OrderDetails>(value, 'id')) {
                    setSheetContent(<OrderDocuments order={value}/>)
                }
                break
            }

            case ElementType.orderDetailsChangeAddress: {
                if (value === null) {
                    close()
                }

                if (isInstanceOf<Address>(value, 'direction')) {
                    setSheetContent(<ChangeOrderAddress  address={value}/>)
                }
                break
            }
        }
    }, [value, type]);

    return <Sheet open={isOpen}>
        <SheetContent className={'font-inter py-0 px-0 flex flex-col gap-0 h-dvh'}>
                <section className={'flex-none sticky top-0 z-50 bg-white border-b'}>
                    <div className={'flex h-[4.25rem] justify-between py-4 px-4 place-items-center'}>
                        <h1 className={'text-foreground text-lg font-semibold leading-7}'}>{title}</h1>
                        <Button variant={'ghost'} size={"iconFit"} className={'p-[0.375rem]'} onClick={() => close()}><CrossIcon size={20} className={'fill-foreground'}/></Button>
                    </div>
                </section>

            <ScrollArea className={'h-[calc(100dvh-4.25rem)]'}>
                {sheetContent}
            </ScrollArea>
        </SheetContent>
    </Sheet>
}