import React, {useState} from "react";
import {Button} from "src/components/button";
import LoadingStatus from "src/components/loadingStatus";
import {Separator} from "src/components/separator";
import photo from 'src/media/onboarding.png'
import {onboarding} from "src/utils/apiCalls/onboarding";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";


const CompleteUnboarding = () => {
const [isLoading, setLoading] = useState<boolean>(false)

    const onboard = async () => {
        setLoading(true)
        const res = await onboarding()
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res)
        } else {
            window.location.href = res.link;
        }
    }

    return <div className={'flex flex-col h-full'}>

        <section className={'flex-1 flex flex-col gap-4 pb-6 px-6'}>
            <p className={'flex-none text-neutral-500 text-sm font-normal leading-5'}>Please complete your company account and  verify with Stripe.</p>

            <div className={'flex-1 place-self-center'}>
                <img src={photo} className={'object-cover max-h-[486px]'}/>
            </div>
        </section>

        <Separator/>

        <div className={'flex-none px-6 flex justify-between items-center py-4'}>
            <Button variant={'textGhost'} size={'lg'} type={'button'}
                    className={'text-foreground text-base font-medium underline leading-5'}>Contact us</Button>
            <Button size={'lg'} disabled={isLoading} className={'text-white text-base font-medium leading-5'} onClick={onboard}>{isLoading ? <LoadingStatus/> : 'Complete onboarding'}</Button>
        </div>

    </div>
}

export default CompleteUnboarding