import {Member} from "src/utils/types/structures/member";
import {create} from 'zustand'

interface State {
    user: Member | null
}

interface Actions {
    setUser: (user: Member) => void
}

const useUserHeader = create<State & Actions>(
    (set, get) => ({
        user: null,

        setUser: (user: Member) => {
            set(
                (state) => (
                    {
                        user: user
                    }
                )
            )
        },


    })
)

export default useUserHeader;
