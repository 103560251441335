import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const OptionsMenuIcon = ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox={'0 0 20 20'} fill="none">
        <path id="Vector"
              d="M7.41992 3.5C7.15471 3.5 6.90035 3.60536 6.71282 3.79289C6.52528 3.98043 6.41992 4.23478 6.41992 4.5V5.5C6.41992 5.76522 6.52528 6.01957 6.71282 6.20711C6.90035 6.39464 7.15471 6.5 7.41992 6.5H8.41992C8.68514 6.5 8.93949 6.39464 9.12703 6.20711C9.31456 6.01957 9.41992 5.76522 9.41992 5.5V4.5C9.41992 4.23478 9.31456 3.98043 9.12703 3.79289C8.93949 3.60536 8.68514 3.5 8.41992 3.5H7.41992Z"
        />
        <path id="Vector_2"
              d="M7.41992 8.5C7.15471 8.5 6.90035 8.60536 6.71282 8.79289C6.52528 8.98043 6.41992 9.23478 6.41992 9.5V10.5C6.41992 10.7652 6.52528 11.0196 6.71282 11.2071C6.90035 11.3946 7.15471 11.5 7.41992 11.5H8.41992C8.68514 11.5 8.93949 11.3946 9.12703 11.2071C9.31456 11.0196 9.41992 10.7652 9.41992 10.5V9.5C9.41992 9.23478 9.31456 8.98043 9.12703 8.79289C8.93949 8.60536 8.68514 8.5 8.41992 8.5H7.41992Z"
        />
        <path id="Vector_3"
              d="M6.41992 14.5C6.41992 14.2348 6.52528 13.9804 6.71282 13.7929C6.90035 13.6054 7.15471 13.5 7.41992 13.5H8.41992C8.68514 13.5 8.93949 13.6054 9.12703 13.7929C9.31456 13.9804 9.41992 14.2348 9.41992 14.5V15.5C9.41992 15.7652 9.31456 16.0196 9.12703 16.2071C8.93949 16.3946 8.68514 16.5 8.41992 16.5H7.41992C7.15471 16.5 6.90035 16.3946 6.71282 16.2071C6.52528 16.0196 6.41992 15.7652 6.41992 15.5V14.5Z"
        />
        <path id="Vector_4"
              d="M12.9199 3.5C12.6547 3.5 12.4004 3.60536 12.2128 3.79289C12.0253 3.98043 11.9199 4.23478 11.9199 4.5V5.5C11.9199 5.76522 12.0253 6.01957 12.2128 6.20711C12.4004 6.39464 12.6547 6.5 12.9199 6.5H13.9199C14.1851 6.5 14.4395 6.39464 14.627 6.20711C14.8146 6.01957 14.9199 5.76522 14.9199 5.5V4.5C14.9199 4.23478 14.8146 3.98043 14.627 3.79289C14.4395 3.60536 14.1851 3.5 13.9199 3.5H12.9199Z"
        />
        <path id="Vector_5"
              d="M11.9199 9.5C11.9199 9.23478 12.0253 8.98043 12.2128 8.79289C12.4004 8.60536 12.6547 8.5 12.9199 8.5H13.9199C14.1851 8.5 14.4395 8.60536 14.627 8.79289C14.8146 8.98043 14.9199 9.23478 14.9199 9.5V10.5C14.9199 10.7652 14.8146 11.0196 14.627 11.2071C14.4395 11.3946 14.1851 11.5 13.9199 11.5H12.9199C12.6547 11.5 12.4004 11.3946 12.2128 11.2071C12.0253 11.0196 11.9199 10.7652 11.9199 10.5V9.5Z"
        />
        <path id="Vector_6"
              d="M12.9199 13.5C12.6547 13.5 12.4004 13.6054 12.2128 13.7929C12.0253 13.9804 11.9199 14.2348 11.9199 14.5V15.5C11.9199 15.7652 12.0253 16.0196 12.2128 16.2071C12.4004 16.3946 12.6547 16.5 12.9199 16.5H13.9199C14.1851 16.5 14.4395 16.3946 14.627 16.2071C14.8146 16.0196 14.9199 15.7652 14.9199 15.5V14.5C14.9199 14.2348 14.8146 13.9804 14.627 13.7929C14.4395 13.6054 14.1851 13.5 13.9199 13.5H12.9199Z"
        />
    </svg>
}

export {OptionsMenuIcon}