import React, {useEffect, useState} from "react";
import GridCard from "src/pages/dashboarSection/search/orderList/gridCard";
import {OrderSearch} from "src/utils/types/structures/orderSearch";
import useSearch from "src/utils/zustandStores/useSearch";
import {TSMap} from "typescript-map";

interface Props {
    orders: OrderSearch[]

}

export default function OrderList({orders}: Props) {
    const [list, setList] = useState(<div></div>)
    const {response} = useSearch()

    useEffect(() => {
        const orderListMap = new TSMap<number, OrderSearch[]>();

        orders.forEach(
            order => {
                orderListMap.set(new Date(order.pickupDate ?? '').valueOf(), orderListMap.get(new Date(order.pickupDate ?? '').valueOf()) ? [...orderListMap.get(new Date(order.pickupDate ?? '').valueOf()), order] : [order])
            }
        )

        switch (response.body.sortFilterValue) {
            case 'DISTANCE': {
                orderListMap.forEach(
                    (orderList, key, index) => {
                        orderList.sort(
                            (a, b) => (b.distance && a.distance) ? b.distance - a.distance : a.id.localeCompare( b.id)
                        )
                    })
                break
            }

            case 'RATE': {
                orderListMap.forEach(
                    (orderList, key, index) => {
                        orderList.sort(
                            (a, b) => (b.rate && a.rate) ? b.rate - a.rate : a.id.localeCompare( b.id)
                        )
                    })
                break
            }

            case 'VOLUME': {
                orderListMap.forEach(
                    (orderList, key, index) => {
                        orderList.sort(
                            (a, b) => (b.volume && a.volume) ? b.volume - a.volume : a.id.localeCompare( b.id)
                        )
                    })
                break
            }
        }

        setList(<div>
            {orderListMap.map(
                (value, key, index) => <GridCard key={value[0].id} date={new Date(key ?? '')} orderList={value}/>)}
        </div>)
    }, [orders, response.body.sortFilterValue]);
    return list
}