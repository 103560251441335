import * as React from "react"

import {cn} from "../utils/cn/cnHelper";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    isValid?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({className, type, ...props}, ref) => {
        return (
            <input
                type={type}
                className={cn(
                    `flex h-10 w-full text-base rounded-md border border-input bg-transparent px-3 py-1 font-inter transition-colors file:border-0 file:bg-transparent file:text-base file:font-medium placeholder:text-base placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50`,
                    className
                )}
                ref={ref}
                {...props}
            />
        )
    }
)
Input.displayName = "Input"

export {Input}
