import React from "react";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

export default function CarrierName({order}: Props) {
    if (!order.driver) {
        return <p className={'font-inter text-sm text-foreground'}>Unassigned</p>
    }
    return <span className={'flex py-1 gap-2 place-items-center'}>
                        <Avatar>
                            <AvatarImage
                                src={(order.driver.avatar && order.driver.avatar !== '') ? order.driver.avatar : undefined}/>
                            <AvatarFallback
                                className={'text-neutral-500 text-sm font-medium leading-5'}>{getFirstLetters(order.driver.firstName, order.driver.lastName)}</AvatarFallback>
                        </Avatar>
                        <p className={'font-inter text-sm text-foreground'}>{getString([
                            {element: order.driver.firstName, tag: ElementTag.name},
                            {element: order.driver.lastName, tag: ElementTag.name}
                        ])}</p>
                    </span>
}