import React from "react";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

export default function CustomerNotes({order}: Props) {
    return <div className={'pt-[1.313rem] pb-4'}>
        {
            order.notes
            ? <p className={'text-wrap break-all truncate'}>{order.notes}</p>
            : <div className={'flex flex-col'}>
                <h2 className={'text-foreground text-[1.375rem] eading-[1.625rem] font-semibold'}>No customer notes</h2>
                <p className={'text-foreground text-base pt-3 pb-6'}>The customer left no notes.</p>
            </div>
        }
    </div>
}