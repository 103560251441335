import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
    stroke: string
}

const ShevronLeft = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox={'0 0 25 25'} fill="none" stroke={props.stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="m15 18-6-6 6-6"/>
    </svg>
}

export {ShevronLeft}
