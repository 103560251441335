import * as React from "react";
import InvetoryTableLongRow
    from "src/pages/dashboarSection/orderDetails/sections/inventory/components/invetoryTableLongRow";
import getNumberOffNull from "src/utils/stringHandlers/getNumberOffNull";
import getWeight from "src/utils/stringHandlers/getWeight";
import getItemsCount from "src/utils/stringHandlers/inventory/getItemsCount";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function InventoryContent({order, setOrder, ...props}: Props) {
    return <div {...props}>
        <section key={'sub-header flex flex-col p-0'}>
            <div className={'flex justify-between items-center'}>
                <h3 className={'text-foreground text-lg font-medium'}>Summary</h3>

                <ul className={'flex gap-4 sm:gap-10 lg:gap-20 justify-between'}>
                    <li key={'items'} className={'flex flex-col gap-1'}>
                        <p className={'text-sm text-muted-foreground font-medium'}>Items</p>
                        <p className={'text-base text-foreground'}>{getItemsCount()}</p>
                    </li>


                    <li key={'volume'} className={'flex flex-col gap-1'}>
                        <p className={'text-sm text-muted-foreground font-medium'}>Volume</p>
                        <p className={'text-base text-foreground'}>{getNumberOffNull(order.volume)} cu.ft</p>
                    </li>

                    <li key={'weight'} className={'flex flex-col gap-1'}>
                        <p className={'text-sm text-muted-foreground font-medium'}>Weight</p>
                        <p className={'text-base text-foreground'}>{getWeight()} lbs </p>
                    </li>
                </ul>
            </div>
        </section>

        <section key={'sub-table'}>
            <InvetoryTableLongRow order={order} setOrder={setOrder}/>
        </section>
    </div>
}