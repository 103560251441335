import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const MobileFleetIcon= ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 21 20" fill="none">
        <path d="M8.70833 11.666H2.875V17.4993H8.70833V11.666Z" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.70833 2.5H2.875V8.33333H8.70833V2.5Z" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.041 3.33398H17.8743" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.041 7.5H17.8743" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.041 12.5H17.8743" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.041 16.666H17.8743" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export {MobileFleetIcon}