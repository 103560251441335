import {create} from 'zustand'

type Route = {
    originCoordinates: google.maps.LatLngLiteral | null
    destinationCoordinates: google.maps.LatLngLiteral | null
    directionsResult: google.maps.DirectionsResult | null
    orderId: string | null
}

interface State {
    route: Route | null
}

interface Actions {
    setRoute: (originCoordinates: google.maps.LatLngLiteral | null, destinationCoordinates: google.maps.LatLngLiteral | null, directionsResult: google.maps.DirectionsResult | null, orderId: string | null) => void
}

const useMapRoutes = create<State & Actions>(
    (set, get) => ({
        route: null,

        setRoute: (originCoordinates: google.maps.LatLngLiteral | null, destinationCoordinates: google.maps.LatLngLiteral | null, directionsResult: google.maps.DirectionsResult | null, orderId: string | null) => {
            set(
                (state) => (
                    {
                        route: {
                            directionsResult: directionsResult,
                            destinationCoordinates: destinationCoordinates,
                            originCoordinates: originCoordinates,
                            orderId: orderId
                        }
                    }
                )
            )
        },
    })
)

export default useMapRoutes;
