import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {CustomItem} from "src/utils/types/structures/customItem";
import {Item} from "src/utils/types/structures/item";
import {OrderItem} from "src/utils/types/structures/orderItem";

export function getExtendedItemName(item: OrderItem | CustomItem, items: Item[]) {
    if (isInstanceOf<OrderItem>(item, 'itemId')) {
        const machedItem = items.filter(el => el.id === item.itemId)
        if (machedItem[0]) {
            return  machedItem[0].name;
        } else return  ''
    } else return item.name
}