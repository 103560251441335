import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function onboarding() {

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/company/onboarding`), {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${useTokenStore.getState().token}`
            }
        })

        if (res.ok) {
            const data: {link: string} = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}