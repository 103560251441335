import React from "react";
import OrderFulfillmentStatusBadge from "src/components/orderFulfillmentStatusBadge";
import {ScrollArea, ScrollBar} from "src/components/scrollArea";
import Tabs from "src/pages/dashboarSection/orderDetails/buttons/buttonLayout/tabs";
import {OrderDetailsTabSections} from "src/pages/dashboarSection/orderDetails/orderDetailsLayout";
import {getLocalityAddress} from "src/utils/stringHandlers/orderDetailsStrings/getLocalityAddress";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import MoreActionsHandler from "../buttonMoreActionsHandle/moreActionsHandler";
import ButtonHandler from "../buttonStatusHandle/buttonHandler";

interface Props {
order: OrderDetails
    currentActiveTab: OrderDetailsTabSections
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}
export default function DesktopDetailsHeader({order, currentActiveTab, setOrder}: Props) {
    return <section className={` flex-none px-padding pt-8  md:shadow-none pb-6 bg-background z-30`}>

        <div key={`header`}
             className={`flex justify-between gap-4`}>

            <div className={'flex justify-between items-center w-full h-10'}>
                <section className={'flex gap-4 items-center'}>
                    <p key={'order_number'} className={'text-2xl font-semibold leading-[32px]'}>#{order.sid}</p>
                    <p key={'address'}
                       className={'hidden lg:block text-[1.375rem] font-semibold'}> {getLocalityAddress(order.originAddress) + ' to ' + getLocalityAddress(order.destinationAddress)}</p>
                    <OrderFulfillmentStatusBadge order={order}/>
                </section>

                <section className={'flex gap-4'}>
                    <ButtonHandler currentActiveTab={currentActiveTab} order={order} setOrder={setOrder}/>
                    <MoreActionsHandler/>
                </section>
            </div>
        </div>

        <ScrollArea className={"w-[95vw] sm:w-full pt-4"}>
            <Tabs currentActiveTab={currentActiveTab} order={order}/>
            <ScrollBar orientation="horizontal" className={'hidden'}/>
        </ScrollArea>
    </section>
}