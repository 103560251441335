import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";

export async function resetPassword(data: string) {
    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/auth/forgotPassword`), {
            method: 'POST',
            body: JSON.stringify({email: data}),
            headers: {
                "content-type": "application/json",
            }
        })

        if (res.ok) {
            const data = 200
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}