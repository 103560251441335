export default function getNumberOffNull (value: number | string | null | undefined) {

    let result = ''

    if (value) {
        if (typeof Number(value) === 'number'){
            result = Number(value).toFixed(0)
        }

        if (typeof value === 'string'){
            result = value
        }

    }

    const updatedResult = result
        .replace(', null', '')
        .replace('null ,', '')
        .replace('null', '')

    return updatedResult
}