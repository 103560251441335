import React from "react";
import DesktopSearch from "src/pages/dashboarSection/search/searchLayout/desktopSearch/desktopSearch";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import MobileSearch from "./mobileSearch/mobileSearch";

export default function SearchLayout() {
    const {width} = useViewport()

    if (width >= defaultDimensions.md) {return <DesktopSearch/>} else return <MobileSearch/>
}