import * as z from "zod";

const customItemSchema = z.object({
    name: z.string().min(2, {message: 'min 2 characters'}),
    count: z.coerce.number().min(1, {message: 'min 1'}),
    weight: z.number(),
    volume: z.number(),

    length: z.coerce.number().nullable().optional(),
    width: z.coerce.number().nullable().optional(),
    height: z.coerce.number().nullable().optional(),
    description: z.string().optional(),
}).superRefine( ({weight, volume}, ctx) => {
    if (isNaN(Number(weight))) {
        ctx.addIssue({
            code: "custom",
            message: "Required",
            path: ["weight"]
        })
    }

    if (isNaN(Number(volume))) {
        ctx.addIssue({
            code: "custom",
            message: "Required",
            path: ["volume"]
        })
    }
})

export default customItemSchema