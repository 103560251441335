import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Progress} from "src/components/progress";
import Header from "src/pages/dashboarSection/layout/header/header";
import AlertWrapper from "src/pages/dashboarSection/layout/popUps/alert/alertWrapper";
import DialogWrapper from "src/pages/dashboarSection/layout/popUps/dialog/dialogWrapper";
import ImageViewer from "src/pages/dashboarSection/layout/popUps/imageViewer/imageViewer";
import SheetWrapper from "src/pages/dashboarSection/layout/popUps/sheet/sheetWrapper";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import useHeaderNavStore from "src/utils/zustandStores/primary/useHeaderNavStore";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useViewport, {ScrollPos} from "src/utils/zustandStores/primary/useViewport";
import useOverlay from "src/utils/zustandStores/useOverlay";

interface Props {
    children: React.ReactNode,
}

export default function LayoutMain({children}: Props) {
    const navigate = useNavigate();
    const [isHidden, setHidden] = useState<boolean>(false);
    const {menuBar, checkCurrentURL, initTabName} = useHeaderNavStore();
    const {setDimensions, setScrollHandler, scrollPos, width} = useViewport();
    const {isImageViewerOpen} = useOrderAttachments();
    const {progressBarValue} = useOverlay();
    const {user} = useTokenStore()

    useEffect(() => {
        checkCurrentURL(window.location.pathname)
        initTabName(window.location.pathname)
    }, [children]);

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
        window.addEventListener('scroll', setScrollHandler);

        return () => {
            window.removeEventListener('scroll', setScrollHandler)
            window.removeEventListener("resize", setDimensions)
        };
    }, []);

    useEffect(() => {
        switch (scrollPos) {
            case ScrollPos.upDeadZone:
            case ScrollPos.up: {
                setHidden(false)
                break
            }

            case ScrollPos.down: {
                setHidden(true)
                break
            }
        }
    }, [scrollPos]);

    return <div className={'h-dvh flex flex-col'}>
        <Progress className={"fixed top-0 z-[90]"} value={progressBarValue}/>
        <DialogWrapper/>
        <ImageViewer/>
        <SheetWrapper/>
        <AlertWrapper/>
        <Header/>

        <div className={`h-full flex flex-col w-full ${isImageViewerOpen && 'hidden'}`}>
            <section
                className={"flex-1 container max-w-[1728px] adaptiveContainer relative pb-24 md:pb-0"}>
                    {children}

                {width < defaultDimensions.md && <section className={`w-full ${isHidden ? 'absolute bottom-0' : 'fixed bottom-0'} z-30 pt-2`}>
                    <ul
                        className={`grid ${(user && user.roles === 'DRIVER') ? 'grid-cols-2' : 'grid-cols-4'} bg-background py-4 border-t`}>
                        {
                            user && menuBar
                                .filter(el => el.mobile && el.permission.includes(user.roles))
                                .map(el =>
                                    <li key={el.title}
                                        onClick={() => navigate(el.route)}
                                        className={`flex flex-col cursor-pointer items-center px-[0.125rem] ${el.isActive ? 'text-foreground' : 'text-[#717171]'}`}>
                                        {el.icon}
                                        <p className={`text-sm ${el.isActive ? 'text-foreground font-medium' : 'text-[#717171]'}`}>{el.title}</p>
                                    </li>
                                )
                        }
                    </ul>
                </section>}
            </section>

            <footer className={'hidden lg:block w-full pt-10'}>
                <ul
                    className={"hidden md:flex bg-white border-[#E2E2E2] gap-2 sm:gap-6 items-center text-neutral-800 text-sm font-normal"}>
                    <li className={'text-foreground text-sm font-normal'}>© 2023 Marco Technologies, Inc.</li>
                    <li className={'hover:underline cursor-pointer text-foreground text-sm font-normal'}>Terms</li>
                    <li className={'hover:underline cursor-pointer text-foreground text-sm font-normal'}>Privacy</li>
                    <li className={'smNonVisible hover:underline cursor-pointer text-foreground text-sm font-normal'}>Sitemap</li>
                </ul>
            </footer>
        </div>
    </div>
}