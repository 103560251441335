import {Address} from "src/utils/types/structures/Address";

export function getLocalityAddress(address: Address | null | undefined) {

    let result = ''

    if (address) {
        let locality = ''

        if (address.locality) {
            locality = address.locality
        }
        result = locality
    }

    return result
}