import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {OrderItem} from "src/utils/types/structures/orderItem";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import {TSMap} from "typescript-map";

export type PUTMember = {
    id: string,
    firstName: string
    lastName: string
    email: string
    phone: string
    role: string
}

export async function updateOrderItems(orderId: string, orderItems: OrderItem[]) {
    const updateditemsMap: TSMap<number, number> = new TSMap<number, number>()

    const items = orderItems.map(el => {
        return {
            itemId: el.itemId,
            count: el.count
        }
    })

    items.forEach(el => {
        updateditemsMap.set(el.itemId, el.count)
    })

        try {
            const res = await fetch(ApiHost + encodeURI(`api/v1/order/items`), {
                method: 'PUT',
                body: JSON.stringify(
                    {
                        orderId: orderId,
                        items: updateditemsMap
                    }),
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${useTokenStore.getState().token}`
                }
            })

            if (res.ok) {
                const data: OrderItem[] = await res.json();
                return data
            } else {
                const err = await getDefinedError(res)
                return err
            }

        } catch (e) {
            return otherError
        }

}