export function getAddressIndexOff(address: string | null | undefined) {
    let addr: string = ''

    if (address) {
        const arr = address.split(',');
        var hasNumber = /\d/;

        const withoutString = arr.map(el => {
            if (!hasNumber.test(el)) {
                return el
            }
        }).filter(el => el !== undefined)

        addr = withoutString.toString().replace(', null', '')
    }

    return addr
}