"use client"

import * as React from "react"
import {DayPicker} from "react-day-picker"
import {ShevronLeft} from "src/media/icons/custom/shevron/shevronLeft";
import {ShevronRight} from "src/media/icons/custom/shevron/shevronRight";
import {Colors} from "src/utils/defaults/colors";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import {buttonVariants} from "../components/button"
import {cn} from "../utils/cn/cnHelper";

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
                      className,
                      classNames,
                      showOutsideDays = true,
                      ...props
                  }: CalendarProps) {

    const {width} = useViewport()

    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('', className)}
            classNames={{
                months: "flex flex-col space-between",
                month: "",
                caption: "flex justify-center relative items-center",
                caption_label: "text-foreground text-base font-semibold leading-tight",
                nav: "flex items-center h-8",
                nav_button: cn(
                    buttonVariants({variant: "ghost", size: "iconFit"}),
                    "rounded-full p-1"
                ),
                nav_button_previous: "absolute left-0",
                nav_button_next: "absolute right-0",
                table: "w-fit",
                head_row: "pt-4 flex-1 w-full h-full  items-center flex",
                head_cell:
                    "flex-1 aspect-square place-content-center text-muted-foreground text-xs font-semibold leading-none rounded-full max-w-[48px]",
                row: "flex-1 w-full flex h-full",
                cell: cn(
                    `w-fit ${(width < 376) ? 'w-[40px]' : 'w-[48px]'} aspect-square text-foreground relative text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-marcoOnHover`,
                    props.mode === "range"
                        ? "[&:has(>.day-range-end)]:rounded-r-full [&:has(>.day-range-start)]:rounded-l-full first:[&:has([aria-selected])]:bg-marcoOnHover last:[&:has([aria-selected])]:bg-marcoOnHover"
                        : "[&:has([aria-selected])]:bg-marcoOnHover"
                ),
                day: cn(
                    buttonVariants({variant: "ghost"}),
                    "rounded-full h-full w-full font-normal aria-selected:opacity-100 "
                ),
                day_range_start: "day-range-start",
                day_range_end: "day-range-end",
                day_selected:
                    "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground rounded-full !font-medium",
                day_today: "bg-accent text-accent-foreground",
                day_outside:
                    "day-outside text-foreground opacity-90 ",
                day_disabled: "text-muted-foreground opacity-50",
                day_range_middle:
                    "aria-selected:bg-marcoOnHover aria-selected:text-foreground",
                day_hidden: "invisible",
                ...classNames,
            }}
            components={{
                IconLeft: ({...props}) => <ShevronLeft size={24} stroke={Colors.foreground}/>,
                IconRight: ({...props}) => <ShevronRight size={24} stroke={Colors.foreground}/>,
            }}
            {...props}
        />
    )
}

Calendar.displayName = "Calendar"

export {Calendar}
