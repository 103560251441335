import {isValidPhoneNumber} from "react-phone-number-input";
import * as z from "zod";

const memberSchema = z.object({
    firstName: z.string().min(2, {message: 'min 2 characters'}),
    lastName: z.string().min(2, {message: 'min 2 characters'}),
    email: z.string().email(),
    phone: z
        .string()
        .refine(isValidPhoneNumber, { message: "Invalid phone number" }),
    role: z.string().min(1, {message: 'select role'}),
})

export default memberSchema