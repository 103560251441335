import * as React from "react";
import {useSearchParams} from "react-router-dom";
import {SliderTwoValues} from "src/components/sliderTwoValues";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import useSearch from "src/utils/zustandStores/useSearch";

export default function RatesPopOverContent() {
    const [searchParams, setSearchParams] = useSearchParams()

    const {
        response,
        setStartRate,
        setEndRate,
    } = useSearch();

    const updateParams = () => {
        const startRate = response.body.startRate
        const endRate = response.body.endRate

        if (startRate) {
            searchParams.set('startRate', startRate.toString())
        } else searchParams.delete('startRate')



        if (endRate) {
            if (endRate === defaultFilterValues.endRate) {
                searchParams.delete('endRate')
            } else searchParams.set('endRate', endRate.toString())

        } else searchParams.delete('endRate')

        setSearchParams(searchParams)
    }

    return <div className={'flex flex-col pt-3 pb-5 px-4'}>

        <div className={'flex flex-col gap-6'}>
            <div className={'flex justify-between'}>
                <p className={'text-foreground text-sm font-inter'}>Rate</p>
                <p className={'text-muted-foreground text-sm'}>
                    {`Min $${response.body.startRate} - `}
                    {(response.body.endRate && response.body.endRate === defaultFilterValues.maxRate) ? `Max $${response.body.endRate}+` : `Max $${response.body.endRate}`}</p>
            </div>

            <SliderTwoValues
                minStepsBetweenThumbs={7}
                step={defaultFilterValues.rateStep}
                value={[response.body.startRate ?? defaultFilterValues.startRate, response.body.endRate ?? defaultFilterValues.endRate]}
                max={defaultFilterValues.maxRate}
                min={defaultFilterValues.minRate}
                onValueChange={(e) => {
                    setStartRate(e[0]);
                    setEndRate(e[1]);
                }}
                onPointerUp={updateParams}
            />
        </div>
    </div>
}