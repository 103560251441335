import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const DotIcon = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox={'0 0 8 8'}>
        <rect width="8" height="8" rx="4"/>
    </svg>
}

export {DotIcon}