import {getGeocode, getLatLng} from "use-places-autocomplete";
import * as z from "zod";

const searchAddresssInputSchema = z.object({
    pickup: z.string(),
    destination: z.string()
}).
superRefine(async ({pickup, destination}, ctx) => {
    if (pickup) {
        try {
            const res = await getGeocode({address: pickup});
            const {lat, lng} = getLatLng(res[0]);
        } catch (e) {
            ctx.addIssue({
                code: "custom",
                message: "Invalid location",
                path: ["pickup"]
            })
        }
    }

    if (destination) {
        try {
            const res = await getGeocode({address: destination});
            const {lat, lng} = getLatLng(res[0]);
        } catch (e) {
            return ctx.addIssue({
                code: "custom",
                message: "Invalid location",
                path: ["destination"]
            })
        }
    }
})

export default searchAddresssInputSchema