import * as SliderPrimitive from "@radix-ui/react-slider";
import React from "react";

import {cn} from "../utils/cn/cnHelper";

type SliderProps = {
    className?: string;
    min: number;
    max: number;
    minStepsBetweenThumbs: number;
    step: number;
    value: number[];
    onValueChange?: (values: number[]) => void;
};

const SliderTwoValues = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({className, ...props}, ref) => (
    <SliderPrimitive.Root
        ref={ref}
        className={cn('relative flex w-full touch-none select-none items-center pb-2', className)}
        {...props}
    >
        <SliderPrimitive.Track className="relative h-[0.125rem] w-full grow overflow-hidden bg-[#E2E2E2]">
            <SliderPrimitive.Range className="absolute h-full bg-primary"/>
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
            className="block h-5 w-5 rounded-full border-primary border-2 bg-background transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"/>
        <SliderPrimitive.Thumb
            className="block h-5 w-5 rounded-full border-primary border-2 bg-background transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"/>
    </SliderPrimitive.Root>
));
SliderTwoValues.displayName = SliderPrimitive.Root.displayName;

export {SliderTwoValues};