import {zodResolver} from "@hookform/resolvers/zod";
import jwt from "jwt-decode";
import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import {PasswordInput} from "src/components/passwordInput";
import {useToast} from "src/components/useToast";
import {getCompany} from "src/utils/apiCalls/getCompany";
import {postLogin} from "src/utils/apiCalls/postLogin";
import routes from "src/utils/defaults/routes";
import {AccountStatus} from "src/utils/enums/accountStatus";
import {Role} from "src/utils/enums/role";
import {LoginRequest} from "src/utils/types/loginRequest";
import loginAndPasswordSchema from "src/utils/zodSchemas/loginAndPasswordSchema";
import useTokenStore, {TokenUser} from "src/utils/zustandStores/primary/useTokenStore";
import * as z from "zod";

export default function Login() {
    const navigate = useNavigate();
    const {toast} = useToast();
    const {setToken} = useTokenStore()

    const form = useForm<z.infer<typeof loginAndPasswordSchema>>({
        resolver: zodResolver(loginAndPasswordSchema),
        defaultValues: {
            email: "",
            password: ""
        }
    });

    async function onSubmit(values: z.infer<typeof loginAndPasswordSchema>) {
        const request: LoginRequest = {
            email: values.email,
            password: values.password
        }

        const res = await postLogin(request);
        if (typeof res === 'string') {
            setToken(res);
            const user: TokenUser = jwt(res);
            switch (user.roles) {
                case Role.driver: {
                    navigate(`${routes.loads}/booked`)
                    break
                }
                case Role.manager:
                case Role.owner: {
                    const company = await getCompany();
                    if (company.status === AccountStatus.active) {
                        navigate(`${routes.search}`)
                    } else {
                        navigate(routes.companyPaymentsAndPayouts)
                    }
                    break
                }
            }

        } else {
            toast({
                variant: "destructive",
                description: "Username or password is incorrect.",
            })
        }
    }

    return <div className={"flex flex-col gap-6"}>
        <h1 className={"text-foreground text-[2rem] font-medium"}>Welcome back</h1>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className={"flex flex-col gap-4"}>
                    <FormField
                        control={form.control}
                        name="email"
                        render={({field}) => (
                            <FormItem>
                                {form.getFieldState('email').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder={"Email"}
                                        className={`${form.getFieldState('email').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full`}
                                        autoComplete={'on'}/>
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="password"
                        render={({field}) => (
                            <FormItem>
                                {form.getFieldState('password').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                <FormControl>
                                    <PasswordInput
                                        {...field}
                                        placeholder={"Password"}
                                        className={`${form.getFieldState('password').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full `}
                                        autoComplete={'current-password'}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <div>
                        <span className={'text-foreground text-sm'}>Having trouble logging in? </span>
                        <span
                            className={"ml-1 text-marcoDarkGreen text-sm hover:underline cursor-pointer"}
                            onClick={() => navigate(routes.forgotPassword)}>
                            Reset your password.
                        </span>
                    </div>

                    <Button className={"w-full"}
                            size={'lg'}
                            variant={"green"}
                            type={'submit'}
                    >Continue
                    </Button>

                    <div>
                        <span className={'text-foreground text-sm'}>Don't have an account?</span>
                        <span
                            className={"ml-1 text-marcoDarkGreen text-sm hover:underline cursor-pointer"}
                            onClick={() => navigate(routes.signUp)}>
                            Sign Up
                        </span>
                    </div>
                </div>
            </form>
        </Form>
    </div>
}