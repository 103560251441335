"use client"

import * as ProgressPrimitive from "@radix-ui/react-progress"
import * as React from "react"

import {cn} from "../utils/cn/cnHelper";

const Progress = React.forwardRef<
    React.ElementRef<typeof ProgressPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({className, value, ...props}, ref) => (
    <ProgressPrimitive.Root
        ref={ref}
        className={cn(
            "relative w-full overflow-hidden bg-transparent h-[3px] md:h-[5px]",
            className
        )}
        {...props}
    >
        <ProgressPrimitive.Indicator
            className="h-full w-full flex-1 bg-foreground transition-all"
            style={{transform: `translateX(-${100 - (value || 0)}%)`}}
        />
    </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export {Progress}
