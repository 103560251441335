import React, {ReactNode, useEffect, useState} from "react";
import {Badge} from "src/components/badge";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {EmptyPipIcon} from "src/media/icons/custom/emptyPipIcon";
import {HalfPipIcon} from "src/media/icons/custom/halfPipIcon";
import {OrderFulfillmentStatus} from "src/utils/enums/orderFulfillmentStatus";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

export default function ({order}: Props) {
    const [badge, setBadge] = useState<ReactNode | null>(null)
    // <EmptyPipIcon size={8} stroke={Colors.marcoBadgeGray}/>
    // <HalfPipIcon size={8} className={'fill-marcoBadgeGray'}/>
    // <DotIcon size={8} className={'fill-marcoBadgeOrange'}/>
    // <EmptyPipIcon size={8} stroke={Colors.marcoBadgeOrange}/>

    useEffect(() => {
        switch (order.fulfillmentStatus) {

            case OrderFulfillmentStatus.notAccepted: {
                setBadge(
                    <Badge variant={'draft'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <HalfPipIcon size={8} className={'fill-marcoBadgeGray'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Not accepted</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.accepted: {
                setBadge(
                    <Badge variant={'accepted'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <DotIcon size={8} className={'fill-[#00A0AC]'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Accepted</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.dispatched: {
                setBadge(
                    <Badge variant={'dispatched'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <EmptyPipIcon size={8} stroke={'#007F5F'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Dispatched</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.arrivedAtPickup: {
                setBadge(
                    <Badge variant={'arrivedAtPickup'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <EmptyPipIcon size={8} stroke={'#007F5F'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>At pickup</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.atPickup: {
                setBadge(
                    <Badge variant={'arrivedAtPickup'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <EmptyPipIcon size={8} stroke={'#007F5F'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Pickup started</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.waitingSignature: {
                setBadge(
                    <Badge variant={'waitingSignature'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <EmptyPipIcon size={8} stroke={'#916A00'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Waiting signature</p>
                    </Badge>
                )
                break
            }

            // waiting payment
            // case OrderFulfillmentStatus.waitingSignature: {
            //     setBadge(
            //         <Badge variant={'waitingSignature'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
            //             <EmptyPipIcon size={8} stroke={'#916A00'}/>
            //             <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Waiting signature</p>
            //         </Badge>
            //     )
            //     break
            // }

            case OrderFulfillmentStatus.pickupStarted: {
                setBadge(
                    <Badge variant={'pickUpStarted'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <HalfPipIcon size={8} className={'fill-[#007F5F]'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Pickup started</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.inTransit: {
                setBadge(
                    <Badge variant={'pickUpStarted'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <HalfPipIcon size={8} className={'fill-[#007F5F]'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>In transit</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.completePickup: {
                setBadge(
                    <Badge variant={'pickUpStarted'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <HalfPipIcon size={8} className={'fill-[#007F5F]'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>In transit</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.startDelivery: {
                setBadge(
                    <Badge variant={'pickUpStarted'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <HalfPipIcon size={8} className={'fill-[#007F5F]'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Start delivery</p>
                    </Badge>
                )
                break
            }

            case OrderFulfillmentStatus.completeDelivery: {
                setBadge(
                    <Badge variant={'pickUpStarted'} className={'flex gap-2 px-[0.625rem] py-2 h-8'}>
                        <DotIcon size={8} className={'fill-[#007F5F]'}/>
                        <p className={'text-center text-neutral-800 text-xs font-medium font-inter leading-[16px]'}>Completed</p>
                    </Badge>
                )
                break
            }
        }

    }, [order]);

    return <>{badge}</>
}