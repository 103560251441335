import {PropertyType} from "src/utils/enums/addressEnum/propertyType";

export default function getPropertyType(propertyType: PropertyType | null | undefined) {

    let result = ''

    if (propertyType){
        switch (propertyType) {
            case PropertyType.house: {
                result = 'House'
                break
            }

            case PropertyType.apartment: {
                result = 'Apartment'
                break
            }

            case PropertyType.commercial: {
                result = 'Commercial'
                break
            }

            case PropertyType.storage: {
                result = 'Storage'
                break
            }
        }
    }

    return result
}