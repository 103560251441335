import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {OrderItem} from "src/utils/types/structures/orderItem";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";

export default function getWeight() {
    const orderItems = useOrderInventory.getState().previousExtendedItems
    const items = useOrderInventory.getState().items

    const orderItemsWeight = orderItems.map(el => {
        const orderItem = el.item
        if (isInstanceOf<OrderItem>(orderItem, 'itemId')) {
            const item = items.filter(el => el.id === orderItem.itemId)
            if (item[0]) {
                return (item[0].volume * 6) * orderItem.count
            } else
                return 0
        } else {
            return orderItem.weight * orderItem.count
        }
    })

    const totalWeight = orderItemsWeight.reduce((previousValue, currentValue) => {
        return previousValue + currentValue
    }, 0)

    return totalWeight.toFixed(0)
}