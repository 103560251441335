import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
size: number
}


const TrashBinIcon = ({...props}: Props) => {
    return <svg  {...props}  width={props.size} height={props.size} viewBox={'0 0 20 20'}>
        <path id="Vector" d="M11.5 8.25C11.6989 8.25 11.8897 8.32902 12.0303 8.46967C12.171 8.61032 12.25 8.80109 12.25 9V13.25C12.25 13.4489 12.171 13.6397 12.0303 13.7803C11.8897 13.921 11.6989 14 11.5 14C11.3011 14 11.1103 13.921 10.9697 13.7803C10.829 13.6397 10.75 13.4489 10.75 13.25V9C10.75 8.80109 10.829 8.61032 10.9697 8.46967C11.1103 8.32902 11.3011 8.25 11.5 8.25Z" />
        <path id="Vector_2" d="M9.25 9C9.25 8.80109 9.17098 8.61032 9.03033 8.46967C8.88968 8.32902 8.69891 8.25 8.5 8.25C8.30109 8.25 8.11032 8.32902 7.96967 8.46967C7.82902 8.61032 7.75 8.80109 7.75 9V13.25C7.75 13.4489 7.82902 13.6397 7.96967 13.7803C8.11032 13.921 8.30109 14 8.5 14C8.69891 14 8.88968 13.921 9.03033 13.7803C9.17098 13.6397 9.25 13.4489 9.25 13.25V9Z" />
        <path id="Vector_3" fillRule="evenodd" clipRule="evenodd" d="M7.25 5.25C7.25 4.52065 7.53973 3.82118 8.05546 3.30546C8.57118 2.78973 9.27065 2.5 10 2.5C10.7293 2.5 11.4288 2.78973 11.9445 3.30546C12.4603 3.82118 12.75 4.52065 12.75 5.25H15.75C15.9489 5.25 16.1397 5.32902 16.2803 5.46967C16.421 5.61032 16.5 5.80109 16.5 6C16.5 6.19891 16.421 6.38968 16.2803 6.53033C16.1397 6.67098 15.9489 6.75 15.75 6.75H15V12.2C15 13.88 15 14.72 14.673 15.362C14.3854 15.9265 13.9265 16.3854 13.362 16.673C12.72 17 11.88 17 10.2 17H9.8C8.12 17 7.28 17 6.638 16.673C6.07354 16.3854 5.61462 15.9265 5.327 15.362C5 14.72 5 13.88 5 12.2V6.75H4.25C4.05109 6.75 3.86032 6.67098 3.71967 6.53033C3.57902 6.38968 3.5 6.19891 3.5 6C3.5 5.80109 3.57902 5.61032 3.71967 5.46967C3.86032 5.32902 4.05109 5.25 4.25 5.25H7.25ZM8.75 5.25C8.75 4.91848 8.8817 4.60054 9.11612 4.36612C9.35054 4.1317 9.66848 4 10 4C10.3315 4 10.6495 4.1317 10.8839 4.36612C11.1183 4.60054 11.25 4.91848 11.25 5.25H8.75ZM6.5 6.75H13.5V12.2C13.5 13.065 13.499 13.623 13.464 14.048C13.431 14.456 13.374 14.607 13.336 14.681C13.1922 14.963 12.963 15.1922 12.681 15.336C12.607 15.374 12.456 15.431 12.048 15.464C11.623 15.499 11.065 15.5 10.2 15.5H9.8C8.935 15.5 8.377 15.499 7.952 15.464C7.544 15.431 7.393 15.374 7.319 15.336C7.03666 15.1924 6.80702 14.9631 6.663 14.681C6.626 14.607 6.569 14.456 6.536 14.048C6.501 13.623 6.5 13.065 6.5 12.2V6.75Z" />

        <defs>
            <rect width="375" height="894" transform="translate(-72 -858)"/>
        </defs>
    </svg>
}

export {TrashBinIcon}