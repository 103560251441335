import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
    stroke?: string
    fill?: string
}

const CheckboxDotFilled = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 14 14" fill="none">
        <path d="M0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z" stroke={props.stroke ? props.stroke : "#222222"}/>
        <path d="M4.5 7C4.5 5.61929 5.61929 4.5 7 4.5C8.38071 4.5 9.5 5.61929 9.5 7C9.5 8.38071 8.38071 9.5 7 9.5C5.61929 9.5 4.5 8.38071 4.5 7Z" fill={props.fill ? props.fill : "#222222"}/>
        <path d="M4.5 7C4.5 5.61929 5.61929 4.5 7 4.5C8.38071 4.5 9.5 5.61929 9.5 7C9.5 8.38071 8.38071 9.5 7 9.5C5.61929 9.5 4.5 8.38071 4.5 7Z" stroke={props.stroke ? props.stroke : "#222222"}/>
    </svg>
}


export {CheckboxDotFilled}