import {useEffect, useState} from "react";
import {Button} from "src/components/button";
import {Sheet, SheetClose, SheetContent, SheetTrigger} from "src/components/sheet";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import {DownLoadIcon} from "src/media/icons/custom/downloadIcon";
import {ThreeDotsVertical} from "src/media/icons/custom/threeDotsVertical";
import {TrashBinIcon} from "src/media/icons/custom/trashBinIcon";
import {Colors} from "src/utils/defaults/colors";
import {BlobType} from "src/utils/enums/blobType";
import {OrderImageOwner} from "src/utils/enums/orderImageOwner";
import getExtensionType from "src/utils/stringHandlers/images/getExtensionType";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";

const minSwipeDistance = 50

export default function MobileContent() {
    const {
        currentOrderId,
        deleteCurrent,
        downloadImage,
        setNextImage,
        setPreviousImage,
        isNext,
        isPrevious,
        totalImages,
        currentPosition,
        closeImageViewer,
        currentImage
    } = useOrderAttachments()
    const [blobType, setBlobType] = useState<BlobType | null>(null)
    const [touchStart, setTouchStart] = useState<number | null>(null)
    const [touchEnd, setTouchEnd] = useState<number | null>(null)

    useEffect(() => {
        setBlobType(null)
        if (currentImage) {
            const extension = getExtensionType(currentImage)
            setBlobType(extension)
        }
    }, [currentImage]);

    const onTouchStart = (e: React.TouchEvent<HTMLImageElement> | React.TouchEvent<HTMLVideoElement> | React.TouchEvent<HTMLDivElement>) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e: React.TouchEvent<HTMLImageElement> | React.TouchEvent<HTMLVideoElement> | React.TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            if (isNext) {
                setNextImage()
            }
        }

        if (isRightSwipe) {
            if (isPrevious) {
                setPreviousImage()
            }
        }
    }

    if (currentImage) {
        return <>
            <div
                className={'flex-none h-17 border-b border-[#222222] flex justify-between place-items-center px-4 pt-2'}>

                <div className={'flex flex-col gap-1'}>
                    <span className={'text-[#DEDEDE] text-sm font-bold'}>{currentOrderId}</span>
                    <span
                        className={'text-[#9F9F9F] text-sm font-medium'}>Media {currentPosition} of {totalImages}</span>
                </div>

                <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'} onClick={closeImageViewer}><CrossIcon size={24} className={'fill-marcoWhite'}/></Button>
            </div>

            <div className={'grow h-[calc(100dvh-200px)]'}>
                {(blobType && blobType === BlobType.image) && <img
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                    onTouchMove={onTouchMove}
                    className={`border-none rounded-xl h-full w-full object-scale-down px-4`}
                    src={currentImage.image.url}
                    alt={'img'}/>}

                {(blobType && blobType === BlobType.video) && <video
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                    onTouchMove={onTouchMove}
                    controls
                    className={`border-none rounded-xl h-full w-full object-scale-down px-4`}
                    src={currentImage.image.url}
                />}

                {(blobType && blobType === BlobType.pdf) &&
                    <iframe onTouchStart={onTouchStart}
                            onTouchEnd={onTouchEnd}
                            onTouchMove={onTouchMove}
                            className={'border-none rounded-xl h-full w-full object-scale-down px-4'} src={`https://docs.google.com/viewer?url=${currentImage.image.url}&embedded=true`}/>
               }
            </div>

            <div className={'flex-none h-13 flex justify-between place-items-center px-16 pb-4'}>
                {currentImage.owner === OrderImageOwner.carrier && <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'} onClick={deleteCurrent}><TrashBinIcon size={24} className={'fill-marcoWhite'}/></Button>}
                <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'} onClick={downloadImage}><DownLoadIcon size={24} className={'fill-marcoWhite'}/></Button>

                <Sheet>
                    <SheetTrigger asChild>
                        <Button variant={'icon'} size={"iconFit"} className={'hover:bg-gray-700 p-1'}><ThreeDotsVertical size={24} stroke={Colors.white}/></Button>
                    </SheetTrigger>
                    <SheetContent className={'bg-[#1A1A1A] py-0 px-0'}>
                        <div className={'flex-none h-17 border-b border-[#222222] place-items-center'}>
                            <div className={'flex justify-between py-3 px-4'}>
                                <div className={'flex flex-col gap-1'}>
                                    <span className={'text-[#DEDEDE] text-sm font-bold'}>{currentOrderId}</span>
                                    <span
                                        className={'text-[#9F9F9F] text-sm font-medium'}>Media {currentPosition} of {totalImages}</span>
                                </div>
                                <SheetClose>
                                    <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'}><CrossIcon size={24} className={'fill-marcoWhite'}/></Button>
                                </SheetClose>

                            </div>
                        </div>

                        <div className={'pt-6 px-4 flex flex-col gap-2'}>
                            <div className={'flex flex-col gap-1'}>
                                <span
                                    className={'text-[#DEDEDE] text-sm font-semibold'}>{currentImage.image.fileId}</span>
                                <span className={'text-[#9F9F9F] text-xs font-medium'}>600 × 150px</span>
                            </div>

                            <p className={'text-[#9F9F9F] text-xs font-medium'}>
                                Write a brief description of the file for people with visual impairment or lowbandwidth
                                connections.
                            </p>

                            <span className={'pt-1 text-[#C5D0FF] text-xs font-medium'}>Add alt text</span>
                        </div>
                    </SheetContent>
                </Sheet>
            </div>
        </>
    } else return <></>
}