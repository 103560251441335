import * as React from "react";
import {useSearchParams} from "react-router-dom";
import {SliderTwoValues} from "src/components/sliderTwoValues";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import useSearch from "src/utils/zustandStores/useSearch";

export default function VolumePopOverContent() {
    const [searchParams, setSearchParams] = useSearchParams()

    const {
        response,
        setStartVolume,
        setEndVolume,
    } = useSearch();

    const updateParams = () => {
        const startVolume = response.body.startVolume
        const endVolume = response.body.endVolume

        if (startVolume) {
            searchParams.set('startVolume', startVolume.toString())
        } else searchParams.delete('startVolume')



        if (endVolume) {
            if (endVolume === defaultFilterValues.maxVolume) {
                searchParams.delete('endVolume')
            } else searchParams.set('endVolume', endVolume.toString())

        } else searchParams.delete('endVolume')

        setSearchParams(searchParams)
    }

    return <div className={'flex flex-col pt-3 pb-5 px-4'}>

        <div className={'flex flex-col gap-6'}>
            <div className={'flex justify-between'}>
                <p className={'text-foreground text-sm font-inter'}>Volume</p>
                <p className={'text-muted-foreground text-sm'}>{`Min ${response.body.startVolume} qu.ft - `}
                    {(response.body.endVolume && response.body.endVolume === defaultFilterValues.maxVolume) ? `Max ${response.body.endVolume}+ qu.ft` : `Max ${response.body.endVolume} qu.ft`}</p>
            </div>

            <SliderTwoValues
                minStepsBetweenThumbs={5}
                step={defaultFilterValues.volumeStep}
                value={[response.body.startVolume ?? defaultFilterValues.minVolume, response.body.endVolume ?? defaultFilterValues.maxVolume]}
                max={defaultFilterValues.maxVolume}
                min={defaultFilterValues.minVolume}
                onValueChange={(e) => {
                    setStartVolume(e[0]);
                    setEndVolume(e[1]);
                }}
                onPointerUp={updateParams}
            />
        </div>
    </div>
}