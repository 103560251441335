import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const CrossIcon = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox={'0 0 16 16'}>
        <path d="M4 13L3 12L7 8L3 4L4 3L8 7L12 3L13 4L9 8L13 12L12 13L8 9L4 13Z"/>
    </svg>
}

export {CrossIcon}