import {TypeOfDocument} from "src/utils/enums/documentType";

export function getDocumentTypeString(docType: TypeOfDocument) {
    switch (docType) {
        case TypeOfDocument.contract: {
            return 'Contract'
        }

        case TypeOfDocument.estimate: {
            return 'Estimate'
        }

        case TypeOfDocument.brochure: {
            return 'Brochure'
        }

        default: {
            return ''
        }
    }
}