"use client"

import {Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport,} from "../components/toast"
import {useToast} from "../components/useToast"

export function Toaster() {
    const { toasts } = useToast()

    return (
        <ToastProvider duration={1000}>
            {toasts.map(function ({ id, title, description, action, ...props }) {
                return (
                    <Toast key={id} {...props}>
                        <div className="grid gap-1">
                            {title && <ToastTitle>{title}</ToastTitle>}
                            {description && (
                                <ToastDescription>{description}</ToastDescription>
                            )}
                        </div>
                        {action}
                        <ToastClose />
                    </Toast>
                )
            })}
            <ToastViewport />
        </ToastProvider>
    )
}
