import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";

export async function uploadFile(file: File | null, url: string) {
if  (file) {
    try {
        const res = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,
            },
            body:file
        })

        if (res.ok) {
            return 200
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}

}