import {create} from 'zustand'

interface State {
    isOpen: boolean
    progressBarValue: number
}

interface Actions {
    setOpen: (open: boolean) => void
    setProgressBarValue: (value: number) => void
}

const useOverlay = create<State & Actions>(
    (set, get) => ({
        isOpen: false,
        progressBarValue: 0,

        setOpen: (open: boolean) => {
            set(
                (state) => (
                    {
                        isOpen: open
                    }
                )
            )
        },

        setProgressBarValue: (value: number) => {
            set(
                (state) => (
                    {
                        progressBarValue: value
                    }
                )
            )
        }
    })
)

export default useOverlay;
