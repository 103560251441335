import * as React from "react";
import {useSearchParams} from "react-router-dom";
import {Slider} from "src/components/slider";
import {cn} from "src/utils/cn/cnHelper";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import useSearch from "src/utils/zustandStores/useSearch";

export default function RadiusPopOverContent() {
    const {setOriginRadius, setDestinationRadius, response} = useSearch()
    const [searchParams, setSearchParams] = useSearchParams()

    const updateOrigin = () => {
        const originRadius = response.body.originRadius

        if (originRadius) {
            if (originRadius === defaultFilterValues.originRadius) {
                searchParams.delete('originRadius')
            } else searchParams.set('originRadius', originRadius.toString());

        } else searchParams.delete('originRadius')

        setSearchParams(searchParams)
    }

    const updateDestination = () => {
        const destinationRadius = response.body.destinationRadius

        if (destinationRadius) {
            if (destinationRadius === defaultFilterValues.destinationRadius) {
                searchParams.delete('destinationRadius')
            } else searchParams.set('destinationRadius', destinationRadius.toString());
        } else searchParams.delete('destinationRadius')

        setSearchParams(searchParams)
    }


    return <div className={'flex flex-col gap-6 font-inter pt-3 pb-5 px-3'}>

        <div className={'flex flex-col gap-6'}>
            <div className={'flex justify-between'}>
                <p className={'text-foreground text-sm font-inter'}>Pickup radius</p>
                <p className={'text-muted-foreground text-sm font-normal font-inter'}>Max {response.body.originRadius}{response.body.originRadius === defaultFilterValues.maxOriginRadius ? '+' : ''} mil</p>
            </div>

            <Slider
                value={[response.body.originRadius ?? 0]}
                onValueChange={(e) => {
                    setOriginRadius(e[0]);
                }}
                max={defaultFilterValues.maxOriginRadius}
                min={defaultFilterValues.minOriginRadius}
                step={defaultFilterValues.pickupRadiusStep}
                className={cn("w-[100%]")}
                onPointerUp={updateOrigin}
            />
        </div>

        <div className={'flex flex-col gap-6 pb-2'}>
            <div className={'flex justify-between'}>
                <p className={'text-foreground text-sm font-inter'}>Delivery radius</p>
                <p className={'text-muted-foreground text-sm'}>Max {response.body.destinationRadius}{response.body.destinationRadius === defaultFilterValues.maxDestinationRadius ? '+' : ''} mil</p>
            </div>

            <Slider
                value={[response.body.destinationRadius ?? 0]}
                onValueChange={(e) => {
                    setDestinationRadius(e[0])
                }}
                max={defaultFilterValues.maxDestinationRadius}
                min={defaultFilterValues.minDestinationRadius}
                step={defaultFilterValues.deliveryRadiusStep}
                className={cn("w-[100%]")}
                onPointerUp={updateDestination}
            />
        </div>
    </div>
}