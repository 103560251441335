import {Options} from "check-password-strength";

const customOptions: Options<string> | undefined = [
    {
        id: 0,
        value: "Too weak",
        minDiversity: 0,
        minLength: 0
    },
    {
        id: 1,
        value: "Weak",
        minDiversity: 2,
        minLength: 8
    },
    {
        id: 2,
        value: "Strong",
        minDiversity: 4,
        minLength: 8
    },
]

export default customOptions