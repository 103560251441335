import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {RegisterRequest} from "src/utils/types/registerRequest";

export async function setPassword(data: RegisterRequest) {
    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/auth/register`), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "content-type": "application/json"
            }
        })

        if (res.ok) {
            return 200
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}