import {zodResolver} from "@hookform/resolvers/zod";
import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import {useToast} from "src/components/useToast";
import {resetPassword} from "src/utils/apiCalls/resetPassword";
import routes from "src/utils/defaults/routes";
import forgotPasswordSchema from "src/utils/zodSchemas/forgotPasswordSchema";
import * as z from "zod";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const {toast} = useToast()

    const form = useForm<z.infer<typeof forgotPasswordSchema>>({
        resolver: zodResolver(forgotPasswordSchema),
        defaultValues: {
            email: ""
        }
    });

    async function onSubmit(values: z.infer<typeof forgotPasswordSchema>) {
        const res = await resetPassword(values.email);
        if (res === 200) {
            toast({
                description: "Registration link sent to email",
            })
            navigate(routes.main)
        } else {
            toast({
                variant: "destructive",
                description: `${res.message}`,
            })
        }
    }

    return <div className={"flex flex-col gap-6"}>
        <div className={"flex flex-col gap-2"}>
            <h1 className={"text-foreground text-[2rem] font-medium"}>Forgot Password</h1>

            <p className={"text-muted-foreground font-normal"}>
            Please enter your email address. We will send you an email to reset your password.
        </p>
        </div>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className={"flex flex-col gap-4"}>
                    <FormField
                        control={form.control}
                        name="email"
                        render={({field}) => (
                            <FormItem>
                                {form.getFieldState('email').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder={"Email address"}
                                        autoComplete={'off'}
                                        className={`${form.getFieldState('email').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full`}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <Button
                        className={"w-full"}
                        size={'lg'}
                        variant={"green"}>
                        Send email
                    </Button>

                    <div>
                        <span className={'text-foreground text-sm'}>Don't have an account?</span>
                        <span
                            className={"ml-1 text-marcoDarkGreen text-sm hover:underline cursor-pointer"}
                            onClick={() => navigate(routes.signUp)}>
                            Sign Up
                        </span>
                    </div>
                </div>
            </form>
        </Form>
    </div>
}