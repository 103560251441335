import {toast} from "src/components/useToast";
import {getImages} from "src/utils/apiCalls/getImages";
import {ApiHost} from "src/utils/defaults/apiHost";
import {OrderImageOwner} from "src/utils/enums/orderImageOwner";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderImage, OrderImageStatus} from "src/utils/types/structures/orderImage";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import {create} from 'zustand'

export type ExtendedOrderImage = {
    isEditable: boolean
    isSelected: boolean
    image: OrderImage
    owner: OrderImageOwner
    id: string
}

interface State {
    carrierImages: ExtendedOrderImage[]
    customerImages: ExtendedOrderImage[]
    systemImages: ExtendedOrderImage[]
    adminImages: ExtendedOrderImage[]
    isAnySelected: boolean
    isImageViewerOpen: boolean
    currentImage: ExtendedOrderImage | null
    totalImages: number
    currentPosition: number
    isNext: boolean
    isPrevious: boolean
    skeletons: string[]
    currentOrderId: string | null
}

interface Actions {
    initStore: (images: OrderImage[], orderId: string) => void
    setImageViewerOpen: (orderImage: ExtendedOrderImage) => void
    setAllCarrierImagesUncheked: () => void
    onCarrierImageCheckChange: (id: string) => void
    closeImageViewer: () => void
    setNextImage: () => void
    setPreviousImage: () => void
    setSkeletonsCount: (count: number) => void

    downloadImage: () => void
    deleteCurrent: () => void
}

const useOrderAttachments = create<State & Actions>(
    (set, get) => ({
        carrierImages: [],
        customerImages: [],
        systemImages: [],
        adminImages: [],
        isAnySelected: false,
        isImageViewerOpen: false,

        currentImage: null,
        totalImages: 0,
        currentPosition: 0,
        isNext: false,
        isPrevious: false,

        skeletons: [],
        currentOrderId: null,

        deleteCurrent: async () => {
            const previousImage = useOrderAttachments.getState().currentImage
            const orderId = useOrderAttachments.getState().currentOrderId
            const isNext = useOrderAttachments.getState().isNext

            if (previousImage && orderId) {
                const previousImageIndex = useOrderAttachments.getState().carrierImages.indexOf(previousImage)

                const res = await fetch(ApiHost + encodeURI(`api/v1/file/`), {
                    method: 'DELETE',
                    body: JSON.stringify({fileId: previousImage.image.fileId}),
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${useTokenStore.getState().token}`
                    }
                })
                if (res.ok) {
                    const currentImages = await getImages(orderId)

                    if (isInstanceOf<ErrorMessage>(currentImages, 'message')) {
                        useOrderAttachments.getState().initStore([], orderId)
                        toast({
                            variant: 'destructive',
                            description: 'Fetch error'
                        })
                        set(
                            (state) => (
                                {
                                    isImageViewerOpen: false
                                }
                            )
                        )
                    } else {
                        useOrderAttachments.getState().initStore(currentImages, orderId)
                    }

                    const updatedImages = useOrderAttachments.getState().carrierImages

                    if  (updatedImages.length === 0 || !isNext) {
                        set(
                            (state) => (
                                {
                                    isImageViewerOpen: false
                                }
                            )
                        )
                    } else {
                        let isNext = true
                        let isPrevious = true

                        const totalImages = updatedImages.length
                        const currentIndex = previousImageIndex
                        const currentPosition = previousImageIndex + 1

                        if (currentIndex === 0) {
                            isPrevious = false
                        }

                        if (currentIndex === updatedImages.length - 1) {
                            isNext = false
                        }

                        set(
                            (state) => (
                                {
                                    currentImage: updatedImages[currentIndex],
                                    currentPosition: currentPosition,
                                    totalImages: totalImages,
                                    isNext: isNext,
                                    isPrevious: isPrevious
                                }
                            )
                        )
                    }
                } else {
                    toast({
                        variant: 'destructive',
                        description: 'Fetch error'
                    })
                    set(
                        (state) => (
                            {
                                isImageViewerOpen: false
                            }
                        )
                    )
                }
            }
        },

        downloadImage: async () => {
            const currentImage = useOrderAttachments.getState().currentImage

            if (currentImage) {
                const res = await fetch(currentImage.image.url)

                if (res.ok) {
                    const imageBlob = await res.blob()
                    const imageURL = URL.createObjectURL(imageBlob)

                    const link = document.createElement('a')
                    link.href = imageURL
                    link.download = imageURL
                    document.body.appendChild(link)
                    link.click()
                } else {
                    toast({
                        variant: 'destructive',
                        description: 'Error fetching data'
                    })
                }
            }
        },

        setSkeletonsCount: (count: number) => {
            const array: string[] = []
            for (let i = 0; i < count; i++) {
                array.push(`${i}idKey`)
            }

            set(
                (state) => (
                    {
                        skeletons: array
                    }
                )
            )
        },

        setNextImage: () => {
            let newImage: ExtendedOrderImage | null = null
            let isNext = true
            let isPrevious = true
            const currentImage = useOrderAttachments.getState().currentImage
            const currentPosition = useOrderAttachments.getState().currentPosition


            const carrierImages = useOrderAttachments.getState().carrierImages
            const customerImages = useOrderAttachments.getState().customerImages
            const systemImages = useOrderAttachments.getState().systemImages
            const adminImages = useOrderAttachments.getState().adminImages

            if (currentImage) {
                switch (currentImage.owner) {
                    case OrderImageOwner.carrier: {
                        const nextIndex = carrierImages.indexOf(currentImage) + 1
                        newImage = carrierImages[nextIndex]
                        if (nextIndex === carrierImages.length-1) {
                            isNext = false
                        }
                        if (nextIndex === 0) {
                            isPrevious = false
                        }
                        break
                    }

                    case OrderImageOwner.customer: {
                        const nextIndex = customerImages.indexOf(currentImage) + 1
                        newImage = customerImages[nextIndex]
                        if (nextIndex === customerImages.length-1) {
                            isNext = false
                        }
                        if (nextIndex === 0) {
                            isPrevious = false
                        }
                        break
                    }

                    case OrderImageOwner.system: {
                        const nextIndex = systemImages.indexOf(currentImage) + 1
                        newImage = systemImages[nextIndex]
                        if (nextIndex === systemImages.length-1) {
                            isNext = false
                        }
                        break
                    }

                    case OrderImageOwner.admin: {
                        const nextIndex = adminImages.indexOf(currentImage) + 1
                        newImage = adminImages[nextIndex]
                        if (nextIndex === adminImages.length-1) {
                            isNext = false
                        }
                        break
                    }
                }

                set(
                    (state) => (
                        {

                            currentPosition: currentPosition + 1,
                            currentImage: newImage,
                            isNext: isNext,
                            isPrevious: isPrevious
                        }
                    )
                )
            }
        },

        setPreviousImage: () => {
            let newImage: ExtendedOrderImage | null = null
            let isNext = true
            let isPrevious = true
            const currentImage = useOrderAttachments.getState().currentImage
            const currentPosition = useOrderAttachments.getState().currentPosition


            const carrierImages = useOrderAttachments.getState().carrierImages
            const customerImages = useOrderAttachments.getState().customerImages
            const systemImages = useOrderAttachments.getState().systemImages
            const adminImages = useOrderAttachments.getState().adminImages

            if (currentImage) {
                switch (currentImage.owner) {
                    case OrderImageOwner.carrier: {
                        const prevIndex = carrierImages.indexOf(currentImage) - 1
                        newImage = carrierImages[prevIndex]
                        if (prevIndex === carrierImages.length-1) {
                            isNext = false
                        }
                        if (prevIndex === 0) {
                            isPrevious = false
                        }
                        break
                    }

                    case OrderImageOwner.customer: {
                        const prevIndex = customerImages.indexOf(currentImage) - 1
                        newImage = customerImages[prevIndex]
                        if (prevIndex === customerImages.length-1) {
                            isNext = false
                        }
                        if (prevIndex === 0) {
                            isPrevious = false
                        }
                        break
                    }

                    case OrderImageOwner.system: {
                        const prevIndex = systemImages.indexOf(currentImage) - 1
                        newImage = systemImages[prevIndex]
                        if (prevIndex === systemImages.length-1) {
                            isNext = false
                        }
                        if (prevIndex === 0) {
                            isPrevious = false
                        }
                        break
                    }

                    case OrderImageOwner.admin: {
                        const prevIndex = adminImages.indexOf(currentImage) - 1
                        newImage = adminImages[prevIndex]
                        if (prevIndex === adminImages.length-1) {
                            isNext = false
                        }
                        if (prevIndex === 0) {
                            isPrevious = false
                        }
                        break
                    }
                }

                set(
                    (state) => (
                        {

                            currentPosition: currentPosition - 1,
                            currentImage: newImage,
                            isNext: isNext,
                            isPrevious: isPrevious
                        }
                    )
                )
            }
        },


        closeImageViewer: () => {
            set(
                (state) => (
                    {

                        isImageViewerOpen: false
                    }
                )
            )
        },

        onCarrierImageCheckChange: (id: string) => {
            const carrierImages = useOrderAttachments.getState().carrierImages
            const updatedCarrierImages = carrierImages.map(el => {
                if (el.id === id) {
                    const updatedItem: ExtendedOrderImage = {
                        ...el,
                        isSelected: !el.isSelected
                    }
                    return updatedItem
                } else return el
            })

            const isAnySelected = updatedCarrierImages.filter(el => el.isSelected)

            set(
                (state) => (
                    {
                        carrierImages: updatedCarrierImages,
                        isAnySelected: isAnySelected.length > 0,
                    }
                )
            )
        },


        setAllCarrierImagesUncheked: () => {
            const carrierImages = useOrderAttachments.getState().carrierImages
            const updatedItems = carrierImages.map(el => {
                const updatedImage: ExtendedOrderImage = {
                    ...el,
                    isSelected: false
                }
                return updatedImage
            })

            set(
                (state) => (
                    {
                        carrierImages: updatedItems,
                        isAnySelected: false,
                    }
                )
            )
        },

        initStore: (images: OrderImage[], orderId: string) => {

            const carrierImages = images
                .filter(el => (el.owner === OrderImageOwner.carrier) && (el.status === OrderImageStatus.uploaded))
                .sort((a, b) => new Date(a.createOn).getTime()-new Date(b.createOn).getTime())
                .map(el => {
                const carrierImageExtended: ExtendedOrderImage = {
                    image: el,
                    owner: el.owner,
                    isSelected: false,
                    isEditable: true,
                    id: el.fileId
                }
                return carrierImageExtended
            })

            const customerImages = images.filter(el => el.owner === OrderImageOwner.customer).map(el => {
                const customerImageExtended: ExtendedOrderImage = {
                    image: el,
                    owner: el.owner,
                    isSelected: false,
                    isEditable: false,
                    id: el.fileId
                }
                return customerImageExtended
            })

            const systemImages = images.filter(el => el.owner === OrderImageOwner.system).map(el => {
                const systemImageExtended: ExtendedOrderImage = {
                    image: el,
                    owner: el.owner,
                    isSelected: false,
                    isEditable: false,
                    id: el.fileId
                }
                return systemImageExtended
            })

            const adminImages = images.filter(el => el.owner === OrderImageOwner.admin).map(el => {
                const adminImageExtended: ExtendedOrderImage = {
                    image: el,
                    owner: el.owner,
                    isSelected: false,
                    isEditable: false,
                    id: el.fileId
                }
                return adminImageExtended
            })

            set(
                (state) => (
                    {
                        carrierImages: carrierImages,
                        customerImages: customerImages,
                        systemImages: systemImages,
                        adminImages: adminImages,
                        isAnySelected: false,
                        currentOrderId: orderId
                    }
                )
            )
        },

        setImageViewerOpen: (orderImage: ExtendedOrderImage) => {
            const carrierImages = useOrderAttachments.getState().carrierImages
            const customerImages = useOrderAttachments.getState().customerImages
            const systemImages = useOrderAttachments.getState().systemImages
            const adminImages = useOrderAttachments.getState().adminImages

            let currentPosition = 0
            let totalImages = 0

            let isNext = true
            let isPrevious = true

            switch (orderImage.owner) {
                case OrderImageOwner.carrier: {
                    totalImages = carrierImages.length
                    const currentIndex = carrierImages.indexOf(orderImage)
                    currentPosition = currentIndex + 1
                    if (currentIndex === 0) {
                        isPrevious = false
                    }

                    if (currentIndex === carrierImages.length - 1) {
                        isNext = false
                    }
                    break
                }

                case OrderImageOwner.customer: {
                    totalImages = customerImages.length
                    const currentIndex = customerImages.indexOf(orderImage)
                    currentPosition = currentIndex + 1
                    if (currentIndex === 0) {
                        isPrevious = false
                    }

                    if (currentIndex === customerImages.length - 1) {
                        isNext = false
                    }
                    break
                }

                case OrderImageOwner.system: {
                    totalImages = systemImages.length
                    const currentIndex = systemImages.indexOf(orderImage)
                    currentPosition = currentIndex + 1
                    if (currentIndex === 0) {
                        isPrevious = false
                    }

                    if (currentIndex === systemImages.length - 1) {
                        isNext = false
                    }
                    break
                }

                case OrderImageOwner.admin: {
                    totalImages = adminImages.length
                    const currentIndex = adminImages.indexOf(orderImage)
                    currentPosition = currentIndex + 1
                    if (currentIndex === 0) {
                        isPrevious = false
                    }

                    if (currentIndex === adminImages.length - 1) {
                        isNext = false
                    }
                    break
                }
            }

            set(
                (state) => (
                    {
                        isImageViewerOpen: true,
                        currentImage: orderImage,
                        currentPosition: currentPosition,
                        totalImages: totalImages,
                        isNext: isNext,
                        isPrevious: isPrevious
                    }
                )
            )
        }
    }),
)

export default useOrderAttachments;