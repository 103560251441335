import moment from "moment";
import React, {useEffect, useState} from "react";
import {DateRange} from "react-day-picker";
import {useSearchParams} from "react-router-dom";
import {Calendar} from "src/components/calendar";
import DistancePopOverContent from "src/pages/dashboarSection/search/searchLayout/filters/distancePopOverContent";
import RadiusPopOverContent from "src/pages/dashboarSection/search/searchLayout/filters/radiusPopOverContent";
import RatesPopOverContent from "src/pages/dashboarSection/search/searchLayout/filters/ratesPopOverContent";
import SortFilterContent from "src/pages/dashboarSection/search/searchLayout/filters/sortFilterContent";
import {FilterType} from "src/pages/dashboarSection/search/searchLayout/mobileSearch/mobileFilter";
import useSearch from "src/utils/zustandStores/useSearch";
import VolumePopOverContent from "../filters/volumePopOverContent";

interface Props {
    filterType: FilterType | null
}

export default function MobileFilterContent({filterType}: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [adds, setAdds] = useState<string>()

    useEffect(() => {
switch (filterType) {
    case FilterType.sort: {
        setAdds('w-fit p-[5px]')
        break
    }

    case FilterType.date: {
        setAdds('w-fit p-4')
        break
    }

    default: {
       setAdds('w-full p-2')
    }


}
    }, [filterType]);

    const {
        response,
        setDate,
    } = useSearch();
    const updateDate = (e: DateRange | undefined) => {
        if (!e) {
            searchParams.delete('startDate');
            searchParams.delete('endDate');
        }

        if (e?.from) {
            const startDate = moment(e.from, 'YYYY-MM-DD').format('YYYY-MM-DD')
            searchParams.set('startDate', startDate);
        }

        if (e?.to) {
            const endDate = moment(e.to, 'YYYY-MM-DD').format('YYYY-MM-DD')
            searchParams.set('endDate', endDate);
        }

        setSearchParams(searchParams)
    }

    return <div className={`absolute ${adds} h-fit border rounded-md mt-[-0.5rem] bg-background z-[80]`}>
        {(filterType && filterType === FilterType.date) && <Calendar initialFocus mode="range" defaultMonth={new Date()} fromDate={new Date()} selected={response.body.date} numberOfMonths={1} onSelect={(e) => {setDate(e);updateDate(e)}}/>}
        {(filterType && filterType === FilterType.radius) && <RadiusPopOverContent/>}
        {(filterType && filterType === FilterType.volume) && <VolumePopOverContent/>}
        {(filterType && filterType === FilterType.distance) && <DistancePopOverContent/>}
        {(filterType && filterType === FilterType.rate) && <RatesPopOverContent/>}
        {(filterType && filterType === FilterType.sort) && <SortFilterContent/>}
    </div>
}