import {useEffect, useState} from "react";
import {EnlargeIcon} from "src/media/icons/custom/enlargeIcon";
import {OptionsMenuIcon} from "src/media/icons/custom/optionsMenuIcon";
import {BlobType} from "src/utils/enums/blobType";
import getExtensionType from "src/utils/stringHandlers/images/getExtensionType";
import useOrderAttachments, {
    ExtendedOrderImage
} from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import imageCover from '../../../../../../media/imageCover.png'

interface Props {
    orderImage: ExtendedOrderImage
}
export default function UneditableImageCover({orderImage}: Props) {
    const [isHovered, setHovered] = useState<boolean>(false);
    const {setImageViewerOpen} = useOrderAttachments()
    const [blobType, setBlobType] = useState<BlobType | null>(null)

    useEffect(() => {
        setBlobType(null)
        if (orderImage) {
            const extension = getExtensionType(orderImage)
            setBlobType(extension)
        }
    }, [orderImage]);

    return <div
        className={`relative w-full aspect-square`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>

        <div
            className={`absolute h-full w-full flex content-center}`}>
            {(blobType && blobType === BlobType.image) &&  <img
                className={`outline outline-card/0 border-2 rounded-xl h-full w-full object-cover`}
                src={orderImage.image.url}
                alt={'img'}/>}

            {(blobType && blobType === BlobType.video) && <video
                className={`outline outline-card/0 border-2 rounded-xl h-full w-full object-cover`}
                preload="metadata"
                src={`${orderImage.image.url}#t=0.1`}
            />}

            {(blobType && blobType === BlobType.pdf) && <img
                className={`outline outline-card/0 border-2 rounded-xl h-full w-full object-cover`}
                src={imageCover}
                alt={'img'}/>}
        </div>

        {isHovered && <div
            className={"cursor-pointer absolute w-full h-full grid grid-cols-1 shadow-md bg-black bg-opacity-50 rounded-xl"}>
            <div className={'absolute right-2 mt-2'} onClick={(e) => {e.stopPropagation()}}>
                <OptionsMenuIcon size={20} className={'fill-marcoWhite'}/>
            </div>

            <div
                className={"absolute cursor-pointer place-self-center"}
                onClick={(e) => {
                    setImageViewerOpen(orderImage)
                    e.stopPropagation()
                }}>
                <EnlargeIcon size={20} className={'fill-marcoWhite'}/>
            </div>

        </div>}
    </div>
}