import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const MinusIcon = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 17 16" fill="none" stroke="currentColor"
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3.83398 8H13.1673" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export {MinusIcon}