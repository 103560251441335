import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Separator} from "src/components/separator";
import NoResults from "src/pages/dashboarSection/search/orderList/noResults";
import OrderList from "src/pages/dashboarSection/search/orderList/orderList";
import SortOrdersFilter
    from "src/pages/dashboarSection/search/searchLayout/desktopSearch/desktopFilterCover/sortOrdersFilter";
import SearchInputDesktop from "src/pages/dashboarSection/search/searchLayout/desktopSearch/searchInputDesktop";
import ResetFilter from "src/pages/dashboarSection/search/searchLayout/filters/resetFilter";
import {LocationData} from "src/pages/dashboarSection/search/searchLayout/mobileSearch/mobileSearch";
import {getOrders} from "src/utils/apiCalls/search/getOrders";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderSearch} from "src/utils/types/structures/orderSearch";
import searchAddresssInputSchema from "src/utils/zodSchemas/searchAddresssInputSchema";
import useSearch from "src/utils/zustandStores/useSearch";
import {getGeocode, getLatLng} from "use-places-autocomplete";
import * as z from "zod";
import {CalendarDateRangePicker} from "./desktopFilterCover/calendarRangePicker";
import DistancePopOver from "./desktopFilterCover/distancePopOver";
import RadiusPopOver from "./desktopFilterCover/radiusPopOver";
import RatesPopOver from "./desktopFilterCover/ratesPopOver";
import VolumePopOver from "./desktopFilterCover/volumePopOver";


export default function DesktopSearch() {
    const navigate = useNavigate();
    const {response} = useSearch();
    const page = useRef(0)
    const [totalRendered, setTotalRendered] = useState<OrderSearch[]>([]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [locationData, setLocationData] = useState<LocationData>({
        destination: {
            shouldFetchData: true,
            lat: null,
            lng: null,
        },

        origin: {
            shouldFetchData: true,
            lat: null,
            lng: null,
        }
    })

    const form = useForm<z.infer<typeof searchAddresssInputSchema>>({
        resolver: zodResolver(searchAddresssInputSchema),
        defaultValues: {
            pickup: '',
            destination: ''
        }
    });

    const {
        getValuesFromURL,
        resetFilter
    } = useSearch()

    const loadMoreOrders = async () => {
        if (totalRendered.length < totalOrders) {
            if (response.request) {
                page.current = page.current + 1
                const res = await getOrders(response.request, page.current);
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.login))

                } else {
                    setTotalRendered(prevState => [...prevState, ...res.content]);
                }
            }
        }
    }

    const initOrders = async () => {
        setLoading(true)
        page.current = 0

        let originLat: number | null = null
        let originLng: number | null = null
        let destinationLat: number | null = null
        let destinationLng: number | null = null
        const origin = searchParams.get('originAddress');
        const destination = searchParams.get('destinationAddress');

        if (locationData.origin.shouldFetchData) {
            if (origin) {
                try {
                    const res = await getGeocode({address: origin});
                    const {lat, lng} = getLatLng(res[0]);
                    originLat = lat;
                    originLng = lng;
                    form.setValue('pickup', origin)
                } catch (e) {
                    originLat = null;
                    originLng = null;
                    form.setValue('pickup', origin)
                    form.setError('pickup', {message: 'Invalid location'})
                }
            } else {
                originLat = null;
                originLng = null;
            }
        } else {
            originLat = locationData.origin.lat;
            originLng = locationData.origin.lng;
        }

        if (locationData.destination.shouldFetchData) {

            if (destination) {
                try {
                    const res = await getGeocode({address: destination});
                    const {lat, lng} = getLatLng(res[0]);
                    destinationLat = lat;
                    destinationLng = lng;
                    form.setValue('destination', destination)
                } catch (e) {
                    destinationLat = null;
                    destinationLng = null;
                    form.setValue('pickup', destination)
                    form.setError('destination', {message: 'Invalid location'})
                }
            } else {
                destinationLat = null;
                destinationLng = null;
            }
        } else {
            destinationLat = locationData.destination.lat;
            destinationLng = locationData.destination.lng;
        }

        const filterValuesObject = await getValuesFromURL(searchParams, originLat, originLng, destinationLat, destinationLng);
        const res = await getOrders(filterValuesObject, page.current)
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate(routes.login))
        } else {
            setTotalOrders(res.totalElements);
            setTotalRendered(res.content)
        }
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        resetFilter()
    }, []);

    useEffect(() => {
        initOrders().then(value => setLoading(false))
    }, [searchParams]);

    return <div className={"h-full"}>

        <section className={'px-padding'}>
            <h1 className={"hidden md:block text-[1.5rem] align-text-top font-semibold font-inter pt-8 pb-6"}>Search loads</h1>

            <SearchInputDesktop setLocationData={setLocationData} form={form} isLoading={isLoading}/>

            <div className={'w-full flex gap-2 h-[76px] items-center'}>
                <SortOrdersFilter/>
                <Separator orientation="vertical" className={'h-5'}/>
                <CalendarDateRangePicker/>
                <RadiusPopOver/>
                <VolumePopOver/>
                <DistancePopOver/>
                <RatesPopOver/>
                <ResetFilter/>
            </div>

            <h3 className={'text-base font-semibold pt-[0.375rem]'}>{totalOrders} loads</h3>
        </section>


        <InfiniteScroll
            next={loadMoreOrders}
            hasMore={totalRendered.length < totalOrders}
            loader={<div>Loading</div>}
            dataLength={totalRendered.length}
        >
            <OrderList orders={totalRendered}/>
        </InfiniteScroll>

        {!isLoading && totalOrders === 0 && <NoResults className={'flex flex-col items-center pt-10'}/>}
    </div>
}