import menuBar, {MenuBar} from "src/utils/defaults/menuBar";
import routes from "src/utils/defaults/routes";
import {create} from 'zustand'

interface HeaderNavState {
    menuBar: MenuBar[]
}

interface HeaderNavActions {
    checkCurrentURL: (currentURL: string) => void;
    initTabName: (currentURL: string) => void;
}

const useHeaderNavStore = create<HeaderNavState & HeaderNavActions>(
    (set, get) => ({
        menuBar: menuBar,

        checkCurrentURL: (currentURL: string) => {
            const url = currentURL.split('/');

            if (url) {
                const updatedBar: MenuBar[] = menuBar.map(el => {
                    const currentElRoute = el.route.split('/')[1]
                    switch (true) {

                        case (currentElRoute === 'settings' && url[1] === 'account-settings'): {
                            return {
                                ...el,
                                isActive: true
                            }
                        }

                        case (currentElRoute === 'settings' && url[1] === 'company-settings'): {
                            return {
                                ...el,
                                isActive: true
                            }
                        }

                        case (url[1] === currentElRoute): {
                            return {
                                ...el,
                                isActive: true
                            }
                        }

                        default: {
                            return {
                                ...el,
                                isActive: false
                            }
                        }
                    }
                })

                set(
                    (state) => (
                        {
                            menuBar: updatedBar
                        }
                    )
                )
            }
        },

        initTabName: (currentURL: string) => {
            const url = currentURL.split('/')

            if (url) {
                switch (true) {
                    case (currentURL === routes.search): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Search | Marco'
                        }
                        break
                    }

                    case ('/' + url[1] === routes.loads): {
                        if (url[3] && (url[3] !== 'booked' && url[2] !== 'in-progress' && url[2] !== 'completed' && url[2] !== 'all')) {
                            const tabTitle = document.getElementById('title')
                            if (tabTitle) {
                                tabTitle.textContent = 'Order details | Marco'
                            }
                            break
                        } else {
                            const tabTitle = document.getElementById('title')
                            if (tabTitle) {
                                tabTitle.textContent = 'Loads | Marco'
                            }
                            break
                        }
                    }

                    case (currentURL === routes.fleetManagement): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Fleet | Marco'
                        }
                        break
                    }

                    case (currentURL === routes.mobileAccountSettings): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Settings | Marco'
                        }
                        break
                    }

                    case (currentURL === routes.companyDetails): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Company details | Marco'
                        }
                        break
                    }

                    case (currentURL === routes.companyPaymentsAndPayouts): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Payments and payouts | Marco'
                        }
                        break
                    }

                    case (currentURL === routes.userPersonalDetails): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Personal details | Marco'
                        }
                        break
                    }

                    case (currentURL === routes.userLoginAndSecurity): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Login and security | Marco'
                        }
                        break
                    }

                    case (currentURL === routes.login): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Sign in | Marco'
                        }
                        break
                    }

                    case (currentURL === routes.forgotPassword): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Restore password | Marco'
                        }
                        break
                    }

                    case (currentURL === routes.signUp): {
                        const tabTitle = document.getElementById('title')
                        if (tabTitle) {
                            tabTitle.textContent = 'Sign up | Marco'
                        }
                        break
                    }
                }
            }
        },
    }),
)

export default useHeaderNavStore;
