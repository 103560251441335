import {passwordStrength} from "check-password-strength";
import customOptions from "src/utils/defaults/passwordRequirements";
import * as z from "zod";

const setUpPasswordSchema = z.object(
    {
        token: z.string(),
        password: z.string().min(8, {message: 'Password must contain at least 8 characters'}),
        repeatPassword: z.string()
    })
    .superRefine(({password, repeatPassword}, ctx) => {
        const passStrengthResult = passwordStrength(password, customOptions).value

        switch (true) {
            case (passStrengthResult === 'Too weak'):
            case (passStrengthResult === 'Weak'): {
                ctx.addIssue({
                    code: "custom",
                    message: "Weak password",
                    path: ["password"]
                })
                break
            }
        }

        if (password !== repeatPassword) {
            ctx.addIssue({
                code: "custom",
                message: "The passwords did not match",
                path: ["repeatPassword"]
            })
        }
    })

export default setUpPasswordSchema