import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    width: number
    height: number
    stroke?: string
    strokeWidth?: number
}

const ShevronDown = ({...props}: Props) => {
    return <svg {...props} width={props.width} height={props.height} viewBox={'3 6 18 10'} fill="none"

                stroke={'currentColor'} strokeWidth={props.strokeWidth ? props.strokeWidth : 3} strokeLinecap="round" strokeLinejoin="round">
        <path d="m6 9 6 6 6-6"/>
    </svg>
}

export {ShevronDown}