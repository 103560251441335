import {useEffect, useState} from "react";
import {Button} from "src/components/button";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import {DownLoadIcon} from "src/media/icons/custom/downloadIcon";
import {ShevronLeft} from "src/media/icons/custom/shevron/shevronLeft";
import {ShevronRight} from "src/media/icons/custom/shevron/shevronRight";
import {TrashBinIcon} from "src/media/icons/custom/trashBinIcon";
import {Colors} from "src/utils/defaults/colors";
import {BlobType} from "src/utils/enums/blobType";
import {OrderImageOwner} from "src/utils/enums/orderImageOwner";
import getExtensionType from "src/utils/stringHandlers/images/getExtensionType";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";

export default function DesktopContent() {
    const [blobType, setBlobType] = useState<BlobType | null>(null)

    const {
        currentOrderId,
        deleteCurrent,
        downloadImage,
        setNextImage,
        setPreviousImage,
        isNext,
        isPrevious,
        totalImages,
        currentPosition,
        closeImageViewer,
        currentImage
    } = useOrderAttachments()

    useEffect(() => {
        setBlobType(null)
        if (currentImage) {
            const extension = getExtensionType(currentImage)
            setBlobType(extension)
        }
    }, [currentImage]);

    if (currentImage) {
        return <>
            <div
                className={'flex-none h-17 border-b border-[#222222] flex justify-between place-items-center px-4 pt-2'}>
                <div className={'flex flex-col gap-1'}>
                    <span className={'text-[#DEDEDE] text-sm font-bold'}>{currentOrderId}</span>
                    <span
                        className={'text-[#9F9F9F] text-sm font-medium'}>Media {currentPosition} of {totalImages}</span>
                </div>

                <div className={'flex gap-2 place-items-center'}>
                    {currentImage.owner === OrderImageOwner.carrier && <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'} onClick={deleteCurrent}><TrashBinIcon size={24} className={'fill-marcoWhite'}/></Button>}
                    <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'} onClick={downloadImage}><DownLoadIcon size={24} className={'fill-marcoWhite'}/></Button>
                    <Button variant={'icon'} size={"iconFit"} className={'hover:bg-marcoDarkHover p-1'} onClick={closeImageViewer}><CrossIcon size={24} className={'fill-marcoWhite'}/></Button>
                </div>
            </div>

            <div className={'grow text-gray-50 flex'}>
                <div className={'grow flex items-center justify-between px-11'}>
                    <div onClick={setPreviousImage}
                         className={`cursor-pointer rounded-full p-2 hover:bg-white hover:bg-opacity-10 flex-none ${!isPrevious && 'invisible'}`}>
                        <ShevronLeft size={24} stroke={Colors.white}/>
                    </div>

                    <div className={'grow px-[2.625rem] h-[calc(100vh-100px)]'}>
                        {(blobType && blobType === BlobType.image) && <img
                            className={`border-none h-full w-full object-scale-down`}
                            src={currentImage.image.url}
                            alt={'img'}/>}

                        {(blobType && blobType === BlobType.video) && <video
                            controls
                            className={`border-none h-full w-full object-scale-down`}
                            src={currentImage.image.url}
                        />}

                        {(blobType && blobType === BlobType.pdf) && <iframe className={`border-none w-full h-full object-scale-down`} src={currentImage.image.url}/>}
                    </div>
                    <div onClick={setNextImage}
                         className={`cursor-pointer rounded-full p-2 hover:bg-white hover:bg-opacity-10 flex-none ${!isNext && 'invisible'}`}>
                        <ShevronRight size={24} stroke={Colors.white}/>
                    </div>

                </div>

                <div className={'border-l border-[#222222] w-[18.25rem] flex-none flex flex-col gap-2 p-5'}>
                    <div className={'flex flex-col gap-1'}>
                        <span className={'text-[#DEDEDE] text-sm font-semibold'}>{currentImage.image.fileId}</span>
                        <span className={'text-[#9F9F9F] text-xs font-medium'}>600 × 150px</span>
                    </div>

                    <p className={'text-[#9F9F9F] text-xs font-medium'}>
                        Write a brief description of the file for people with visual impairment or lowbandwidth connections.
                    </p>

                    <span className={'pt-1 text-[#C5D0FF] text-xs font-medium'}>Add alt text</span>
                </div>
            </div>
        </>
    } else return <></>
}