import {useNavigate} from "react-router-dom";
import routes from "src/utils/defaults/routes";

export default function UserAccountNavigationMenu() {
    const navigate = useNavigate()

    return <ul className={'flex flex-col gap-2 w-[18rem]'}>
        <li className={'text-base leading-6 cursor-pointer py-2'} onClick={() => navigate(routes.userPersonalDetails)}>Personal Details</li>
        <li className={'text-base leading-6 cursor-pointer py-2'} onClick={() => navigate(routes.userLoginAndSecurity)}>Login and security</li>
    </ul>
}