import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {CustomItem} from "src/utils/types/structures/customItem";
import {Item} from "src/utils/types/structures/item";
import {OrderItem} from "src/utils/types/structures/orderItem";

export default function getExtendedItemVolumeAndWeight(orderItem: OrderItem | CustomItem, items: Item[]) {
    if (isInstanceOf<OrderItem>(orderItem, 'itemId')) {
        const item = items.filter(el => el.id === orderItem.itemId)
        if (item[0]) {
            return {
                volume: (item[0].volume * orderItem.count).toFixed(0),
                weight: ((item[0].volume * orderItem.count) * 6).toFixed(0)
            }
        } else return {
            volume: 0,
            weight: 0
        }
    } else {
        if (orderItem.volume) {
            const volume = orderItem.volume * orderItem.count
            const weight = orderItem.weight ? (orderItem.weight * orderItem.count) : (volume * 6)

            return {
                volume: volume,
                weight: weight.toFixed(0)
            }
        } else return {
            volume: 0,
            weight: 0
        }
    }
}