import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import routes from "src/utils/defaults/routes";
import {CurrentTabStatus} from "src/utils/enums/CurrentTabStatus";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    currentActive: CurrentTabStatus | null
}

export default function EmptyResults({currentActive, ...props}: Props) {
    const navigate = useNavigate()
    const {user} = useTokenStore();

    return <div {...props}>
        {currentActive === CurrentTabStatus.booked
            ? <section className={'flex flex-col items-center'}>
                <h3 className={'text-[22px] font-semibold text-foreground leading-relaxed'}>No booked loads</h3>
                <p className={'pt-3 text-muted-foreground text-base font-normal leading-normal'}>There are no booked loads to display</p>
                {user?.roles !== 'DRIVER' && <Button onClick={() => navigate(routes.search)} variant={'outline'} size={'default'} className={'text-sm mt-6 font-medium leading-tight'}>Find more loads!</Button>}
            </section>
            : <section className={'flex flex-col items-center'}>
                <h3 className={'text-[22px] font-semibold text-foreground leading-relaxed'}>No active loads</h3>
                <p className={'pt-3 text-muted-foreground text-base font-normal leading-normal'}>There are no active loads to display</p>
            </section>
        }
    </div>
}