import {ApiHost} from "src/utils/defaults/apiHost";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {IData} from "src/utils/types/iData";
import {OrderSearch} from "src/utils/types/structures/orderSearch";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function getOrders(data: Object, page: number | null) {
    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/order/filter?size=${defaultFilterValues.limit}&page=${page}`), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${useTokenStore.getState().token}`
            }
        })

        if (res.ok) {
            const data: IData<OrderSearch> = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}