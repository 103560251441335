import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import formatEnumValueToString from "src/utils/stringHandlers/formatEnumValueToString";
import {Member} from "src/utils/types/structures/member";
import TableCellName from "./tablecomponents/tableCellName";
import TableDots from "./tablecomponents/tableDots";

interface Props {
    members: Member[]
    mutateMembers: () => void
}

export default function MembersTableMD({members, mutateMembers}: Props) {

    return <Table>
        <TableHeader>
            <TableRow>
                <TableHead className={'text-sm font-semibold sm:min-w-[14.5rem]'}>Name</TableHead>
                <TableHead className={'text-sm font-semibold sm:min-w-[4.5rem]'}>Role</TableHead>
                <TableHead className={'text-sm font-semibold'}></TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>

            {members.map(
                member =>
                    <TableRow
                        key={member.id}>
                        <TableCellName member={member}/>
                        <TableCell className={'font-normal text-sm'}>{formatEnumValueToString(member.role)}</TableCell>
                        <TableDots mutateMembers={() => mutateMembers()} member={member}/>
                    </TableRow>
            )}
        </TableBody>
    </Table>
}