export const pickup = {
    path: "M 0.189 20.813 C 0.714 21.441 2.032 21.404 2.791 20.803 C 3.508 20.037 3.337 18.841 2.725 18.206 C 2.014 17.632 0.927 17.616 0.213 18.277 C -0.372 18.907 -0.387 19.948 0.189 20.813 Z",
    fillColor: "#00442E",
    fillOpacity: 1,
    strokeWeight: 1,
    rotation: 0,
    scale: 2.5,
    anchor: new google.maps.Point(0, 18),
};

export const destination = {
    path: "M 0.001 21.001 L 3.001 21 L 3 18 L 0 18 Z",
    fillColor: "#00442E",
    fillOpacity: 1,
    strokeWeight: 1,
    rotation: 0,
    scale: 2.5,
    anchor: new google.maps.Point(0, 20),
};


export const mapId = '3999fcb295fa34f1'

export const API_KEY = 'AIzaSyC-BSwkr80ziYNRaqy_I3h9PhTo0XO6l7E'