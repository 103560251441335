import React from "react";
import OrderFulfillmentStatusBadge from "src/components/orderFulfillmentStatusBadge";
import {ScrollArea, ScrollBar} from "src/components/scrollArea";
import Tabs from "src/pages/dashboarSection/orderDetails/buttons/buttonLayout/tabs";
import MoreActionsHandler
    from "src/pages/dashboarSection/orderDetails/buttons/buttonMoreActionsHandle/moreActionsHandler";
import {OrderDetailsTabSections} from "src/pages/dashboarSection/orderDetails/orderDetailsLayout";
import {getLocalityAddress} from "src/utils/stringHandlers/orderDetailsStrings/getLocalityAddress";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import ButtonHandler from "../buttonStatusHandle/buttonHandler";

interface Props {
    order: OrderDetails
    currentActiveTab: OrderDetailsTabSections
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}
export default function MobileDetailsHeader({order, currentActiveTab, setOrder}: Props) {
    const {isScrolled} = useViewport();

    return <section className={`${!isScrolled && 'shadow-light'} flex-none px-padding sticky top-0 pt-8  md:shadow-none pb-6 bg-background z-30 flex flex-col gap-4`}>

        <div className={'flex justify-between items-center w-full'}>
            <section className={'flex gap-4 items-center'}>
                <p key={'order_number'} className={'text-2xl font-semibold leading-[32px]'}>#{order.sid}</p>
                <p key={'address'}
                   className={'hidden lg:block text-[1.375rem] font-semibold'}> {getLocalityAddress(order.originAddress) + ' to ' + getLocalityAddress(order.destinationAddress)}</p>
                <OrderFulfillmentStatusBadge order={order}/>
            </section>

            <MoreActionsHandler/>
        </div>

        <ButtonHandler currentActiveTab={currentActiveTab} order={order} setOrder={setOrder}/>

        <ScrollArea className={"w-[95vw] sm:w-full"}>
            <Tabs order={order} currentActiveTab={currentActiveTab}/>
            <ScrollBar orientation="horizontal" className={'hidden'}/>
        </ScrollArea>
    </section>
}