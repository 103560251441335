import useOverlay from "src/utils/zustandStores/useOverlay";

export default function Overlay() {
    const {isOpen, setOpen} = useOverlay()

    return <div
        onTouchMove={() => setOpen(false)}
        onClick={() => setOpen(false)}
        className={`${isOpen ? 'fixed' : 'hidden'} z-40 bg-primary bg-opacity-0 w-full h-full`}>
    </div>
}