import {produce} from "immer";
import moment from "moment";
import {DateRange} from "react-day-picker";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import {SortFilter} from "src/utils/enums/sortFilter";
import {create} from 'zustand'

type Body = {
    sortFilterValue: SortFilter

    originLat: number | null,
    originLng: number | null,

    destinationLat: number | null,
    destinationLng: number | null,

    originRadius: number | null,
    destinationRadius: number | null,

    date: DateRange | undefined

    startRate: number | null,
    endRate: number | null,
    startVolume: number | null,
    endVolume: number | null,
    startDistance: number | null,
    endDistance: number | null,
}

type FilterResponse = {
    body: Body
    request: Object | null
}

interface State {
    response: FilterResponse
}

interface Actions {
    setOriginRadius: (radius: number) => void
    setDestinationRadius: (radius: number) => void

    setDate: (date: DateRange | undefined) => void

    setStartRate: (rate: number) => void
    setEndRate: (rate: number) => void

    setStartVolume: (volume: number) => void
    setEndVolume: (volume: number) => void

    setStartDistance: (distance: number) => void
    setEndDistance: (distance: number) => void

    setOriginCoordinates: (originLat: number | null, originLng: number | null) => void
    setDestinationCoordinates: (destinationLat: number | null, destinationLng: number | null) => void

    getValuesFromURL: (searchParams: URLSearchParams, originLat: number | null, originLng: number | null, destinationLat: number | null, destinationLng: number | null) => Promise<{
        [p: string]: number | null | Date
    }>
    getURLFromValues: (searchParams: URLSearchParams, originAddress?: string, destinationAddress?: string) => Promise<URLSearchParams>

    setSortFilterValue: (value: SortFilter) => void
    resetFilter: () => void
}

const defaultResponse: FilterResponse = {
    request: null,
    body: {
        sortFilterValue: SortFilter.rate,

        originLat: null,
        originLng: null,

        destinationLat: null,
        destinationLng: null,

        originRadius: defaultFilterValues.originRadius,
        destinationRadius: defaultFilterValues.destinationRadius,

        date: undefined,

        startRate: defaultFilterValues.minRate,
        endRate: defaultFilterValues.maxRate,

        startVolume: defaultFilterValues.minVolume,
        endVolume: defaultFilterValues.maxVolume,

        startDistance: defaultFilterValues.minDistance,
        endDistance: defaultFilterValues.maxDistance,
    }
}


const useSearch = create<State & Actions>(
    (set, get) => ({
        response: defaultResponse,

        resetFilter: () => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body = {
                                    sortFilterValue: SortFilter.rate,

                                    originLat: null,
                                    originLng: null,

                                    destinationLat: null,
                                    destinationLng: null,

                                    originRadius: defaultFilterValues.originRadius,
                                    destinationRadius: defaultFilterValues.destinationRadius,

                                    date: undefined,

                                    startRate: defaultFilterValues.startRate,
                                    endRate: defaultFilterValues.endRate,

                                    startVolume: defaultFilterValues.startVolume,
                                    endVolume: defaultFilterValues.endVolume,

                                    startDistance: defaultFilterValues.startDistance,
                                    endDistance: defaultFilterValues.endDistance,
                                }
                            })
                    }
                )
            )
        },


        setSortFilterValue: (value) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.sortFilterValue = value
                            })
                    }
                )
            )
        },

        setOriginRadius: (radius) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.originRadius = radius
                            })
                    }
                )
            )
        },

        setDestinationRadius: (radius) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.destinationRadius = radius
                            })
                    }
                )
            )
        },

        setDate: (date: DateRange | undefined) => {
            set(
                (state) => (
                    {
                        response: produce(state.response, draft => {
                            draft.body.date = date

                            if (date === undefined) {
                                draft.body.date = undefined

                            } else {

                                switch (true) {
                                    case (date.from && date.to === undefined): {
                                        draft.body.date = {from: date.from, to: undefined}
                                        break
                                    }

                                    default: {
                                        draft.body.date = {from: date.from, to: date.to}
                                        break
                                    }
                                }
                            }
                        })
                    }
                )
            )
        },

        setStartRate: (rate) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.startRate = rate
                            })
                    }
                )
            )
        },

        setEndRate: (rate) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.endRate = rate
                            })
                    }
                )
            )
        },

        setStartVolume: (volume) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.startVolume = volume
                            })
                    }
                )
            )
        },

        setEndVolume: (volume) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.endVolume = volume
                            })
                    }
                )
            )
        },

        setStartDistance: (distance) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.startDistance = distance
                            })
                    }
                )
            )
        },

        setEndDistance: (distance) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.endDistance = distance
                            })
                    }
                )
            )
        },

        setOriginCoordinates: (originLat: number | null, originLng: number | null) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.originLat = originLat
                                draft.body.originLng = originLng
                            })
                    }
                )
            )
        },


        setDestinationCoordinates: (destinationLat: number | null, destinationLng: number | null) => {
            set(
                (state) => (
                    {
                        response:
                            produce(state.response, draft => {
                                draft.body.destinationLat = destinationLat
                                draft.body.destinationLng = destinationLng
                            })
                    }
                )
            )
        },

        getValuesFromURL: async (searchParams: URLSearchParams, originLat: number | null, originLng: number | null, destinationLat: number | null, destinationLng: number | null) => {
            set(
                (state) => (
                    {
                        response: defaultResponse
                    }
                )
            )

            const originRadius = searchParams.get('originRadius');
            const sort = searchParams.get('sort');
            const destinationRadius = searchParams.get('destinationRadius');
            const startDate = searchParams.get('startDate');
            const endDate = searchParams.get('endDate');
            const startRate = searchParams.get('startRate');
            const endRate = searchParams.get('endRate');
            const startVolume = searchParams.get('startVolume');
            const endVolume = searchParams.get('endVolume');
            const startDistance = searchParams.get('startDistance');
            const endDistance = searchParams.get('endDistance');

            if (originLat && originLng) {
                set(
                    (state) => (
                        {
                            response: produce(state.response, draft => {
                                draft.body.originLat = originLat
                                draft.body.originLng = originLng
                            })
                        }
                    )
                )
            }

            if (destinationLat && destinationLng) {
                set(
                    (state) => (
                        {
                            response: produce(state.response, draft => {
                                draft.body.destinationLat = destinationLat
                                draft.body.destinationLng = destinationLng
                            })
                        }
                    )
                )
            }

            if (sort) {
                switch (sort) {
                    case SortFilter.rate: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        searchParams.delete('sort');
                                        draft.body.sortFilterValue = SortFilter.rate
                                    })
                                }
                            )
                        )
                        break
                    }

                    case SortFilter.volume: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.sortFilterValue = SortFilter.volume
                                    })
                                }
                            )
                        )
                        break
                    }

                    case SortFilter.distance: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.sortFilterValue = SortFilter.distance
                                    })
                                }
                            )
                        )
                        break
                    }
                }
            } else {
                set(
                    (state) => (
                        {
                            response: produce(state.response, draft => {
                                draft.body.sortFilterValue = SortFilter.rate
                            })
                        }
                    )
                )
            }

            if (originRadius) {
                switch (true) {
                    case (Number(originRadius) === defaultFilterValues.originRadius):
                    case (isNaN(Number(originRadius))):
                    case (Number(originRadius) < defaultFilterValues.minOriginRadius):
                    case (Number(originRadius) > defaultFilterValues.maxOriginRadius): {

                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        searchParams.delete('originRadius');
                                        draft.body.originRadius = defaultFilterValues.originRadius
                                    })
                                }
                            )
                        )
                        break
                    }

                    default: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.originRadius = Number(originRadius)
                                    })
                                }
                            )
                        )
                    }
                }

            } else {
                set(
                    (state) => (
                        {
                            response: produce(state.response, draft => {
                                draft.body.originRadius = Number(defaultFilterValues.originRadius)
                            })
                        }
                    )
                )
            }

            if (destinationRadius) {
                switch (true) {
                    case (Number(destinationRadius) === defaultFilterValues.destinationRadius):
                    case (isNaN(Number(destinationRadius))):
                    case (Number(destinationRadius) < defaultFilterValues.minDestinationRadius):
                    case (Number(destinationRadius) > defaultFilterValues.maxDestinationRadius): {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        searchParams.delete('destinationRadius');
                                        draft.body.destinationRadius = defaultFilterValues.destinationRadius
                                    })
                                }
                            )
                        )
                        break
                    }

                    default: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.destinationRadius = Number(destinationRadius)
                                    })
                                }
                            )
                        )
                    }
                }
            } else {
                set(
                    (state) => (
                        {
                            response: produce(state.response, draft => {
                                draft.body.destinationRadius = Number(defaultFilterValues.destinationRadius)
                            })
                        }
                    )
                )
            }

            if (startDate) {
                if (endDate) {

                    set(
                        (state) => (
                            {
                                response: produce(state.response, draft => {
                                    draft.body.date = {from: moment(startDate, 'YYYY-MM-DD').toDate(), to: moment(endDate, 'YYYY-MM-DD').toDate()}
                                })
                            }
                        )
                    )
                } else {
                    set(
                        (state) => (
                            {
                                response: produce(state.response, draft => {
                                    draft.body.date = {from: moment(startDate, 'YYYY-MM-DD').toDate(), to: undefined}
                                })
                            }
                        )
                    )
                }

            } else {
                set(
                    (state) => (
                        {
                            response: produce(state.response, draft => {
                                draft.body.date = undefined
                            })
                        }
                    )
                )
            }

            if (endRate) {
                switch (true) {
                    case (Number(endRate) === defaultFilterValues.endRate):
                    case(isNaN(Number(endRate))):
                    case (Number(endRate) > defaultFilterValues.maxRate):
                    case (Number(endRate) < defaultFilterValues.minRate): {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.endRate = null;
                                    })
                                }
                            )
                        )
                        break
                    }

                    default: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.endRate = Number(endRate)
                                    })
                                }
                            )
                        )
                    }
                }
            }

            if (startRate) {
                switch (true) {
                    case (Number(startRate) === defaultFilterValues.startRate):
                    case(isNaN(Number(startRate))):
                    case (Number(startRate) > defaultFilterValues.maxRate):
                    case (Number(startRate) < defaultFilterValues.minRate): {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.startRate = null;
                                    })
                                }
                            )
                        )
                        break
                    }

                    default: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.startRate = Number(startRate)
                                    })
                                }
                            )
                        )
                    }
                }
            }


            if (startVolume) {
                switch (true) {
                    case (Number(startVolume) === defaultFilterValues.startVolume):
                    case(isNaN(Number(startVolume))):
                    case (Number(startVolume) > defaultFilterValues.maxVolume):
                    case (Number(startVolume) < defaultFilterValues.minVolume): {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.startVolume = null;
                                    })
                                }
                            )
                        )
                        break
                    }

                    default: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.startVolume = Number(startVolume)
                                    })
                                }
                            )
                        )
                    }
                }
            }

            if (endVolume) {
                switch (true) {
                    case (Number(endVolume) === defaultFilterValues.endVolume):
                    case(isNaN(Number(endVolume))):
                    case (Number(endVolume) > defaultFilterValues.maxVolume):
                    case (Number(endVolume) < defaultFilterValues.minVolume): {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.endVolume = null;
                                    })
                                }
                            )
                        )
                        break
                    }

                    default: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.endVolume = Number(endVolume)
                                    })
                                }
                            )
                        )
                    }
                }
            }

            if (startDistance) {
                switch (true) {
                    case (Number(startDistance) === defaultFilterValues.startDistance):
                    case(isNaN(Number(startDistance))):
                    case (Number(startDistance) > defaultFilterValues.maxDistance):
                    case (Number(startDistance) < defaultFilterValues.minDistance): {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.startDistance = null;
                                    })
                                }
                            )
                        )
                        break
                    }

                    default: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.startDistance = Number(startDistance)
                                    })
                                }
                            )
                        )
                    }
                }
            }

            if (endDistance) {
                switch (true) {
                    case (Number(endDistance) === defaultFilterValues.endDistance):
                    case(isNaN(Number(endDistance))):
                    case (Number(endDistance) > defaultFilterValues.maxDistance):
                    case (Number(endDistance) < defaultFilterValues.minDistance): {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.endDistance = null;
                                    })
                                }
                            )
                        )
                        break
                    }

                    default: {
                        set(
                            (state) => (
                                {
                                    response: produce(state.response, draft => {
                                        draft.body.endDistance = Number(endDistance)
                                    })
                                }
                            )
                        )
                    }
                }
            }

            const data = useSearch.getState().response.body

            const correctedData = produce(data, draft => {
                if (data.originRadius) {
                    draft.originRadius = data.originRadius * 1609
                }

                if (data.destinationRadius) {
                    draft.destinationRadius = data.destinationRadius * 1609
                }

                if (data.endVolume === defaultFilterValues.maxVolume) {
                    draft.endVolume = null
                }
                if (data.startVolume === defaultFilterValues.startVolume) {
                    draft.startVolume = null
                }

                if (data.startDistance === defaultFilterValues.minDistance) {
                    draft.startDistance = null
                }
                if (data.endDistance === defaultFilterValues.endDistance) {
                    draft.endDistance = null
                }

                if (data.startRate === defaultFilterValues.startRate) {
                    draft.startRate = null
                }
                if (data.endRate === defaultFilterValues.endRate) {
                    draft.endRate = null
                }
            })

            const postObj = {
                ...correctedData,
                startDate: null,
                endDate: null
            }

            delete postObj.date;

            if (correctedData.date) {
                if (correctedData.date.from) {
                    // @ts-ignore
                    postObj.startDate = moment(correctedData.date.from).format('YYYY-MM-DD')
                }

                if (correctedData.date.to) {
                    // @ts-ignore
                    postObj.endDate = moment(correctedData.date.to).format('YYYY-MM-DD')
                }
            }

            let result: any = Object.fromEntries(Object.entries(postObj).filter(([_, v]) => v != null));
            set(
                (state) => (
                    {
                        response: produce(state.response, draft => {
                            draft.request = result
                        })
                    }
                )
            )

            return result
        },

        getURLFromValues: async (searchParams: URLSearchParams, originAddress?: string, destinationAddress? : string) => {
            searchParams.delete('originRadius')
            searchParams.delete('destinationRadius')
            searchParams.delete('startDate')
            searchParams.delete('endDate')
            searchParams.delete('endRate')
            searchParams.delete('startRate')
            searchParams.delete('startVolume')
            searchParams.delete('endVolume')
            searchParams.delete('startDistance')
            searchParams.delete('endDistance')
            searchParams.delete('sort')
            searchParams.delete('originAddress')
            searchParams.delete('destinationAddress')

            const entries = useSearch.getState().response.body

            if (entries.originRadius !== null) {
                switch (true) {
                    case (entries.originRadius === defaultFilterValues.originRadius): {
                        break
                    }

                    default: {
                        searchParams.set('originRadius', (entries.originRadius.toString()))
                    }
                }
            }

            if (entries.destinationRadius !== null) {
                switch (true) {
                    case (entries.destinationRadius === defaultFilterValues.destinationRadius): {
                        break
                    }

                    default: {
                        searchParams.set('destinationRadius', entries.destinationRadius.toString())
                    }
                }
            }

            if (entries.date) {

                if (entries.date.from) {
                    searchParams.set('startDate', moment(entries.date?.from).format('YYYY-MM-DD'))
                }

                if (entries.date.to) {
                    searchParams.set('endDate', moment(entries.date?.to).format('YYYY-MM-DD'))
                }

            }

            if (entries.endRate !== null) {
                switch (true) {
                    case (entries.endRate === defaultFilterValues.endRate): {
                        break
                    }

                    default: {
                        searchParams.set('endRate', entries.endRate.toString())
                    }
                }
            }

            if (entries.startRate !== null) {
                switch (true) {
                    case (entries.startRate === defaultFilterValues.startRate): {
                        break
                    }

                    default: {
                        searchParams.set('startRate', entries.startRate.toString())
                    }
                }
            }

            if (entries.startVolume !== null) {
                switch (true) {
                    case (entries.startVolume === defaultFilterValues.startVolume): {
                        break
                    }

                    default: {
                        searchParams.set('startVolume', entries.startVolume.toString())
                    }
                }
            }

            if (entries.endVolume !== null) {
                switch (true) {
                    case (entries.endVolume === defaultFilterValues.endVolume): {
                        break
                    }

                    default: {
                        searchParams.set('endVolume', entries.endVolume.toString())
                    }
                }
            }

            if (entries.startDistance !== null) {
                switch (true) {
                    case (entries.startDistance === defaultFilterValues.startDistance): {
                        break
                    }

                    default: {
                        searchParams.set('startDistance', entries.startDistance.toString())
                    }
                }
            }

            if (entries.endDistance !== null) {
                switch (true) {
                    case (entries.endDistance === defaultFilterValues.endDistance): {
                        break
                    }

                    default: {
                        searchParams.set('endDistance', entries.endDistance.toString())
                    }
                }
            }

            if (entries.sortFilterValue) {
                switch (entries.sortFilterValue) {

                    case SortFilter.distance: {
                        searchParams.set('sort', SortFilter.distance)
                        break
                    }

                    case SortFilter.volume: {
                        searchParams.set('sort', SortFilter.volume)
                        break
                    }
                }
            }

            if (originAddress) {
                searchParams.set('originAddress', originAddress)
            }

            if (destinationAddress) {
                searchParams.set('destinationAddress', destinationAddress)
            }

            return searchParams
        },
    })
)

export default useSearch;
