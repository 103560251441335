import React from "react";
import {Separator} from "src/components/separator";
import CustomerInfoSection
    from "src/pages/dashboarSection/orderDetails/sections/details/components/customerInfoSection";
import DetailsSection from "src/pages/dashboarSection/orderDetails/sections/details/components/detailsSection";
import DriverInfoSection from "src/pages/dashboarSection/orderDetails/sections/details/components/driverInfoSection";
import MapSection from "src/pages/dashboarSection/orderDetails/sections/details/components/mapSection";
import StopsSection from "src/pages/dashboarSection/orderDetails/sections/details/components/stopsSection";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails | undefined
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function DetailsContentLayout({order, setOrder, ...props}: Props) {
    const {width} = useViewport()

    if (!order) {
        return <></>
    }

    return <div {...props}>
        {
            width < defaultDimensions.md
                ? <div className={'flex flex-col gap-6'}>
                    <MapSection order={order}/>
                    <CustomerInfoSection order={order}/>
                    <Separator/>
                    <StopsSection order={order}/>
                    <Separator/>
                    <DriverInfoSection order={order} setOrder={setOrder}/>
                    <Separator/>
                    <DetailsSection order={order}/>
                </div>

                : <div className={'flex gap-12'}>
                    <section key={'left'} className={`grow flex flex-col gap-6`}>
                        <MapSection order={order}/>
                        <StopsSection order={order}/>
                    </section>

                    <section key={'right'} className={`w-1/3 flex flex-col gap-6`}>
                        <CustomerInfoSection order={order}/>
                        <Separator/>
                        <DriverInfoSection order={order} setOrder={setOrder}/>
                        <Separator/>
                        <DetailsSection order={order}/>
                    </section>
                </div>
        }
    </div>
}