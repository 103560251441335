export const defaultFilterValues = {
    // current default values

    originLat: null,
    originLng: null,

    destinationLat: null,
    destinationLng: null,

    originCoordinates: null,
    destinationCoordinates: null,
    pickupAddress: '',
    destinationAddress: '',


    offset: 0,
    limit: 30,
    startRate: 0,
    endRate: 10000,
    startDistance: 0,
    endDistance: 5000,
    startVolume: 0,
    endVolume: 5000,
    originRadius: 150,
    destinationRadius: 150,
    endDate: undefined,
    startDate: undefined,



    // filters
    minVolume: 0,
    maxVolume: 5000,
    volumeStep: 100,

    minDistance: 0,
    maxDistance: 5000,
    distanceStep: 50,

    minRate: 0,
    maxRate: 10000,
    rateStep: 100,

    minOriginRadius: 50,
    maxOriginRadius: 250,
    pickupRadiusStep: 50,

    minDestinationRadius: 50,
    maxDestinationRadius: 250,
    deliveryRadiusStep: 50,
}