import {create} from 'zustand'

export enum ScrollPos {
    upDeadZone = 'UPPER_DEADZONE',
    down = 'UP',
    up = 'DOWN'
}

interface State {
    width: number
    height: number
    currentScrollY: number
    isScrolled: boolean
    scrollPos: ScrollPos
    deadZone: number
}

interface Actions {
    setDimensions: () => void
    setScrollHandler: () => void
    setScrollPosition: (pos: ScrollPos) => void
}

const useViewport = create<State & Actions>(
    (set, get) => ({
        width: window.innerWidth,
        height: window.innerHeight,
        isScrolled: true,
        scrollPos: ScrollPos.upDeadZone,
        currentScrollY: window.scrollY,
        deadZone: 35,

        setScrollPosition: (pos: ScrollPos) => {
            set(
                (state) => (
                    {
                        scrollPos: pos
                    }
                )
            )
        },

        setDimensions: () => {
            set(
                (state) => (
                    {
                        width: window.innerWidth,
                        height: window.innerHeight
                    }
                )
            )
        },

        setScrollHandler: () => {
            const currentScrollY = useViewport.getState().currentScrollY
            const deadZone = useViewport.getState().deadZone

            let pos: ScrollPos = ScrollPos.upDeadZone

            switch (true) {
                case (currentScrollY < deadZone): {
                    pos = ScrollPos.upDeadZone
                    break
                }

                case (currentScrollY > window.scrollY): {
                    pos = ScrollPos.up
                    break
                }

                case (currentScrollY < window.scrollY): {
                    pos = ScrollPos.down
                    break
                }


            }

            set(
                (state) => (
                    {
                        isScrolled: window.scrollY <= 20,
                        currentScrollY: window.scrollY,
                        scrollPos: pos
                    }
                )
            )
        },
    })
)

export default useViewport;
