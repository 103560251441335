import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import * as RPNInput from "react-phone-number-input";
import {useNavigate} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {PhoneInput} from "src/components/phoneInput";
import {Separator} from "src/components/separator";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {AccountUserCredentials, editAccountUserCredentials} from "src/utils/apiCalls/editAccoountUserCredentials";
import fetcher from "src/utils/apiCalls/fetcher";
import {ApiHost} from "src/utils/defaults/apiHost";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {Member} from "src/utils/types/structures/member";
import personalDetails from "src/utils/zodSchemas/personalDetails";
import useUserHeader from "src/utils/zustandStores/userHeader/useUserHeader";
import useSWR from "swr";
import * as z from "zod";

export enum CompanDetailsSection {
    name = 'NAME',
    emailAddress = 'EMAIL_ADDRESS',
    phoneNumber = 'PHONE_NUMBER',
    avatar = 'AVATAR'
}

type IsDisabled = {
    name: boolean,
    emailAddress : boolean,
    phoneNumber: boolean,
    avatar: boolean
    currentSection: CompanDetailsSection | null
}

const defaultIsDisabled: IsDisabled = {
    name: false,
    emailAddress : false,
    phoneNumber: false,
    avatar: false,
    currentSection: null
}

export default function PersonalDetails() {
    const [isDisabled, setIsDisabled] = useState<IsDisabled>(defaultIsDisabled);
    const [isLoading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const {setUser} = useUserHeader()
    const {open} = useDialog();

    const form = useForm<z.infer<typeof personalDetails>>({
        resolver: zodResolver(personalDetails),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
        }
    });

    const {data: user, error, mutate: mutateUser} = useSWR<Member>(ApiHost + encodeURI(`api/v1/auth/profile`), fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate(routes.login))
        }
    }, [error]);

    useEffect(() => {
        if (user) {
            form.setValue('firstName', user.firstName)
            form.setValue('lastName', user.lastName)
            form.setValue('email', user.email)
            form.setValue('phoneNumber', user.phone)
        }
    }, [user]);

    async function onSubmit(values: z.infer<typeof personalDetails>) {
        if (user) {
            setLoading(true)
            const updatedMember: AccountUserCredentials = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phoneNumber,
            }

            const res = await editAccountUserCredentials(updatedMember)
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate(routes.login))
            } else {
                await mutateUser()
                setUser(res)
                setIsDisabled({
                    name: false,
                    emailAddress : false,
                    phoneNumber: false,
                    avatar: false,
                    currentSection: null
                })
                window.scrollTo(0, 0)
            }
            setLoading(false)
        }
    }

    if (user) {
        return <div className={`w-full`}>
            <h1 className={` text-foreground font-medium text-2xl leading-[1.875rem]`}>Personal details</h1>

            <p className={'pt-4 leading-6'}>Your Marco profile will be used in all communication channels on the Marco platform.</p>

            <div key={'avatar'} className={'pt-4 w-fit flex flex-col items-center relative'}>
                <Avatar className={'w-[120px] h-[120px]'}>
                    <AvatarImage src={(user.avatar && user.avatar !== '') ? `${user.avatar}` : undefined}/>
                    <AvatarFallback>{getFirstLetters(user.firstName, user.lastName)}</AvatarFallback>
                </Avatar>

                <div className={'absolute bottom-[-10px] '}>
                    <Button
                        type={'button'}
                        disabled={isDisabled.avatar}
                        onClick={() => {
                            window.scrollTo(0, 0)
                            open(DialogElementType.personalSettingsShowAvatar, user, 'Edit profile picture', () => mutateUser())
                        }} variant={'ghost'} size={'sm'} className={`bg-white rounded-[1.875rem] shadow-light text-sm font-medium ${isDisabled.avatar ? 'text-[#dddddd]' : 'text-foreground'}`}>Edit</Button>
                </div>
            </div>

            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className={'flex flex-col pt-6 gap-6'}>

                        <section key={'name'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center'}>
                                <h2 className={`${isDisabled.name ? 'text-[#dddddd]' : 'text-foreground'} font-medium leading-6`}>Name</h2>
                                <Button
                                    disabled={isDisabled.name}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.name) {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress : false,
                                                phoneNumber: false,
                                                avatar: false,
                                                currentSection: null
                                            })
                                            form.setValue('firstName', user.firstName)
                                            form.setValue('lastName', user?.lastName)
                                            window.scrollTo(0, 0)
                                        } else {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress : true,
                                                phoneNumber: true,
                                                avatar: true,
                                                currentSection: CompanDetailsSection.name
                                            })
                                        }
                                    }} type={'button'} variant={'textGhost'} size={'textForm'} className={`font-semibold ${isDisabled.name ? 'text-[#dddddd]' : 'text-foreground'}`}>{isDisabled.currentSection === CompanDetailsSection.name ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${(isDisabled.currentSection === CompanDetailsSection.name) && 'hidden'} ${isDisabled.name ? 'text-[#dddddd]' : 'text-foreground'} text-sm`}>
                                {getString([{element: user.firstName,tag: ElementTag.name}, {element: user.lastName,tag: ElementTag.name},])}
                            </p>
                            <p className={`${isDisabled.name ? 'text-[#dddddd]' : 'text-muted-foreground'} text-sm pt-1`}>The designated company contact person for communication with customers or the Marco platform is either the company owner or a legal representative.</p>

                            {(isDisabled.currentSection === CompanDetailsSection.name) && <div className={'pt-6 flex flex-col gap-6'}>
                                <div className={"flex gap-4"}>
                                    <FormField
                                        control={form.control}
                                        name="firstName"
                                        render={({field}) => (
                                            <FormItem className={"flex-1"}>

                                                <div className={'grid grid-cols-2'}>
                                                    <FormLabel className={'text-sm leading-3'}>First name</FormLabel>
                                                    {form.getFieldState('firstName').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                </div>

                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        className={`${form.getFieldState('firstName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                        placeholder={"First name"}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="lastName"
                                        render={({field}) => (
                                            <FormItem className={"flex-1"}>

                                                <div className={'grid grid-cols-2'}>
                                                    <FormLabel className={'text-sm leading-3'}>Last name</FormLabel>
                                                    {form.getFieldState('lastName').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                                </div>

                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        placeholder={"Last name"}
                                                        className={`${form.getFieldState('lastName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <Button type={'submit'} size={'lg'} className={'w-fit'}>{isLoading ? <LoadingStatus/> : 'Save'}</Button>
                            </div>}
                        </section>

                        <Separator/>

                        <section key={'email-address'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center'}>
                                <h2 className={`${isDisabled.emailAddress ? 'text-[#dddddd]' : 'text-foreground'} font-medium leading-6`}>Email address</h2>
                                <Button
                                    disabled={isDisabled.emailAddress}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.emailAddress) {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress : false,
                                                phoneNumber: false,
                                                avatar: false,
                                                currentSection: null
                                            })
                                            form.setValue('email', user?.email)
                                            window.scrollTo(0, 0)
                                        } else {
                                            setIsDisabled({
                                                name: true,
                                                emailAddress : false,
                                                phoneNumber: true,
                                                avatar: true,
                                                currentSection: CompanDetailsSection.emailAddress
                                            })
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'} className={`font-semibold ${isDisabled.emailAddress ? 'text-[#dddddd]' : 'text-foreground'}`}>{isDisabled.currentSection === CompanDetailsSection.emailAddress ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${(isDisabled.currentSection === CompanDetailsSection.emailAddress) && 'hidden'} ${isDisabled.emailAddress ? 'text-[#dddddd]' : 'text-foreground'} text-sm`}>{user.email}</p>
                            <p className={`text-sm pt-1 ${isDisabled.emailAddress ? 'text-[#dddddd]' : 'text-muted-foreground'}`}>The primary email address for communication with confirmed customers or the Marco platform.</p>

                            {(isDisabled.currentSection === CompanDetailsSection.emailAddress) && <div className={'pt-6 flex flex-col gap-6'}>
                                <FormField
                                    control={form.control}
                                    name="email"
                                    render={({field}) => (
                                        <FormItem className={"flex-1"}>

                                            <div className={'grid grid-cols-2'}>
                                                <FormLabel className={'text-sm leading-3'}>Email</FormLabel>
                                                {form.getFieldState('email').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                            </div>

                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    placeholder={"Email"}
                                                    className={`${form.getFieldState('email').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                                <Button type={'submit'} size={'lg'} className={'w-fit'}>{isLoading ? <LoadingStatus/> : 'Save'}</Button>
                            </div>}
                        </section>

                        <Separator/>

                        <section key={'phone-number'} className={'flex flex-col'}>
                            <div className={'flex justify-between items-center'}>
                                <h2 className={`${isDisabled.phoneNumber ? 'text-[#dddddd]' : 'text-foreground'} font-medium leading-6`}>Phone number</h2>
                                <Button
                                    disabled={isDisabled.phoneNumber}
                                    onClick={() => {
                                        if (isDisabled.currentSection === CompanDetailsSection.phoneNumber) {
                                            setIsDisabled({
                                                name: false,
                                                emailAddress : false,
                                                phoneNumber: false,
                                                avatar: false,
                                                currentSection: null
                                            })
                                            form.setValue('phoneNumber', user?.phone)
                                            window.scrollTo(0, 0)
                                        } else {
                                            setIsDisabled({
                                                name: true,
                                                emailAddress : true,
                                                phoneNumber: false,
                                                avatar: true,
                                                currentSection: CompanDetailsSection.phoneNumber
                                            })
                                        }
                                    }} type={'button'} size={'textForm'} variant={'textGhost'} className={`${isDisabled.phoneNumber ? 'text-[#dddddd]' : 'text-foreground'} font-semibold`}>{isDisabled.currentSection === CompanDetailsSection.phoneNumber ? 'Cancel' : 'Edit'}</Button>
                            </div>

                            <p className={`${isDisabled.currentSection === CompanDetailsSection.phoneNumber && 'hidden'} ${isDisabled.phoneNumber ? 'text-[#dddddd]' : 'text-foreground'} text-sm`}>{RPNInput.formatPhoneNumber(user.phone)}</p>
                            <p className={`text-sm ${isDisabled.phoneNumber ? 'text-[#dddddd]' : 'text-muted-foreground'} pt-1`}>Contact number for confirmed customers and Marco to get in touch. Additional numbers can be added and their usage specified.</p>

                            {isDisabled.currentSection === CompanDetailsSection.phoneNumber && <div className={'pt-6 flex flex-col gap-6'}>
                                <FormField
                                    control={form.control}
                                    name="phoneNumber"
                                    render={({field}) => (
                                        <FormItem className={"flex-1"}>

                                            <div className={'grid grid-cols-2'}>
                                                <FormLabel className={'text-sm leading-3'}>Phone number</FormLabel>
                                                {form.getFieldState('phoneNumber').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                            </div>

                                            <FormControl>
                                                <PhoneInput
                                                    {...field}
                                                    isvalid={form.getFieldState('phoneNumber').invalid.toString()}
                                                    placeholder={'Phone'}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />

                                <Button type={'submit'} size={'lg'} className={'w-fit'}>{isLoading ? <LoadingStatus/> : 'Save'}</Button>
                            </div>}
                        </section>
                    </div>
                </form>
            </Form>
        </div>
    } else return null
}