import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

const fetcher = async (url: string) => {
    const token = useTokenStore.getState().token;


        const res: Response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json"
            }
        })

    if (res.ok) {
        return res.json()
    } else {
        const definedError = await getDefinedError(res)
        throw definedError
    }

}
export default fetcher