import React, {useEffect, useState} from "react";
import AttachmentsButtonSet
    from "src/pages/dashboarSection/orderDetails/buttons/buttonStatusHandle/buttonSets/attachmentsButtonSet";
import DetailsButtonSet
    from "src/pages/dashboarSection/orderDetails/buttons/buttonStatusHandle/buttonSets/detailsButtonSet";
import InventoryButtonSet
    from "src/pages/dashboarSection/orderDetails/buttons/buttonStatusHandle/buttonSets/inventoryButtonSet";
import {OrderDetailsTabSections} from "src/pages/dashboarSection/orderDetails/orderDetailsLayout";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
    currentActiveTab: OrderDetailsTabSections
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function ButtonHandler({order, currentActiveTab, setOrder}: Props) {
    const [button, setButton] = useState<React.ReactNode | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        switch (currentActiveTab) {
            case OrderDetailsTabSections.details: {
                setButton(<DetailsButtonSet isLoading={isLoading} setLoading={setLoading} order={order} setOrder={setOrder}/>)
                break
            }

            case OrderDetailsTabSections.inventory: {
                setButton(<InventoryButtonSet isLoading={isLoading} setLoading={setLoading} order={order} setOrder={setOrder}/>)
                break
            }

            case OrderDetailsTabSections.attachments: {
                setButton(<AttachmentsButtonSet isLoading={isLoading} setLoading={setLoading} order={order} setOrder={setOrder}/>)
                break
            }
        }
    }, [currentActiveTab, order, isLoading]);


    return <>{button}</>
}