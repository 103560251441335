import {cva, type VariantProps} from "class-variance-authority"
import * as React from "react"

import {cn} from "../utils/cn/cnHelper";

const badgeVariants = cva(
    "items-center rounded-2xl border h-3 px-2 py-[0.375rem] text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    {
        variants: {
            variant: {
                default:
                    "border-transparent bg-[#F5F5F5] text-[#222222]",

                completed:
                    "border-transparent bg-[#AEE9D1] text-overlay text-sm",

                draft:
                    "border-transparent bg-[#E4E5E7] text-overlay text-sm",

                quote:
                    "border-transparent bg-[#E4E5E7] text-overlay text-sm",

                cancelled:
                    "border-transparent bg-[#BE2F22] text-overlay text-sm",

                new:
                    "border-transparent bg-[#FFD79D] text-overlay text-sm",

                approved:
                    "border-transparent bg-[#FFD79D] text-overlay text-sm",

                in_progress:
                    "border-transparent bg-[#FFD79D] text-overlay text-sm",














                accepted:
                    "border-transparent bg-[#A4E8F2] text-overlay text-sm",

                dispatched:
                    "border-transparent bg-[#AEE9D1] text-overlay text-sm",

                arrivedAtPickup:
                    "border-transparent bg-[#AEE9D1] text-overlay text-sm",

                waitingSignature:
                    "border-transparent bg-[#FFEA8A] text-overlay text-sm",

                pickUpStarted:
                    "border-transparent bg-[#AEE9D1] text-overlay text-sm",





                secondary:
                    "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
                destructive:
                    "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
                outline: "text-foreground",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
)

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {
}

function Badge({className, variant, ...props}: BadgeProps) {
    return (
        <div className={cn(badgeVariants({variant}), className)} {...props} />
    )
}

export {Badge, badgeVariants}
