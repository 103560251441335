import {useNavigate} from "react-router-dom";
import {ShevronLeft} from "src/media/icons/custom/shevron/shevronLeft";
import CompanyAccountNavigationMenu
    from "src/pages/dashboarSection/accountSettings/companyAccount/companyAccountNavigationMenu";
import {Colors} from "src/utils/defaults/colors";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props {
    children: React.ReactNode,
}

export default function CompanyAccountLayout({children}: Props) {
    const navigate = useNavigate()
    const {width} = useViewport();

    return <div className={`px-padding ${width < defaultDimensions.md ? 'pt-6' : 'pt-8'} flex flex-col pb-6`}>

        {width >= defaultDimensions.md && <h1 className={'text-2xl font-semibold leading-[1.875rem]'}>Company settings</h1>}

        <div className={'flex md:pt-8 gap-[9%]'}>
            {width >= defaultDimensions.md && <CompanyAccountNavigationMenu/>}
            <div className={'w-full flex flex-col gap-6 md:max-w-[35.5rem]'}>

                {width < defaultDimensions.md && <div onClick={() => navigate(routes.mobileAccountSettings)} className={'cursor-pointer flex gap-2 w-fit items-center'}>
                    <ShevronLeft stroke={Colors.foreground} size={20} className={'w-fit h-fit'}/>
                    <h3 className={'font-medium text-base leading-6'}>Back</h3>
                </div>}

                {children}
            </div>
        </div>
    </div>
}