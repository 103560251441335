import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import InventoryTableDots
    from "src/pages/dashboarSection/orderDetails/sections/inventory/components/inventoryTableDots";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import getSimpleString from "src/utils/stringHandlers/getSimpleString";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import getExtendedItemDescription from "src/utils/zustandStores/orderDetailsInventory/utils/getExtendedItemDescription";
import getExtendedItemVolumeAndWeight
    from "src/utils/zustandStores/orderDetailsInventory/utils/getExtendedItemVolumeAndWeight";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}
export default function InvetoryTableLongRow({setOrder, order}: Props) {
    const {previousExtendedItems, items} = useOrderInventory();
    const {width} = useViewport()

    return <Table>
        <TableHeader>
            <TableRow>
                <TableHead>Item name</TableHead>
                <TableHead>Qty</TableHead>
                <TableHead className={'hidden sm:grid items-center'}>Notes</TableHead>
                <TableHead>Volume</TableHead>
                {width >= defaultDimensions.sm && <TableHead>Weight</TableHead>}
                <TableHead className={'w-12'}></TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {previousExtendedItems.map(el => <TableRow key={el.name}>
                <TableCell>{el.name}</TableCell>
                <TableCell>{getSimpleString(el.item.count)}</TableCell>
                <TableCell className={'hidden sm:block break-all text-sm text-foreground'}>
                    <div className={'break-all text-sm text-foreground h-full flex items-center'}>
                    {getExtendedItemDescription(el.item, items)}
                </div></TableCell>
                <TableCell>{getExtendedItemVolumeAndWeight(el.item, items).volume} cu.ft</TableCell>
                {width >= defaultDimensions.sm && <TableCell>{getExtendedItemVolumeAndWeight(el.item, items).weight} lbs</TableCell>}
                <InventoryTableDots/>
            </TableRow>)}
        </TableBody>
    </Table>
}