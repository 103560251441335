import React from "react";
import {TabsTrigger} from "src/components/tabs";
import {NotesSection} from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/notes/notes";

interface Props {
    currentActive: NotesSection
    setCurrentActive: React.Dispatch<React.SetStateAction<NotesSection>>
    title: string
    value: NotesSection
}

export default function NotesTrigger({currentActive, setCurrentActive, title, value}: Props) {
    return <TabsTrigger value={value}
                        className={`
                             ${currentActive === value && 'bg-[#F7F7F7] outline outline-[0.175rem] outline-offset-[-2px] outline-foreground'} h-10`}
                        onClick={(e) => {
                            if (currentActive !== value) {
                                setCurrentActive(value);
                            }
                        }}>{title}</TabsTrigger>
}