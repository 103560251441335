import MembersTable from "src/pages/dashboarSection/fleetManagement/table/membersTable";
import MembersTableMD from "src/pages/dashboarSection/fleetManagement/table/MembersTableMD";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import {Member} from "src/utils/types/structures/member";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props {
    members: Member[]
    mutateMembers: () => void
}

export default function MembersGrid({members, mutateMembers}:Props) {
    const {width} = useViewport();

    return <>
        {width < defaultDimensions.md && <MembersTableMD mutateMembers={() => mutateMembers()} members={members}/>}
        {width >= defaultDimensions.md && <MembersTable mutateMembers={() => mutateMembers()} members={members}/>}
        </>
}