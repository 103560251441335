import {zodResolver} from "@hookform/resolvers/zod";
import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import {PhoneInput} from "src/components/phoneInput";
import {useToast} from "src/components/useToast";
import {RegistrationForm, sendApplication} from "src/utils/apiCalls/sendApplication";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import registrationSchema from "src/utils/zodSchemas/registrationSchema";
import * as z from "zod";

export default function Register() {
    const navigate = useNavigate();
    const {toast} = useToast();

    const form = useForm<z.infer<typeof registrationSchema>>({
        resolver: zodResolver(registrationSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            companyName: "",
            email: "",
            phoneNumber: "",
            DOT: "",
            MC: "",
        }
    });

    async function onSubmit(values: z.infer<typeof registrationSchema>) {

        const appData: RegistrationForm = {
            ...values
        }

        const data = await sendApplication(appData)
        if (isInstanceOf<ErrorMessage>(data, 'message')) {
            handleResponseError(data, () => navigate(routes.login))
        } else {
            toast({
                description: 'Application sent'
            })
            navigate(routes.thankYouScreen)
        }
    }

    return <div className={"flex flex-col gap-6"}>
        <h1 className={"text-foreground text-[2rem] font-medium leading-tight"}>Lets get your application started!</h1>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>

                <div className={"flex flex-col gap-4"}>

                    <div className={"flex gap-4 items-end"}>
                        <FormField
                            control={form.control}
                            name="firstName"
                            render={({field}) => (
                                <FormItem className={"flex-1"}>
                                    {form.getFieldState('firstName').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            className={`${form.getFieldState('firstName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                            placeholder={"First name"}
                                            />
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="lastName"
                            render={({field}) => (
                                <FormItem className={"flex-1"}>
                                    {form.getFieldState('lastName').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            placeholder={"Last name"}
                                            className={`${form.getFieldState('lastName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <FormField
                        control={form.control}
                        name="companyName"
                        render={({field}) => (
                            <FormItem>
                                {form.getFieldState('companyName').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                <FormControl>
                                    <Input
                                        {...field}
                                        autoComplete={'off'}
                                        placeholder={"Company name"}
                                        className={`${form.getFieldState('companyName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="email"
                        render={({field}) => (
                            <FormItem>
                                {form.getFieldState('email').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                <FormControl>
                                    <Input
                                        {...field}
                                        autoComplete={'off'}
                                        placeholder={"Email address"}
                                        className={`${form.getFieldState('email').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full`}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="phoneNumber"
                        render={({field}) => (
                            <FormItem>

                                {form.getFieldState('phoneNumber').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium text-end'}/>}

                                <FormControl>
                                    <PhoneInput
                                        {...field}
                                        autoComplete={'off'}
                                        className={'text-sm'}
                                        isvalid={form.getFieldState('phoneNumber').invalid.toString()}
                                        placeholder={'Phone'}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <div className={"flex gap-4 items-end"}>
                        <FormField
                            control={form.control}
                            name="DOT"
                            render={({field}) => (
                                <FormItem className={"flex-1"}>
                                    {form.getFieldState('DOT').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            placeholder={"DOT#"}
                                            className={`${form.getFieldState('DOT').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="MC"
                            render={({field}) => (
                                <FormItem className={"flex-1"}>
                                    {form.getFieldState('MC').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                    <FormControl>
                                        <Input
                                            {...field}
                                            autoComplete={'off'}
                                            placeholder={"MC #"}
                                            className={`${form.getFieldState('MC').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <Button className={"w-full"}
                            type={"submit"}
                            size={'lg'}
                            variant={"green"}>
                        Start application
                    </Button>

                    <div>
                        <span className={'text-sm font-normal'}>Already registered?</span>
                        <span
                            className={"ml-1 text-marcoDarkGreen text-sm font-normal hover:underline cursor-pointer"}
                            onClick={() => navigate(routes.main)}>
                            Sign In
                        </span>
                    </div>
                </div>
            </form>
        </Form>
    </div>
}