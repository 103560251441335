import * as React from "react";
import {Button} from "src/components/button";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import {ShevronDown} from "src/media/icons/custom/shevron/shevronDown";
import DistancePopOverContent from "src/pages/dashboarSection/search/searchLayout/filters/distancePopOverContent";
import {Colors} from "src/utils/defaults/colors";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import useSearch from "src/utils/zustandStores/useSearch";


export default function DistancePopOver() {
    const {response} = useSearch();

    return <Popover>
        <PopoverTrigger asChild>
            <Button
                id="date"
                variant={"rounded"}
                size={"tab"}
                className={`
                        ${response.body.startDistance !== defaultFilterValues.startDistance && 'outline outline-foreground outline-2 outline-offset-[-2px]'} 
                        ${response.body.endDistance !== defaultFilterValues.endDistance && 'outline outline-foreground outline-2 outline-offset-[-2px]'}
                        rounded-full flex gap-2`}
            >
                <span className={'text-foreground text-sm font-medium leading-tight'}>Distance</span>
                <ShevronDown width={10} height={6} stroke={Colors.foreground}/>
            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[22.5rem] p-0 rounded-md" align="start" sticky={'always'}>
            <DistancePopOverContent/>
        </PopoverContent>
    </Popover>

}