import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {Member} from "src/utils/types/structures/member";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";


export async function getAccountUserCredentials() {
    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/auth/profile`), {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${useTokenStore.getState().token}`
            }
        })

        if (res.ok) {
            const data: Member = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}