import * as z from "zod";

const addressSchema = z.object({
    direction: z.string(),
    address: z.string().min(2, {message: "Required"}),
    state: z.string().optional(),
    access: z.string().min(2, {message: "Required"}),
    locality: z.string().optional(),
    zipCode: z.string().optional(),

    floorNumber: z.string(),
    numberStories: z.string().nullable(),
    propertyType: z.string().nullable(),
    squareFootage: z.string().nullable(),
    unitNumber: z.string(),
    unitSize: z.string().nullable(),
    propertySize: z.string().nullable(),
})

export default addressSchema