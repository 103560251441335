export enum OrderFulfillmentStatus {
    notAccepted = 'NOT_ACCEPTED', // => api/v1/order/accept =>
    accepted = 'ACCEPTED', // => api/v1/order/dispatched =>
    dispatched = 'DISPATCHED', // => api/v1/order/arrived =>
    arrivedAtPickup = 'ARRIVED_AT_PICKUP', // => api/v1/order/pickup =>
    atPickup = 'AT_PICKUP', // => api/v1/order/contract =>
    waitingSignature = 'WAITING_SIGNATURE',
    awaitingPayment = 'WAITING_PAYMENT',

    pickupStarted = 'PICKUP_STARTED', // => api/v1/order/completePickup =>
    completePickup = 'COMPLETE_PICKUP',// => api/v1/order/ =>
    inTransit = 'IN_TRANSIT', // => api/v1/order/arrivedDelivery =>
    startDelivery = 'START_DELIVERY', // => api/v1/order/contract =>
    //waitingSignature = 'WAITING_SIGNATURE',
    //awaitingPayment = 'WAITING_PAYMENT',
    completeDelivery = 'COMPLETE_DELIVERY'// => api/v1/order/completeDelivery => orderStatus === COMPLETED
}