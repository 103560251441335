import {X} from "lucide-react";
import React, {useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Checkbox} from "src/components/checkBox";
import {Command} from "src/components/command";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {ScrollArea} from "src/components/scrollArea";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import {MinusIcon} from "src/media/icons/custom/minusIcon";
import {PlusIcon} from "src/media/icons/custom/plusIcon";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {deleteOrderCustomItems} from "src/utils/apiCalls/orderDetails/customItems/deleteOrderCustomItems";
import {getOrderCustomItems} from "src/utils/apiCalls/orderDetails/customItems/getOrderCustomItems";
import {updateOrderCustomItems} from "src/utils/apiCalls/orderDetails/customItems/updateOrderCustomItems";
import {deleteOrderItems} from "src/utils/apiCalls/orderDetails/orderItems/deleteOrderItems";
import {getOrderItems} from "src/utils/apiCalls/orderDetails/orderItems/getOrderItems";
import {updateOrderItems} from "src/utils/apiCalls/orderDetails/orderItems/updateOrderItems";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import getSimpleString from "src/utils/stringHandlers/getSimpleString";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {CustomItem} from "src/utils/types/structures/customItem";
import {Item} from "src/utils/types/structures/item";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderItem} from "src/utils/types/structures/orderItem";
import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";
import getExtendedItemVolumeAndWeight
    from "src/utils/zustandStores/orderDetailsInventory/utils/getExtendedItemVolumeAndWeight";

interface Props {
    order: OrderDetails
}

enum ButtonStatus {
    save = 'SAVE',
    deleteItems = 'DELETE_ITEMS'
}

export default function SearchInventory({order}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<Item[]>([]);
    const isOutside = useOnclickOutside(() => {
        setSearchResults([])
    });
    const [flag, setFlag] = useState<boolean>(false)
    const [itemToAdd, setItemToAdd] = useState<Item | null>(null);
    const navigate = useNavigate();
    const [SaveOrDeleteButtonCurrentStatus, setSaveOrDeleteButtonCurrentStatus] = useState<ButtonStatus>(ButtonStatus.save);
    const [isAddItemButtonActive, setAddItemButtonActive] = useState<boolean>(false);

    const {
        items,
        currentExtendedItems,
        onExtendedItemCheckChange,
        onCheckAllChange,
        isAnySelected,
        onExtendedItemChangeCount,
        onExtendedItemAdd,
        onExtendedItemDelete,
        getSortedItemsToRequestLoad,
        initExtendedItems
    } = useOrderInventory()

    const {close, mutateFirstSource} = useSheet();

    useEffect(() => {
        const item = items.filter(el => el.name.toLowerCase() === searchInputValue.toLowerCase())[0]
        if (item) {
            setItemToAdd(item)
        }

        if (flag) {
            const results = items.filter(el => el.name.toLowerCase().includes(searchInputValue.toLowerCase()))
            setSearchResults(results)
        }
    }, [searchInputValue]);


    useEffect(() => {
        if (itemToAdd) {
            setAddItemButtonActive(true)
        } else setAddItemButtonActive(false)
    }, [itemToAdd]);

    useEffect(() => {
        if (isAnySelected) {
            setSaveOrDeleteButtonCurrentStatus(ButtonStatus.deleteItems)
        } else setSaveOrDeleteButtonCurrentStatus(ButtonStatus.save)
    }, [isAnySelected]);

    function getButtonName() {
        if (isLoading) {
            return <LoadingStatus/>
        } else {
            if (SaveOrDeleteButtonCurrentStatus === ButtonStatus.save) {
                return 'Save'
            }

            if (SaveOrDeleteButtonCurrentStatus === ButtonStatus.deleteItems) {
                return 'Delete selected'
            }
        }
    }

    async function SaveDeleteButtonOnClickHandler() {

        if (SaveOrDeleteButtonCurrentStatus === ButtonStatus.deleteItems) {
            onExtendedItemDelete()
        } else {
            setLoading(true)
            const sortedItems = await getSortedItemsToRequestLoad();

            const itemsIdsToDelete = sortedItems.orderItemsToDelete.filter(el => el.id !== null).map(el => el.id)
            if (itemsIdsToDelete.length > 0) {
                const deleteOrderItemsRes = await deleteOrderItems(order.id, itemsIdsToDelete)

                if (deleteOrderItemsRes && isInstanceOf<ErrorMessage>(deleteOrderItemsRes, 'message')) {
                    handleResponseError(deleteOrderItemsRes, () => navigate(routes.login))
                }
            }

            if (sortedItems.currentOrderItems.length > 0) {
                const updatedOrderItems = await updateOrderItems(order.id, sortedItems.currentOrderItems)
                if (isInstanceOf<ErrorMessage>(updatedOrderItems, 'message')) {
                    handleResponseError(updatedOrderItems, () => navigate('/'))
                }
            }

            const customItemsToDelete = sortedItems.customItemsToDelete.map(el => el.id)
            if (customItemsToDelete.length > 0) {
                const res = await deleteOrderCustomItems(order.id, customItemsToDelete)
                if (res && isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate('/'))
                }
            }

            if (sortedItems.currentCustomItems.length > 0) {
                const updatedCustomItems = await updateOrderCustomItems(order.id, sortedItems.currentCustomItems)
                if (isInstanceOf<ErrorMessage>(updatedCustomItems, 'message')) {
                    handleResponseError(updatedCustomItems, () => navigate('/'))
                }
            }

            let currentOrderItems: OrderItem[] = []
            let currentCustomItems: CustomItem[] = []

            const orderItems = await getOrderItems(order.id);
            if (isInstanceOf<ErrorMessage>(orderItems, 'message')) {
                handleResponseError(orderItems, () => navigate('/'))
            } else {
                currentOrderItems = orderItems
            }

            const customItems = await getOrderCustomItems(order.id);
            if (isInstanceOf<ErrorMessage>(customItems, 'message')) {
                handleResponseError(customItems, () => navigate('/'))
            } else {
                currentCustomItems = customItems
            }

            initExtendedItems(currentOrderItems, currentCustomItems)
            if (mutateFirstSource) {
                mutateFirstSource()
            }
            setLoading(false)
            close()
        }
    }

    return <section className={'h-[calc(100dvh-8.7rem)] flex flex-col pt-6'}>
        <section className={'grow flex flex-col gap-6 px-4'}>
            <div className={'flex flex-col gap-2'}>
                <h3 className={'text-sm font-medium'}>Search by name</h3>

                <div key={'add-item-input'} className={'flex gap-4'}>

                    <div className={'relative flex-1'}>
                        <Command className={'border rounded-md'}>
                            <div className="flex items-center gap-0 pl-0 pr-3">
                                <Input className={'h-10 text-base text-foreground border-none pr-1'} type={'text'}
                                       value={searchInputValue}
                                       onInput={(e) => {
                                           setFlag(true)
                                           setSearchInputValue(e.currentTarget.value)
                                       }} placeholder={'Search by name'}/>

                                <div className={`${searchInputValue === '' ? 'invisible' : 'visible'}`}><X
                                    onClick={() => {
                                        setSearchInputValue('')
                                        setSearchResults([])
                                        setItemToAdd(null)
                                        setFlag(false)
                                    }} className={`xInput`}/></div>
                            </div>
                        </Command>

                        {(searchResults.length <= 5 && searchResults.length > 0) &&
                            <ul ref={isOutside} className={'absolute w-full bg-background z-30 border rounded-md mt-2 p-[5px]'}>
                                {items.filter(el => el.name.toLowerCase()
                                    .includes(searchInputValue.toLowerCase()))
                                    .map(el =>
                                        <li
                                            key={el.name}
                                            onClick={(e) => {
                                                setSearchInputValue(el.name);
                                                setSearchResults([]);
                                                setFlag(false)
                                            }}
                                            className={'hover:bg-marcoOnHover rounded-md  p-3 cursor-pointer group flex flex-row justify-between items-center'}>
                                            <span className={'text-sm text-foreground'}>{el.name}</span>
                                            <span className={'text-xs font-medium leading-none invisible group-hover:visible'}>Select item</span>

                                        </li>)}
                            </ul>
                        }

                        {(searchResults.length > 5) &&
                            <ul ref={isOutside} className={'absolute w-full bg-background z-30 border rounded-md mt-2 p-[5px]'}>
                                <ScrollArea className={'h-[13.75rem]'}>
                                    {items.filter(el => el.name.toLowerCase().includes(searchInputValue.toLowerCase())).map(el =>
                                        <li
                                            key={el.name}
                                            onClick={(e) => {
                                                setSearchInputValue(el.name);
                                                setSearchResults([]);
                                                setFlag(false)
                                            }}
                                            className={'hover:bg-marcoOnHover rounded-md  p-3 cursor-pointer group flex flex-row justify-between items-center'}>
                                            <span className={'text-sm text-foreground'}>{el.name}</span>
                                            <span className={'text-xs font-medium leading-none invisible group-hover:visible'}>Select item</span>

                                        </li>)}
                                </ScrollArea>
                            </ul>}
                    </div>

                    <Button size={'defaultTextSM'} onClick={() => {
                        itemToAdd && onExtendedItemAdd(itemToAdd);
                        setSearchInputValue('');
                        setSearchResults([]);
                        setItemToAdd(null)
                    }} disabled={!isAddItemButtonActive || isLoading}> {isLoading ?
                        <LoadingStatus/> : 'Add item'}</Button>
                </div>
            </div>

            <div>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead><Checkbox className={'grid items-center'} checked={isAnySelected}
                                                 onCheckedChange={onCheckAllChange}/></TableHead>
                            <TableHead className={'w-[150px]'}>Item name</TableHead>
                            <TableHead>Volume</TableHead>
                            <TableHead className={'text-center w-[125px]'}>Qty</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {currentExtendedItems.map(el => <TableRow key={el.name}>
                            <TableCell><Checkbox className={'grid items-center'} checked={el.isSelected}
                                                 onCheckedChange={() => onExtendedItemCheckChange(el)}/></TableCell>
                            <TableCell>{el.name}</TableCell>
                            <TableCell>{getExtendedItemVolumeAndWeight(el.item, items).volume} cu.ft</TableCell>
                            <TableCell>
                                <div className={'w-fit h-10 flex gap-0 p-0 rounded-md items-center'}>
                                    <Button onClick={(e) => {
                                        if (el.item.count > 1) {
                                            onExtendedItemChangeCount(el, el.item.count - 1)
                                        }
                                    }}
                                            variant={'ghost'}
                                            className={'w-9 border-l border-t border-b rounded-r-none hover:bg-marcoOnHover round-l-md px-[0.313rem] py-[0.375rem]'}><MinusIcon
                                        size={16}/></Button>
                                    <Input
                                        className={'border rounded-none px-[0.375rem] w-[2.625rem] h-10 text-center text-sm font-normal leading-5'}
                                        inputMode={'numeric'} type={'number'} min={1}
                                        value={getSimpleString(el.item.count)}
                                        onChange={(e) => onExtendedItemChangeCount(el, Number(e.target.value))}/>
                                    <Button onClick={(e) => onExtendedItemChangeCount(el, el.item.count + 1)}
                                            variant={'icon'}
                                            className={'w-9 border-r border-t border-b rounded-l-none hover:bg-marcoOnHover round-r-md px-[0.313rem] py-[0.375rem]'}><PlusIcon
                                        size={16}/></Button>
                                </div>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </div>
        </section>

        <section
            className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-4 gap-4'}>
            <Button size={'lg'}
                    variant={'outline'}
                    className={'w-fit text-base font-medium leading-tight'}
                    disabled={isLoading}
                    onClick={() => {
                        close()
                    }}>Cancel</Button>

            <Button size={'lg'}
                    variant={SaveOrDeleteButtonCurrentStatus === ButtonStatus.save ? 'default' : 'red'}
                    onClick={SaveDeleteButtonOnClickHandler}
                    disabled={isLoading}
                    className={'w-fit text-base font-medium leading-tight'}>{getButtonName()}</Button>
        </section>
    </section>
}