import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const HalfPipIcon = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox={'0 0 8 8'}>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4ZM2 4H6C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4Z"
        />
    </svg>
}

export {HalfPipIcon}