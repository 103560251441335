import React from "react";
import {Button} from "src/components/button";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import {ThreeDotsVertical} from "src/media/icons/custom/threeDotsVertical";
import useSheet, {ElementType} from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {Colors} from "src/utils/defaults/colors";
import getAddressPropertyProps from "src/utils/stringHandlers/orderDetailsStrings/getAddressPropertyProps";
import {getCleanAddress} from "src/utils/stringHandlers/orderDetailsStrings/getCleanAddress";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}

export default function StopsSection({order}: Props) {
    const {open} = useSheet()

    return <section key={'stops-details'} className={'flex flex-col gap-4'}>
        <h2 key={'stops-title'} className={'text-lg font-semibold'}>Stops</h2>


        <section key={'origin'} className={'flex flex-col gap-2'}>

            <div className={'flex gap-2 items-center'}>
                <div className={'flex-none place-self-center p-2'}>
                    <DotIcon size={8} className={'fill-foreground'}/>
                </div>
                <h3 className={'text-muted-foreground text-sm font-medium leading-tight'}>Origin</h3>
            </div>

            <div className={'flex gap-2'}>
                <div className={'w-[24px] grid'}>
                    <div className={'bg-foreground place-self-center h-full border-l border-foreground'}></div>
                </div>

                <div className={'flex justify-between w-full gap-4'}>
                    <div className={'flex-1 flex flex-col gap-2'}>
                        <p className={'text-foreground text-base font-normal leading-normal'}>{getCleanAddress(order.originAddress?.address)}</p>
                        <p className={'text-foreground text-base font-normal leading-normal'}>{getAddressPropertyProps(order.originAddress)}</p>

                        <div className={'flex flex-col gap-2'}>
                            <h3 className={'text-muted-foreground text-sm font-medium leading-tight pt-2'}>Notes</h3>
                            <p className={'text-foreground text-base font-normal leading-normal'}>Address notes</p>
                        </div>
                    </div>

                    <Button
                        onClick={() => order.originAddress && open(ElementType.orderDetailsChangeAddress, order.originAddress, 'Address details')}
                        variant={'ghost'}
                        size={'iconFit'}
                        className={'flex-none w-fit h-fit p-[0.375rem]'}><ThreeDotsVertical size={20} stroke={Colors.foreground}/></Button>
                </div>

            </div>
        </section>

        <section key={'destination'} className={'flex flex-col gap-2'}>
            <div className={'flex gap-2 items-center'}>
                <div className={'flex-none place-self-center fill-foreground p-2'}>
                    <SquareIcon size={8} className={'fill-foreground'}/>
                </div>

                <h3 className={'text-muted-foreground text-sm font-medium leading-tight'}>Destination</h3>
            </div>

            <div className={'flex gap-2 w-full'}>
                <div className={'flex-none w-[24px] grid'}>

                </div>

                <div className={'flex justify-between w-full gap-4'}>
                    <div className={'flex-1 flex flex-col gap-2'}>
                        <p className={'text-foreground text-base font-normal leading-normal'}>{getCleanAddress(order.destinationAddress?.address)}</p>
                        <p className={'text-foreground text-base font-normal leading-normal'}>{getAddressPropertyProps(order.destinationAddress)}</p>

                        <div className={'flex flex-col gap-2'}>
                            <h3 className={'text-muted-foreground text-sm font-medium leading-tight pt-2'}>Notes</h3>
                            <p className={'text-foreground text-base font-normal leading-normal'}>Address notes</p>
                        </div>
                    </div>

                    <Button
                        onClick={() => order.destinationAddress && open(ElementType.orderDetailsChangeAddress, order.destinationAddress, 'Address details')}
                        variant={'ghost'}
                        size={'iconFit'}
                        className={'w-fit h-fit p-[0.375rem] flex-none'}><ThreeDotsVertical size={20} stroke={Colors.foreground}/></Button>
                </div>
            </div>
        </section>
    </section>
}