import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import fetcher from "src/utils/apiCalls/fetcher";
import {ApiHost} from "src/utils/defaults/apiHost";
import routes from "src/utils/defaults/routes";
import {AccountStatus} from "src/utils/enums/accountStatus";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {Company} from "src/utils/types/structures/company";
import useSWR from "swr";

export default function PaymentsAndPayouts() {
    const navigate = useNavigate();

    const {open} = useDialog()

    const {
        data: company,
        error
    } = useSWR<Company>(ApiHost + encodeURI(`api/v1/company`), fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate(routes.login))
        }

        if (!error && company?.status === AccountStatus.onboarding) {
            open(DialogElementType.completeUnboarding, company, 'Complete your account')
        }
    }, [error, company]);

    return <div className={`w-full`}>

        <h1 className={`text-foreground font-medium text-2xl leading-[1.875rem]`}>Payments & Payouts</h1>

        <h2 className={'pt-6 text-xl font-medium leading-7'}>How you’ll get paid</h2>

        <p className={'pt-3 text-sm text-muted-foreground leading-5'}>Please set up your Stripe account as a payout method so we know where to send your money. You will be able to track all transactions through the Stripe dashboard.</p>

        <Button size={'lg'} className={'mt-6 text-center text-white text-base font-medium leading-normal'}>Manage payments</Button>
    </div>
}