import {useEffect} from "react";
import {ReactComponent as MarcoLogo} from 'src/media/marcoLogo.svg';
import RightSide from "src/pages/authSection/layout/rightSide/rightSide";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import useHeaderNavStore from "src/utils/zustandStores/primary/useHeaderNavStore";
import useViewport from "src/utils/zustandStores/primary/useViewport";

interface Props {
    children: React.ReactNode,
}

export default function LayoutAuth({children}: Props) {
    const {width} = useViewport();
    const {setDimensions} = useViewport();
    const {initTabName} = useHeaderNavStore();

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
        return () => window.removeEventListener("resize", setDimensions);
    }, []);

    useEffect(() => {
        initTabName(window.location.pathname)
    }, [children]);

    return <div className={"h-[100dvh] grid lg:grid-cols-2 font-inter"}>

        <div className={"flex flex-col lg:col-span-1 px-padding"}>
            <div className={'w-full h-17 grid items-center'}>
                <MarcoLogo/>
            </div>

            <section className={"grid sm:place-content-center sm:h-full mt-[3.25rem] sm:mt-[0px]"}>
                <div className={"sm:w-[28.8rem] sm:mb-[4.25rem]"}>
                    {children}
                </div>
            </section>
        </div>

        {width > defaultDimensions.lg && <RightSide/>}
    </div>
}