import React from "react";
import {TabsTrigger} from "src/components/tabs";
import {
    ActivitySection
} from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/payments/paymentsContent";

interface Props {
    currentActive: ActivitySection
    setCurrentActive: React.Dispatch<React.SetStateAction<ActivitySection>>
    title: string
    value: ActivitySection
}

export default function PaymentsTrigger({currentActive, setCurrentActive, title, value}: Props) {
    return <TabsTrigger value={value}
                        className={`
                             ${currentActive === value && 'bg-[#F7F7F7] outline outline-[0.175rem] outline-offset-[-2px] outline-foreground'} h-10`}
                        onClick={(e) => {
                            if (currentActive !== value) {
                                setCurrentActive(value);
                            }
                        }}>{title}</TabsTrigger>
}