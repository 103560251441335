import * as React from "react";
import {Button} from "src/components/button";
import {ThreeDotsVertical} from "src/media/icons/custom/threeDotsVertical";
import {Colors} from "src/utils/defaults/colors";

export default function MoreActionsHandler() {
    return <Button variant={'ghost'} size={'iconFit'} className={'place-self-center p-[0.47rem]'} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation()
    }}><ThreeDotsVertical size={24} stroke={Colors.foreground}/></Button>
}