import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import LoadingStatus from "src/components/loadingStatus";
import {Tabs, TabsContent, TabsList} from "src/components/tabs";
import {toast} from "src/components/useToast";
import CrewContent
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/driverAssign/driverAssignTabsContent/crewContent";
import DriverAssignTabTrigger
    from "src/pages/dashboarSection/layout/popUps/sheet/content/orderDetails/driverAssign/driverAssignTabTrigger";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {assignDriver} from "src/utils/apiCalls/assignDriver";
import fetcher from "src/utils/apiCalls/fetcher";
import {unassignDriver} from "src/utils/apiCalls/unassignDriver";
import {ApiHost} from "src/utils/defaults/apiHost";
import routes from "src/utils/defaults/routes";
import {OrderFulfillmentStatus} from "src/utils/enums/orderFulfillmentStatus";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {Member} from "src/utils/types/structures/member";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useSWR from "swr";

interface Props {
    order: OrderDetails
}

export enum AssignDriverSections {
    crew = 'CREW'
}

export default function DriverAssign({order}: Props) {
    const navigate = useNavigate();
    const {close, isOpen, mutateFirstSource} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);
    const {data: companyMemberData, error} = useSWR<Member[]>(ApiHost + encodeURI(`api/v1/company/user`), fetcher);
    const [currentActiveTab, setCurrentActiveTab] = useState<AssignDriverSections>(AssignDriverSections.crew);
    const [currentDriverId, setCurrentDriverId] = useState<string | null | undefined>(null)

    useEffect(() => {
        setCurrentDriverId(order.driver?.id)
    }, [order.driver]);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate(routes.login))
        }
    }, [error]);

    async function onSave() {
        setLoading(true)
        if (order) {
            if (currentDriverId) {
                const res = await assignDriver(order.id, currentDriverId);
                if (isInstanceOf<ErrorMessage>(res, 'message')) {
                    handleResponseError(res, () => navigate(routes.login))
                } else {
                    if (mutateFirstSource) {
                        mutateFirstSource()
                    }
                    close()
                }
            } else {
                if (order.driver) {
                    if (order.fulfillmentStatus !== OrderFulfillmentStatus.accepted) {
                        toast({variant: 'destructive', description: 'Driver not assigned'})
                    } else {
                        const res = await unassignDriver(order.id, order.driver.id)
                        if (isInstanceOf<ErrorMessage>(res, 'message')) {
                            handleResponseError(res, () => navigate(routes.main))
                        } else {
                            if (mutateFirstSource) {
                                mutateFirstSource()
                            }
                            close()
                        }
                    }
                }
            }
        }

        setLoading(false)
    }

    return <section className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>
            <Tabs value={currentActiveTab} className="grow w-full py-6 px-4">
                <TabsList
                    className={'flex gap-2 text-base font-medium rounded-none bg-opacity-0 justify-start px-0 pt-[1rem] pb-[1.125rem]'}>
                    <DriverAssignTabTrigger value={AssignDriverSections.crew} currentActive={currentActiveTab}
                             setCurrentActive={setCurrentActiveTab} title={'Crew'}/>
                </TabsList>

                <TabsContent value={AssignDriverSections.crew}><CrewContent currentDriverId={currentDriverId} setCurrentDriverId={setCurrentDriverId} companyMemberData={companyMemberData}/></TabsContent>
            </Tabs>

        <section
            className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-4 gap-4'}>
            <Button size={'lg'}
                    variant={'outline'}
                    className={'w-full text-base font-medium leading-tight'}
                    onClick={close}>Cancel</Button>
            <Button
                onClick={onSave}
                size={'lg'}
                variant={'default'}
                disabled={isLoading}
                className={'w-full text-base font-medium leading-tight'}>{isLoading ? <LoadingStatus/> : 'Save'}</Button>
        </section>
    </section>
}