import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const EnlargeIcon = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox={'0 0 19 19'}>
        <path id="Vector"
              d="M3.18588 9.77588C2.50988 9.77588 2.00488 9.30388 2.00488 8.63588V4.28788C2.00488 3.15588 2.66488 2.50488 3.78788 2.50488H8.29888C8.97488 2.50488 9.44688 3.00188 9.44688 3.67788C9.44688 4.34488 8.96688 4.84188 8.29888 4.84188H7.76188L5.95888 4.84388L7.25688 6.07088L9.29288 8.09888C9.51188 8.31888 9.65088 8.59588 9.65088 8.90488C9.65088 9.62188 9.15388 10.1349 8.42888 10.1349C8.11988 10.1349 7.81888 10.0209 7.59888 9.79288L5.57988 7.75688L4.35188 6.45788L4.34988 8.22888V8.63588C4.34988 9.30388 3.84488 9.77588 3.18588 9.77588ZM11.7029 18.5049C11.0349 18.5049 10.5629 18.0079 10.5629 17.3319C10.5629 16.6649 11.0349 16.1679 11.7029 16.1679H12.2479L14.0619 16.1659L12.7529 14.9469L10.7169 12.9109C10.4979 12.6909 10.3589 12.4139 10.3589 12.1049C10.3589 11.3879 10.8559 10.8749 11.5719 10.8749C11.8899 10.8749 12.1829 10.9889 12.4109 11.2169L14.4299 13.2529L15.6569 14.5509L15.6599 12.7809V12.3739C15.6599 11.7059 16.1649 11.2339 16.8239 11.2339C17.4999 11.2339 18.0049 11.7139 18.0049 12.3739V16.7219C18.0049 17.8539 17.3449 18.5049 16.2219 18.5049H11.7029Z"
        />
    </svg>
}

export {EnlargeIcon}