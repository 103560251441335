import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import {SortFilter} from "src/utils/enums/sortFilter";
import {resetAndSearch} from "src/utils/resetParamsAndSearch";
import useSearch from "src/utils/zustandStores/useSearch";

export default function ResetFilter() {
    const [searchParams, setSearchParams] = useSearchParams()
    const {resetFilter} = useSearch();
    const [isHidden, setHidden] = useState<boolean>(true);
    const {response} = useSearch();
    function isInvisible(): boolean {
        if (
            response.body.date !== undefined ||
            response.body.originRadius !== defaultFilterValues.originRadius ||
            response.body.destinationRadius !== defaultFilterValues.destinationRadius ||
            response.body.startRate !== defaultFilterValues.minRate ||
            response.body.endRate !== defaultFilterValues.maxRate ||
            response.body.startVolume !== defaultFilterValues.minVolume ||
            response.body.endVolume !== defaultFilterValues.maxVolume ||
            response.body.startDistance !== defaultFilterValues.minDistance ||
            response.body.sortFilterValue !== SortFilter.rate ||
            response.body.endDistance !== defaultFilterValues.maxDistance
        ) return false
        return true
    }

    useEffect(() => {
        const hidden = isInvisible();
        setHidden(hidden)
    }, [response.body]);

    return <div
        className={`${isHidden ? 'hidden' : ''} box-border cursor-pointer hover:bg-marcoOnHover h-10 px-3 rounded-full py-4 w-fit flex gap-2 place-items-center`}
        onClick={() => {
            resetFilter()
            resetAndSearch(searchParams, setSearchParams)
        }}>

        <p className={'text-foreground text-sm font-medium leading-tight'}>Reset</p>
        <CrossIcon size={16} className={'fill-foreground'}/>
    </div>
}