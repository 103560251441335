import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export async function completeDelivery(orderId: string) {
    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/order/completeDelivery`), {
            method: 'POST',
            body: JSON.stringify({orderId: orderId}),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${useTokenStore.getState().token}`
            }
        })

        if (res.ok) {
            const data: OrderDetails = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}