import React from "react";
import {useNavigate} from "react-router-dom";
import {Badge} from "src/components/badge";
import {Card} from "src/components/card";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import routes from "src/utils/defaults/routes";
import formatEnumValueToString from "src/utils/stringHandlers/formatEnumValueToString";
import getFixedToZeroDecimalNumber from "src/utils/stringHandlers/getFixedToZeroDecimalNumber";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {getLocalityStateAddress} from "src/utils/stringHandlers/orderDetailsStrings/getLocalityStateAddress";
import stringToFormatDate from "src/utils/stringHandlers/stringToFormatDate";
import {OrderDetails} from "src/utils/types/structures/orderDetails";


interface Props {
    order: OrderDetails
}

export default function OrderCardMD({order}: Props) {
    const navigate = useNavigate();


    return <Card
        className={'w-full flex flex-col gap-3 py-3 px-3 cursor-pointer'}
        onClick={() => {
            window.scrollTo(0, 0)
            navigate(`${routes.loads}/${order.id}/details`)
        }}>

        <div className={'flex justify-between font-bold'}>
            <h3 className={'text-foreground font-semibold'}>#{order.sid}</h3>
            <p className={'text-foreground font-semibold'}>${getFixedToZeroDecimalNumber(order.rate, 2)}</p>
        </div>


        <div className={'flex gap-2'}>
            <div className={'flex-none place-self-center'}>
                <DotIcon size={8} className={'fill-foreground'}/>
            </div>

            <div className={'flex-1 flex justify-between'}>
                <p className={'flex-1 min-w-32 text-foreground text-sm font-medium'}>{getLocalityStateAddress(order.originAddress)}</p>
                <p className={'flex-none text-muted-foreground text-sm font-normal'}>{stringToFormatDate(order.pickupDate)}</p>
            </div>
        </div>

        <div className={'flex gap-2 place-items-center'}>
            <div className="flex-none place-self-center fill-foreground">
                <SquareIcon size={8} className={'fill-foreground'}/>
            </div>

            <div className={'flex-1 flex justify-between'}>
                <p className={'flex-1 min-w-32 text-foreground text-sm font-medium'}>{getLocalityStateAddress(order.destinationAddress)}</p>
                <p className={'flex-none text-muted-foreground text-sm font-normal'}>{stringToFormatDate(order.deliveryDate)}</p>
            </div>

        </div>

        <div className={'flex gap-2'}>
            <Badge className={'h-fit'}><p className={'text-foreground text-xs font-medium'}>Assigned to: {order.driver ? getString([
                {element: order.driver?.firstName, tag: ElementTag.name},
                {element: order.driver?.lastName, tag: ElementTag.name},
            ]) : 'Unassigned'}</p></Badge>
            <Badge className={'text-foreground text-xs font-medium h-fit'}>{formatEnumValueToString(order.fulfillmentStatus)}</Badge>
        </div>
    </Card>
}