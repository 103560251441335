import {NumberStories} from "src/utils/enums/addressEnum/numberStories";

export default function getNumberStories(numberStory: NumberStories | null | undefined) {

    let result = ''

    if (numberStory){
        switch (numberStory) {
            case NumberStories.one_stories: {
                result = '1 story'
                break
            }

            case NumberStories.two_stories: {
                result = '2 stories'
                break
            }

            case NumberStories.three_stories: {
                result = '3 stories'
                break
            }

            case NumberStories.four_stories: {
                result = '4+ stories'
                break
            }
        }
    }
    return result
}