import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
    stroke?: string
}

const CheckboxDotEmpty = ({...props}: Props) => {

    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 14 14" fill="none">
        <path d="M0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z" stroke={props.stroke ? props.stroke : "#222222"}/>
    </svg>
}

export {CheckboxDotEmpty}