import {CheckIcon, ChevronsUpDown} from "lucide-react";
import * as React from "react";
import * as RPNInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {Button} from "src/components/button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "src/components/command";

import {cn} from "../utils/cn/cnHelper";
import {Input} from "./input";
import {Popover, PopoverContent, PopoverTrigger} from "./popOver";

type PhoneInputProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "value"
> &
    Omit<RPNInput.Props<typeof RPNInput.default>, "onChange"> & {
    onChange?: (value: RPNInput.Value) => void;
}

& ExtendedInputProps;

export interface ExtendedInputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    isvalid?: string
}

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> =
    React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
        ({ className, onChange, ...props }, ref) => {
            return (
                <RPNInput.default
                    ref={ref}
                    className={cn("flex gap-2", className)}
                    flagComponent={FlagComponent}
                    countrySelectComponent={CountrySelect}
                    inputComponent={InputComponent}
                    defaultCountry={'US'}
                    onChange={(value) => {
                        if (typeof value !== 'undefined') onChange?.(value)
                    }}
                    {...props}
                />
            );
        },
    );
PhoneInput.displayName = "PhoneInput";

const InputComponent = React.forwardRef<HTMLInputElement, ExtendedInputProps>(
    ({ className, ...props }, ref) => (
        <Input
            {...props}
            className={cn(`rounded-md h-10 text-base leading-5 ${(props.isvalid === 'true') ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`, className)}
            ref={ref}
        />
    ),
);
InputComponent.displayName = "InputComponent";

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
    disabled?: boolean;
    value: RPNInput.Country;
    onChange: (value: RPNInput.Country) => void;
    options: CountrySelectOption[];
};

const CountrySelect = ({
                           disabled,
                           value,
                           onChange,
                           options,
                       }: CountrySelectProps) => {
    const handleSelect = React.useCallback(
        (country: RPNInput.Country) => {
            onChange(country);
        },
        [onChange],
    );

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    type="button"
                    variant={"outline"}
                    className={cn("flex gap-1 rounded-md h-10 hover:bg-none")}
                    disabled={disabled}>
                    <FlagComponent country={value} countryName={value} />
                    <ChevronsUpDown
                        className={cn(
                            "h-4 w-4 opacity-50",
                            disabled ? "hidden" : "opacity-100",
                        )}
                    />
                </Button>
            </PopoverTrigger>
            <PopoverContent side={'bottom'} className="ml-4 md:ml-56 p-0 w-[300px]">
                <Command>
                    <CommandList>
                        <CommandInput placeholder="Search country..." />
                        <CommandEmpty>No country found.</CommandEmpty>
                        <CommandGroup>
                            {options
                                .filter((x) => x.value)
                                .map((option) => (
                                    <CommandItem
                                        className="gap-2"
                                        key={option.value}
                                        onSelect={() => handleSelect(option.value)}>
                                        <FlagComponent
                                            country={option.value}
                                            countryName={option.label}
                                        />
                                        <span className="text-sm flex-1">{option.label}</span>
                                        {option.value && (
                                            <span className="text-sm text-foreground/50">
                        {`+${RPNInput.getCountryCallingCode(option.value)}`}
                      </span>
                                        )}
                                        <CheckIcon
                                            className={cn(
                                                "ml-auto h-4 w-4",
                                                option.value === value ? "opacity-100" : "opacity-0",
                                            )}
                                        />
                                    </CommandItem>
                                ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
    const Flag = flags[country];

    return (
        <span className="flex h-5 w-7 overflow-hidden rounded-sm bg-foreground/20">
      {Flag && <Flag title={countryName} />}
    </span>
    );
};
FlagComponent.displayName = "FlagComponent";

export { PhoneInput };