import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}


const DownLoadIcon = ({...props}: Props) => {

    return <svg {...props}  width={props.size} height={props.size} viewBox={'0 0 20 20'}>
        <path fillRule="evenodd" clipRule="evenodd" d="M6 5.75C6 5.06 6.56 4.5 7.25 4.5H10V7.75C10 8.716 10.784 9.5 11.75 9.5H15V10.25C15 10.4489 15.079 10.6397 15.2197 10.7803C15.3603 10.921 15.5511 11 15.75 11C15.9489 11 16.1397 10.921 16.2803 10.7803C16.421 10.6397 16.5 10.4489 16.5 10.25V8.75C16.4998 8.55115 16.4207 8.36052 16.28 8.22L11.28 3.22C11.1395 3.07931 10.9488 3.00018 10.75 3H7.25C6.52065 3 5.82118 3.28973 5.30546 3.80546C4.78973 4.32118 4.5 5.02065 4.5 5.75V14.25C4.5 14.9793 4.78973 15.6788 5.30546 16.1945C5.82118 16.7103 6.52065 17 7.25 17H10.5C10.6989 17 10.8897 16.921 11.0303 16.7803C11.171 16.6397 11.25 16.4489 11.25 16.25C11.25 16.0511 11.171 15.8603 11.0303 15.7197C10.8897 15.579 10.6989 15.5 10.5 15.5H7.25C6.56 15.5 6 14.94 6 14.25V5.75ZM13.94 8L11.5 5.56V7.75C11.5 7.888 11.612 8 11.75 8H13.94Z"/>
        <path d="M15.5002 12.5C15.5002 12.3011 15.4211 12.1103 15.2805 11.9697C15.1398 11.829 14.9491 11.75 14.7502 11.75C14.5512 11.75 14.3605 11.829 14.2198 11.9697C14.0792 12.1103 14.0002 12.3011 14.0002 12.5V15.44L13.2802 14.72C13.2115 14.6463 13.1287 14.5872 13.0367 14.5462C12.9447 14.5052 12.8454 14.4832 12.7447 14.4814C12.644 14.4796 12.5439 14.4982 12.4506 14.5359C12.3572 14.5736 12.2723 14.6297 12.2011 14.701C12.1299 14.7722 12.0738 14.857 12.036 14.9504C11.9983 15.0438 11.9798 15.1438 11.9816 15.2445C11.9833 15.3452 12.0054 15.4445 12.0464 15.5365C12.0874 15.6285 12.1465 15.7113 12.2202 15.78L14.2202 17.78C14.3608 17.9205 14.5514 17.9993 14.7502 17.9993C14.9489 17.9993 15.1395 17.9205 15.2802 17.78L17.2802 15.78C17.3538 15.7113 17.4129 15.6285 17.4539 15.5365C17.4949 15.4445 17.517 15.3452 17.5187 15.2445C17.5205 15.1438 17.502 15.0438 17.4643 14.9504C17.4266 14.857 17.3704 14.7722 17.2992 14.701C17.228 14.6297 17.1431 14.5736 17.0498 14.5359C16.9564 14.4982 16.8563 14.4796 16.7556 14.4814C16.6549 14.4832 16.5556 14.5052 16.4636 14.5462C16.3716 14.5872 16.2888 14.6463 16.2202 14.72L15.5002 15.44V12.5Z"/>
    </svg>
}

export {DownLoadIcon}