import {toast} from "src/components/useToast";
import {ErrorMessage} from "src/utils/types/errorMessage";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";

export const handleResponseError = (errorMessage: ErrorMessage, unauthorisedAction?: () => void) => {
    toast({
        variant: 'destructive',
        title: 'Error',
        description: `${errorMessage.message}`
    })

    if (errorMessage.status === '400'){
        if (errorMessage.message === 'Access Denied') {
            useTokenStore.getState().resetToken()
            if (unauthorisedAction) {
                unauthorisedAction()
            }
        }
    }

    if (errorMessage.status === '401') {
        useTokenStore.getState().resetToken()
        if (unauthorisedAction) {
            unauthorisedAction()
        }
    }
}