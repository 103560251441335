import * as React from "react";
import {useState} from "react";
import {Button} from "src/components/button";
import {TableCell} from "src/components/table";
import {ThreeDotsVertical} from "src/media/icons/custom/threeDotsVertical";
import {Colors} from "src/utils/defaults/colors";

export default function InventoryTableDots() {
    const [, setOpen] = useState<boolean>(false)

    return <TableCell className={'p-0 w-12 min-w-12'}>
        <div className={'w-full grid'}>
            <Button variant={'ghost'} size={'iconFit'} className={'place-self-center p-[0.375rem]'} onClick={() => setOpen(true)}>
                <ThreeDotsVertical size={20} stroke={Colors.foreground}/>
            </Button>
        </div>
    </TableCell>
}