import * as React from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "src/components/table";
import {ThreeDotsVertical} from "src/media/icons/custom/threeDotsVertical";
import CarrierName from "src/pages/dashboarSection/myLoads/components/carrierName";
import {Colors} from "src/utils/defaults/colors";
import routes from "src/utils/defaults/routes";
import formatEnumValueToString from "src/utils/stringHandlers/formatEnumValueToString";
import getFixedToZeroDecimalNumber from "src/utils/stringHandlers/getFixedToZeroDecimalNumber";
import {getLocalityStateAddress} from "src/utils/stringHandlers/orderDetailsStrings/getLocalityStateAddress";
import stringToFormatDate from "src/utils/stringHandlers/stringToFormatDate";
import {OrderDetails} from "src/utils/types/structures/orderDetails";


interface Props {
    orders: OrderDetails[]
    slice: number
}

export function TableMD({orders: orderList, slice}: Props) {
    const navigate = useNavigate();

    return <Table>
        <TableHeader>
            <TableRow>
                <TableHead className={'px-3 text-sm font-semibold lg:min-w-24'}>Reference</TableHead>
                <TableHead className={'pr-4 pl-3 text-sm font-semibold lg:min-w-[6.313rem] '}>Rate</TableHead>
                <TableHead className={'pl-4 pr-3 text-sm font-semibold lg:min-w-44'}>Pickup</TableHead>
                <TableHead className={'px-3 text-sm font-semibold lg:min-w-40'}>Delivery</TableHead>
                <TableHead className={'px-3 text-sm font-semibold lg:min-w-[6.313rem]'}>Driver</TableHead>
                <TableHead className={'px-3 text-sm font-semibold lg:min-w-[6.313rem]'}>Status</TableHead>
                <TableHead className={'w-12 min-w-12'}></TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>

            {orderList.slice(slice).map(
                order => <TableRow key={order.id} className={'cursor-pointer'}
                                   onClick={() => {
                                       navigate(`${routes.loads}/${order.id}/details`);
                                   }}>

                    <TableCell className={'px-3 text-foreground text-sm font-normal'}>#{order.sid}</TableCell>
                    <TableCell
                        className={'px-3 text-foreground text-sm font-normal'}>${getFixedToZeroDecimalNumber(order.rate, 2)}</TableCell>

                    <TableCell className={'pr-4 pl-3'}>
                        <div className={'flex flex-col'}>
                            <p className={'text-foreground text-sm font-normal'}>{getLocalityStateAddress(order.originAddress)}</p>
                            <p className={'text-muted-foreground text-sm font-normal'}>{stringToFormatDate(order.pickupDate)}</p>
                        </div>
                    </TableCell>

                    <TableCell className={'pl-4 pr-3'}>
                        <div className={'flex flex-col'}>
                            <p className={'text-foreground text-sm font-normal'}>{getLocalityStateAddress(order.destinationAddress)}</p>
                            <p className={'text-muted-foreground text-sm font-normal'}>{stringToFormatDate(order.deliveryDate)}</p>
                        </div>
                    </TableCell>

                    <TableCell
                        className={'px-3 text-foreground text-sm font-normal'}><CarrierName order={order}/></TableCell>

                    <TableCell
                        className={'px-3 text-foreground text-sm font-normal'}>{formatEnumValueToString(order.fulfillmentStatus)}</TableCell>

                    <TableCell className={'p-0 w-12 min-w-12'}>
                        <div className={'w-full grid'}>
                        <Button variant={'ghost'} size={'iconFit'} className={'place-self-center p-[0.375rem]'}><ThreeDotsVertical size={20} stroke={Colors.foreground}/></Button>
                        </div>
                        </TableCell>
                </TableRow>
            )}
        </TableBody>
    </Table>
}