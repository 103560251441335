import * as React from "react";
import {useSearchParams} from "react-router-dom";
import {SliderTwoValues} from "src/components/sliderTwoValues";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import useSearch from "src/utils/zustandStores/useSearch";

export default function DistancePopOverContent() {
    const [searchParams, setSearchParams] = useSearchParams()

    const {
        response,
        setStartDistance,
        setEndDistance,
    } = useSearch();

    const updateParams = () => {
        const startDistance = response.body.startDistance
        const endDistance = response.body.endDistance

        if (startDistance) {
            searchParams.set('startDistance', startDistance.toString())
        } else searchParams.delete('startDistance')



        if (endDistance) {
            if (endDistance === defaultFilterValues.maxDistance) {
                searchParams.delete('endDistance')
            } else searchParams.set('endDistance', endDistance.toString())

        } else searchParams.delete('endDistance')

        setSearchParams(searchParams)
    }

    return <div className={'flex flex-col pt-3 pb-5 px-4'}>

        <div className={'flex flex-col gap-6'}>
            <div className={'flex justify-between'}>
                <p className={'text-foreground text-sm font-inter'}>Distance</p>
                <p className={'text-muted-foreground text-sm'}>{`Min ${response.body.startDistance} mi - `}
                    {(response.body.endDistance && response.body.endDistance === defaultFilterValues.maxDistance) ? `Max ${response.body.endDistance}+ mi` : `Max ${response.body.endDistance} mi`}</p>
            </div>

            <SliderTwoValues
                minStepsBetweenThumbs={7}
                step={defaultFilterValues.distanceStep}
                value={[response.body.startDistance ?? defaultFilterValues.minDistance, response.body.endDistance ?? defaultFilterValues.maxDistance]}
                max={defaultFilterValues.maxDistance}
                min={defaultFilterValues.minDistance}
                onValueChange={(e) => {
                    setStartDistance(e[0]);
                    setEndDistance(e[1]);
                }}
                onPointerUp={updateParams}
            />
        </div>
    </div>
}