import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
    stroke?: string
}


const MobileProfileIcon= ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" strokeWidth={1.6}/>
        <circle cx="12" cy="7" r="4" strokeWidth={1.6}/>
    </svg>
}

export {MobileProfileIcon}