import {passwordStrength} from "check-password-strength";
import customOptions from "src/utils/defaults/passwordRequirements";
import * as z from "zod";

const changePassword = z.object(
    {
        currentPassword: z.string(),
        newPassword: z.string().min(8, {message: 'Password must contain at least 8 characters'}),
        repeatPassword: z.string()
    })
    .superRefine(({newPassword, repeatPassword}, ctx) => {
        const passStrengthResult = passwordStrength(newPassword, customOptions).value

        switch (true) {
            case (passStrengthResult === 'Too weak'):
            case (passStrengthResult === 'Weak'): {
                ctx.addIssue({
                    code: "custom",
                    message: "Weak password",
                    path: ["newPassword"]
                })
                break
            }
        }

        if (newPassword !== repeatPassword) {
            ctx.addIssue({
                code: "custom",
                message: "The passwords did not match",
                path: ["repeatPassword"]
            })
        }
    })

export default changePassword