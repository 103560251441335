import React from "react";
import {useSearchParams} from "react-router-dom";
import {Button} from "src/components/button";
import {resetAndSearch} from "src/utils/resetParamsAndSearch";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{}

export default function NoResults({...props}: Props) {
    const [searchParams, setSearchParams] = useSearchParams()

    return <div {...props}>

        <h2 className={'text-[22px] font-semibold leading-relaxed'}>No available loads</h2>

        <p className={'text-muted-foreground text-base font-normal leading-normal pt-3 pb-6'}>Try adjusting your search by changing your location, dates or removing filters.</p>

        <Button className={'w-fit text-sm font-medium leading-tight'} variant={'outline'} type={'button'} onClick={() => resetAndSearch(searchParams, setSearchParams)}>Remove all filters</Button>
    </div>
}