import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {CustomItem} from "src/utils/types/structures/customItem";
import {Item} from "src/utils/types/structures/item";
import {OrderItem} from "src/utils/types/structures/orderItem";

export default function getExtendedItemDescription(orderItem: OrderItem | CustomItem, items: Item[]) {
    if (isInstanceOf<OrderItem>(orderItem, 'itemId')) {
        const item = items.filter(el => el.id === orderItem.itemId)
        if (item[0]) {
            return item[0].description
        } else return ''
    } else {
        return orderItem.description ?? ''
    }
}