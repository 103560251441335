import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import OrderCardMD from "./orderCardMD";
import {TableMD} from "./tableMD";

interface Props {
    orderList: OrderDetails[]
}

export default function GridCardWithTablesMD({orderList}: Props) {
    const {width} = useViewport();

    return <div className={'px-padding'}>

        {width < defaultDimensions.md && <section className={'grid grid-cols-1 sm:grid-cols-2 gap-4'}>
            {orderList.map(
                el => <OrderCardMD key={el.id} order={el}/>
            )}
        </section>}

        {width >= defaultDimensions.md && <section>
            <TableMD orders={orderList} slice={0}></TableMD>
        </section>
        }
    </div>
}