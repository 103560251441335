import {ApiHost} from "src/utils/defaults/apiHost";
import getDefinedError from "src/utils/errorHandlers/getDefinedError";
import otherError from "src/utils/errorHandlers/otherError";
import {LoginRequest} from "src/utils/types/loginRequest";

type Token = {
    token: string
}

export async function postLogin(data: LoginRequest) {
    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/auth/login`), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "content-type": "application/json",
            }
        })

        if (res.ok) {
            const data: Token = await res.json();
            return data.token
        } else {
            const err = await getDefinedError(res)
            return err
        }

    } catch (e) {
        return otherError
    }
}