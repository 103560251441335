import {Address} from "src/utils/types/structures/Address";

export function getLocalityStateAddress(address: Address | null | undefined) {

    let result = ''

    if (address) {

        let localityAndState = ''


        if (address.locality) {
            localityAndState = address.locality
        }

        if (address.state) {

            if (address.locality) {
                localityAndState = localityAndState + ', ' + address.state
            } else {
                localityAndState = address.state
            }
        }

        result = localityAndState
    }

    return result
}