import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}
const EmptyPipIcon = ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox={'0 0 8 8'} fill="none">
        <circle cx="4" cy="4" r="3" strokeWidth="2"/>
    </svg>
}

export {EmptyPipIcon}