import React from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import routes from "src/utils/defaults/routes";

export default function ThankYouScreen() {
    const navigate = useNavigate()

    const onClickHandler = () => {
        window.location.href = 'https://www.marcohq.com/';
        return null;
    }

    return <div>
        <div className={'flex flex-col gap-4'}>
            <h1 className={'font-medium text-foreground text-[2rem]'}>Thanks for your interest in partnering with Marco.</h1>

            <p className={'text-base'}>We've received your submission and will reach out soon to follow up. In the
                meantime, explore our services and locations.</p>
        </div>

        <div className={'flex flex-col gap-3'}>

            <Button variant={'green'} size={'lg'} onClick={onClickHandler} className={'w-full mt-6'}>
                Explore our services</Button>

            <div>
                <span className={'text-sm font-normal'}>Already registered?</span>
                <span
                    className={"ml-1 text-marcoDarkGreen text-sm font-normal hover:underline cursor-pointer"}
                    onClick={() => navigate(routes.main)}>
                            Sign In
                        </span>
            </div>
        </div>
    </div>
}