import {UnitSize} from "src/utils/enums/addressEnum/unitSize";

export default function getUnitSize(unitSize: UnitSize | null | undefined) {

    let result = ''

    if (unitSize){
        switch (unitSize) {
            case UnitSize.unit_5x5: {
                result = "5' x 5'"
                break
            }

            case UnitSize.unit_5x10: {
                result = "5' x 10'"
                break
            }

            case UnitSize.unit_10x10: {
                result = "10' x 10'"
                break
            }

            case UnitSize.unit_10x15: {
                result = "10' x 15'"
                break
            }

            case UnitSize.unit_10x20: {
                result = "10' x 20'"
                break
            }

            case UnitSize.unit_10x25: {
                result = "10' x 25'"
                break
            }

            case UnitSize.unit_10x30: {
                result = "10' x 30'"
                break
            }
        }
    }
    return result
}