import {PopoverClose} from "@radix-ui/react-popover";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import {Separator} from "src/components/separator";
import routes from "src/utils/defaults/routes";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useUserHeader from "src/utils/zustandStores/userHeader/useUserHeader";

export default function UserNamePopOver() {
    const navigate = useNavigate()
    const {user} = useUserHeader()

    return <div>
        <Popover>
            <PopoverTrigger asChild>
                <Avatar className={'w-10 h-10 rounded-full cursor-pointer'}>
                    <AvatarImage src={(user && user.avatar && user.avatar !== '') ? user.avatar: undefined}/>
                    <AvatarFallback className={'text-sm'}>{user ? getFirstLetters(user.firstName, user.lastName) : ''}</AvatarFallback>
                </Avatar>
            </PopoverTrigger>
            <PopoverContent className="flex flex-col w-fit h-fit p-0 mt-[4px] shadow-md" align="end">

                <div className={'p-[0.313rem]'}>
                    <section className={'flex flex-col py-[0.375rem] px-[0.5rem] gap-0'}>
                        <p className={'text-sm font-medium leading-5 text-foreground'}>{user ? (user.firstName + ' ' + user.lastName) : ''}</p>
                        <p className={'text-xs text-muted-foreground leading-4'}>{user ? (user.email) : ''}</p>
                    </section>
                </div>

                <Separator/>

                <section className={'grow w-full h-full flex flex-col p-[0.313rem]'}>
                    <PopoverClose>
                        <div
                            className={'text-start text-sm leading-5 py-[0.375rem] px-[0.5rem] hover:bg-marcoOnHover rounded-lg text-foreground'}
                            onClick={() => {
                                navigate(routes.userPersonalDetails)
                            }}>Account
                        </div>
                    </PopoverClose>

                    <PopoverClose>
                        <div className={'text-start text-sm leading-5 py-[0.375rem] px-[0.5rem] hover:bg-marcoOnHover rounded-lg text-foreground'}>Messages</div>
                    </PopoverClose>

                    <PopoverClose>
                        <div className={'text-start text-sm leading-5 py-[0.375rem] px-[0.5rem] hover:bg-marcoOnHover rounded-lg text-foreground'}>Help center</div>
                    </PopoverClose>
                </section>

                <Separator/>

                <PopoverClose className={'p-[0.313rem]'}>
                    <div
                        className={'text-start text-sm leading-5 py-[0.375rem] px-[0.5rem] hover:bg-marcoOnHover rounded-lg text-foreground'}
                        onClick={() => {
                            useTokenStore.getState().resetToken();
                            navigate('/')
                        }}>Sign out
                    </div>
                </PopoverClose>
            </PopoverContent>
        </Popover>
    </div>
}