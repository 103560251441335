import {Address} from "src/utils/types/structures/Address";
import {Member} from "src/utils/types/structures/member";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderSearch} from "src/utils/types/structures/orderSearch";
import {create} from 'zustand'

export enum ElementType {
    default = 'DEFAULT',
    order = 'ORDER',

    orderDetailsPayments = 'ORDER_DETAILS_PAYMENTS',
    orderDetailsNotes = 'ORDER_DETAILS_NOTES',
    orderDetailsDriverAssign = 'ORDER_DETAILS_DRIVER_ASSIGN',
    orderDetailsUpdateItems = 'ORDER_DETAILS_UPDATE_ITEMS',
    orderDetailsDocuments = 'ORDER_DETAILS_DOCUMENTS',
    orderDetailsChangeAddress = 'ORDER_DETAILS_CHANGE_ADDRESS',

    fleetManagementInviteMember = 'FLEET_MANAGEMENT_INVITE_MEMBER',
    fleetManagementEditMember = 'FLEET_MANAGEMENT_EDIT_MEMBER',
}

export type SheetDefinition = null | OrderSearch | OrderDetails | Member | Address

interface State {
    isOpen: boolean
    type: ElementType
    value: SheetDefinition
    title: string

    onCloseAction?: () => void
    mutateFirstSource?: () => void
    mutateSecondSource?: () => void
}

interface Actions {
    open: (elementType: ElementType, value: SheetDefinition, title: string, mutateFirstSource?: () => void, mutateSecondSource?: () => void) => void
    close: () => void

    setOnCloseAction: (mutateSecondSource: () => void) => void,

}

const useSheet = create<State & Actions>(
    (set, get) => ({
        isOpen: false,
        type: ElementType.default,
        value: null,
        title: '',

        setOnCloseAction: (onCloseAction: () => void) => {
            set(
                (state) => (
                    {
                        onCloseAction: onCloseAction
                    }
                )
            )
        },

        open: (elementType: ElementType, value: SheetDefinition, title: string, mutateFirstSource?: () => void, mutateSecondSource?: () => void, onCloseAction?: () => void) => {
            set(
                (state) => (
                    {
                        isOpen: true,
                        type: elementType,
                        value: value,
                        title: title,
                        mutateFirstSource: mutateFirstSource ?? undefined,
                        mutateSecondSource: mutateSecondSource ?? undefined,
                        onCloseAction: onCloseAction ?? undefined
                    }
                )
            )
        },

        close: () => {
            set(
                (state) => (
                    {
                        isOpen: false,
                        type: ElementType.default,
                        value: null,
                        title: '',
                        mutateFirstSource: undefined,
                        mutateSecondSource: undefined,
                        onCloseAction: undefined,
                    }
                )
            )
        },
    })
)

export default useSheet;
