import React from "react";
import {Avatar, AvatarFallback, AvatarImage} from "src/components/avatar";
import {Button} from "src/components/button";
import {ChatIcon} from "src/media/icons/custom/chatIcon";
import {PhoneIcon} from "src/media/icons/custom/phoneIcon";
import useDialog, {DialogElementType} from "src/pages/dashboarSection/layout/popUps/dialog/useDialog";
import {getFirstLetters} from "src/utils/stringHandlers/getFirstLetters";
import getString, {ElementTag} from "src/utils/stringHandlers/getString";
import {OrderDetails} from "src/utils/types/structures/orderDetails";

interface Props {
    order: OrderDetails
}
export default function CustomerInfoSection({order}: Props) {
    const {open} = useDialog()

    const openDialog = () => {
        open(DialogElementType.contactDetails, order, 'Contact details')
    }


    return <section key={'customer-info'} className={'flex flex-col gap-4'}>
        <h2 className={'text-foreground text-lg font-semibold'}>Customer info</h2>

        <div className={'flex flex-1 items-center gap-4'}>
            <div className={'flex-1 flex gap-2 items-center'}>
                <Avatar className={'flex-none'}>
                    <AvatarImage
                        src={undefined}/>
                    <AvatarFallback
                        className={'text-sm'}>{getFirstLetters(order.customerFirstName ?? '', order.customerLastName ?? '')}</AvatarFallback>
                </Avatar>

                <div className={'flex-1 text-base font-normal leading-6'}>
                    {getString([
                        {element: order.customerFirstName ?? '', tag: ElementTag.name},
                        {element: order.customerLastName ?? '', tag: ElementTag.name},
                    ])}
                </div>
            </div>

            <div className={'flex-none flex gap-4'}>
                <Button size={'iconFit'} variant={'ghost'} className={'p-2'} onClick={openDialog}><PhoneIcon size={24}/></Button>
                <Button size={'iconFit'} variant={'ghost'} className={'p-2'} onClick={openDialog}><ChatIcon size={24}/></Button>
            </div>
        </div>


    </section>
}