import {SetURLSearchParams} from "react-router-dom"

export function resetAndSearch(searchParams: URLSearchParams, setSearchParams: SetURLSearchParams) {
    searchParams.delete('originRadius')
    searchParams.delete('destinationRadius')
    searchParams.delete('startDate')
    searchParams.delete('endDate')
    searchParams.delete('endRate')
    searchParams.delete('startRate')
    searchParams.delete('startVolume')
    searchParams.delete('endVolume')
    searchParams.delete('startDistance')
    searchParams.delete('endDistance')
    searchParams.delete('sort')
    setSearchParams(searchParams)
}