import useOrderInventory from "src/utils/zustandStores/orderDetailsInventory/orderInventory";

export default function getItemsCount() {

    const previousExtendedItems = useOrderInventory.getState().previousExtendedItems
    const itemCount = previousExtendedItems.reduce((previousValue, currentItem) => {
        return previousValue + currentItem.item.count
    }, 0)

    return itemCount
}