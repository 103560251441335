import DesktopContent from "src/pages/dashboarSection/layout/popUps/imageViewer/desktopContent";
import MobileContent from "src/pages/dashboarSection/layout/popUps/imageViewer/mobileContent";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import useViewport from "src/utils/zustandStores/primary/useViewport";

export default function ImageViewer() {
    const {isImageViewerOpen} = useOrderAttachments()
    const {width} = useViewport()

    return <div className={`absolute h-dvh w-full z-50 bg-[#1A1A1A] ${isImageViewerOpen ? 'block' : 'hidden'} flex flex-col`}>
        { width < defaultDimensions.md ? <MobileContent/> : <DesktopContent/>}
    </div>
}