export type FormCheck = {
    isLengthCorrect: boolean,
    isUpperPlusLowerLettersPresent: boolean,
    isAtLeastOneNumber: boolean,
    isAtLeastOneSpecialCharacter: boolean,
}

const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

const checkValues = (value: string) => {
    let upperCase: boolean = false
    let lowerCase: boolean = false

    let length: boolean = false
    let upperAndLowerCase: boolean = false
    let IsAnyNumber: boolean = false
    let isAnySpecialCharacter: boolean = false

    if (value.length >= 8) {
        length = true
    }

    Array.from(value).forEach(el => {

        if (el === el.toUpperCase() && !format.test(el) && isNaN(Number(el))) {
            upperCase = true
        }

        if (el === el.toLowerCase()) {
            lowerCase = true
        }

        if (!isNaN(Number(el))) {
            IsAnyNumber = true
        }
    })

    if (format.test(value)) {
        isAnySpecialCharacter = true
    }

    if (upperCase && lowerCase) {
        upperAndLowerCase = true
    }

    const updatedValues: FormCheck = {
        isLengthCorrect: length,
        isUpperPlusLowerLettersPresent: upperAndLowerCase,
        isAtLeastOneNumber: IsAnyNumber,
        isAtLeastOneSpecialCharacter: isAnySpecialCharacter
    }

    return updatedValues
}

export default checkValues