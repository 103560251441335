import {Card, CardContent} from "src/components/card";
import background from "src/media/background.jpg";
import photo from "src/media/photoes/45b91603fcaf382ba52a707c23525d78.png";

export default function RightSide() {
    return <div className={`relative lg:col-span-1 lg:w-full`}>
            <div className={"absolute w-full bottom-17 px-17"}>
                <div>
                    <Card>
                        <CardContent className={"py-0 px-0"}>
                            <div className={"py-6 px-6 h-fit flex flex-col gap-6"}>

                                <h2 className={"text-foreground text-xl font-bold"}>“ With Marco saved me countless
                                    hours of work and helped me deliver stunning designs to my clients faster than ever
                                    before.”</h2>

                                <div className={"flex gap-4 place-items-center"}>

                                    <img src={photo} className={"w-12 h-12 border rounded-full"}/>

                                    <div className={"flex flex-col gap-[4px]"}>
                                        <p className={"text-foreground text-sm font-bold"}>Charlie</p>
                                        <p className={"text-muted-foreground text-sm font-normal"}>
                                            Express Moving & Storage
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <img src={background} className={"object-cover h-full w-full"}/>
        </div>
}