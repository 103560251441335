import {zodResolver} from "@hookform/resolvers/zod";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {PasswordInput} from "src/components/passwordInput";
import {getAccountUserCredentials} from "src/utils/apiCalls/getAccountUserCredentials";
import {resetPassword} from "src/utils/apiCalls/resetPassword";
import {updatePassword} from "src/utils/apiCalls/updatePassword";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import checkValues, {FormCheck} from "src/utils/stringHandlers/checkValues";
import {ErrorMessage} from "src/utils/types/errorMessage";
import changePassword from "src/utils/zodSchemas/changePassword";
import * as z from "zod";

type ResError = {
    error: string
}

type ResAccepted = {
    status: string
}

export default function LoginAndSecurity() {
    const navigate = useNavigate()
    const [formChecker, setFormChecker] = useState<FormCheck | null>(null)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const form = useForm<z.infer<typeof changePassword>>(
        {
            resolver: zodResolver(changePassword),
            defaultValues: {
                currentPassword: '',
                newPassword: '',
                repeatPassword: ''
            }
        }
    );

    async function onNewPasswordClickHandle() {
        const member = await getAccountUserCredentials()
        if (isInstanceOf<ErrorMessage>(member, 'message')) {
            handleResponseError(member, () => navigate(routes.login))
        } else {
            const res = await resetPassword(member.email)
            if (res !== 200) {
                handleResponseError(res, () => navigate(routes.login))
            }
        }
    }

    async function onSubmit(values: z.infer<typeof changePassword>) {
        const res = await updatePassword(values.currentPassword, values.newPassword)

        switch (true) {
            case (isInstanceOf<ErrorMessage>(res, 'message')): {
                handleResponseError(res, () => navigate('/'))
                break
            }

            case (isInstanceOf<ResError>(res, 'error')): {
                form.setError('currentPassword', {message: res.error})
                break
            }

            case (isInstanceOf<ResAccepted>(res, 'status')): {
                break
            }
        }
    }

    return <div className={`w-full`}>
        <h1 className={` text-foreground font-medium text-2xl leading-[1.875rem]`}>Login & Security</h1>

        <p className={'pt-6 text-xl font-medium leading-7'}>Login</p>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className={'flex flex-col pt-12 gap-6'}>
                    <section key={'name'} className={'flex flex-col'}>
                        <div className={'flex justify-between items-center'}>
                            <h2 className={`text-foreground font-medium leading-6`}>Password</h2>
                            <div
                                onClick={() => {
                                    if (isDisabled) {
                                        setIsDisabled(false)
                                        window.scrollTo(0, 0)
                                    } else {
                                        setIsDisabled(true)
                                    }
                                }}
                                className={`text-sm underline font-semibold cursor-pointer`}>{isDisabled ? 'Cancel' : 'Update'}</div>
                        </div>

                        <p className={`${isDisabled && 'hidden'} text-foreground text-sm`}>*********</p>
                        <p className={`${isDisabled && 'hidden'} text-muted-foreground text-sm`}>Last change May 17,
                            2023</p>

                        {isDisabled && <div className={'pt-6 flex flex-col gap-6'}>
                            <div className={"flex flex-col gap-4"}>
                                <FormField
                                    control={form.control}
                                    name="currentPassword"
                                    render={({field}) => (
                                        <FormItem className={"flex-1"}>

                                            <div className={'flex justify-between'}>
                                                <FormLabel className={'text-sm leading-3'}>Current password</FormLabel>
                                                {form.getFieldState('currentPassword').invalid && <FormMessage
                                                    className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                            </div>

                                            <FormControl>
                                                <PasswordInput
                                                    {...field}
                                                    className={`${form.getFieldState('currentPassword').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                    placeholder={"Current password"}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />

                                <p className={'text-sm text-marcoDarkGreen cursor-pointer'}
                                   onClick={onNewPasswordClickHandle}>Need a new password?</p>

                                <FormField
                                    control={form.control}
                                    name="newPassword"
                                    render={({field}) => (
                                        <FormItem className={"flex-1"}>

                                            <div className={'flex justify-between'}>
                                                <FormLabel className={'text-sm leading-3'}>New password</FormLabel>
                                                {form.getFieldState('newPassword').invalid && <FormMessage
                                                    className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                            </div>

                                            <FormControl>
                                                <PasswordInput
                                                    {...field}
                                                    placeholder={"New password"}
                                                    className={`${form.getFieldState('newPassword').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                    onInput={event => setFormChecker(checkValues(event.currentTarget.value))}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />

                                <ul className={'text-muted-foreground text-sm font-inter list-disc pl-4'}>
                                    <li className={`${!formChecker && 'text-muted-foreground'} ${formChecker?.isLengthCorrect ? 'text-marcoDarkGreen' : 'text-marcoFormErrorTextColor'} text-sm`}>8
                                        or more characters
                                    </li>
                                    <li className={`${!formChecker && 'text-muted-foreground'} ${formChecker?.isUpperPlusLowerLettersPresent ? 'text-marcoDarkGreen' : 'text-marcoFormErrorTextColor'}  text-sm`}>Upper
                                        + lowercase letters
                                    </li>
                                    <li className={`${!formChecker && 'text-muted-foreground'} ${formChecker?.isAtLeastOneNumber ? 'text-marcoDarkGreen' : 'text-marcoFormErrorTextColor'} text-sm`}>At
                                        least one number
                                    </li>
                                    <li className={`${!formChecker && 'text-muted-foreground'} ${formChecker?.isAtLeastOneSpecialCharacter ? 'text-marcoDarkGreen' : 'text-marcoFormErrorTextColor'} text-sm`}>At
                                        least one special character
                                    </li>
                                </ul>

                                <FormField
                                    control={form.control}
                                    name="repeatPassword"
                                    render={({field}) => (
                                        <FormItem className={"flex-1"}>

                                            <div className={'flex justify-between'}>
                                                <FormLabel className={'text-sm leading-3'}>Confirm password</FormLabel>
                                                {form.getFieldState('repeatPassword').invalid && <FormMessage
                                                    className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                            </div>

                                            <FormControl>
                                                <PasswordInput
                                                    {...field}
                                                    placeholder={"Confirm password"}
                                                    className={`${form.getFieldState('repeatPassword').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'}`}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <Button type={'submit'} size={'lg'} className={'w-fit'}>Update password</Button>
                        </div>}
                    </section>
                </div>
            </form>
        </Form>
    </div>
}